.c-text-image {
  @include full-grid-with-column-gap;
  align-items: flex-end;
  row-gap: 16px;

  &__content {
    z-index: 2;
    grid-column: main-start / main-end;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-top: 16px;
    align-self: center;

    @include tablet-landscape {
      padding-top: 0px;
      grid-column: 2 / span 5;
      grid-row: 1 / span 2;
    }

    @include widescreen {
      grid-column: 3 / span 4;
    }
  }

  &__heading,
  &__paragraph,
  &__arrow {
    opacity: 0;
    transform: translateY(20%);
    transition: all $transition-slow ease-out;

    .loaded & {
      transform: translateY(0%);
      opacity: 1;
    }
  }

  &__paragraph {
    transition-delay: $transition-fast;
  }
  &__arrow {
    transition-delay: $transition-slow;
    margin-top: 1.5rem;
  }

  &__visual {
    grid-column: main-start / main-end;
    align-self: end;

    @include tablet-landscape {
      padding-bottom: 0px;
      grid-column: span 7 / -2;
      grid-row: 1 / span 2;
    }

    @include widescreen {
      grid-column: span 6 / -3;
    }
  }

  &__shape {
    display: none;

    @include tablet-landscape {
      display: flex;
      grid-column: span 9 / side-end;
      grid-row: 1 / span 2;
    }
  }

  &--imageLeft &__content {
    @include tablet-landscape {
      grid-column: 10 / 14;
      grid-row: 2 / span 1;
    }

    @include widescreen {
      // grid-column: span 4 / -3;
    }
  }

  &--imageLeft &__visual {
    @include tablet-landscape {
      grid-column: 2 / span 7;
      grid-row: 2 / span 1;
    }

    @include widescreen {
      grid-column: 3 / span 6;
    }
  }

  &--imageLeft &__shape {
    @include tablet-landscape {
      grid-column: side-start / span 9;
      grid-row: 1 / span 2;
    }
  }
}
