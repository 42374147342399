$component: "p-service-detail";
$component2: "p-service-content";
.#{$component} {
    &__category-container {
        display: flex;
        gap: 16px;
        margin-top: 32px;
        flex-wrap: wrap;
    }

    &__category-link,
    &__category-span {
        text-decoration: none;
        color: $green-dark;
        font-size: 16px;
        line-height: calc(22.03px / 16px);
        padding: 8px 24px;
        border: 1px solid $green-dark;
        border-radius: 24px;
        display: block;
    }
    &__category-link {
        transition: 0.2s;
        &:hover {
            color: $green-light;
            background-color: $green-dark;
            transition: 0.2s;
        }
    }
}
.#{$component2} {
    margin-top: 50px;
    grid-column: main-start / main-end;

    @include tablet-landscape {
        padding-top: 0px;
        grid-column: 2 / span 6;
    }

    @include widescreen {
        grid-column: 3 / span 6;
    }
}
