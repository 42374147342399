.c-header-banner {
  background-color: $purple;
  margin-left: -$section-outer-padding--mobile;
  margin-right: -$section-outer-padding--mobile;
  padding-left: $section-outer-padding--mobile;

  @include tablet {
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 0px;
  }

  @include standard-grid;

  &__content {
    position: relative;
    padding-top: 24px;
    padding-bottom: 24px;
    grid-column: span 4;

    @include tablet {
        padding-top: 40px;
        padding-bottom: 40px;  
        padding-left: 40px;      
        grid-column: 1 / span 6;
    }

    @include desktop {
        padding-left: 0px;
        grid-column: 2 / span 5;
    }
  }

  &__visual {
    position: relative;
    grid-column: span 2;
    overflow: hidden;

    &::before {  
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        content: "";
        mask-image: svg("headerBanner/shape-mobile.svg");
        background-color: $purple;
        z-index: 2;
        transform: translateX(-1px);
        aspect-ratio: 82 / 218;
        height: 100%;    
    }    

    @include tablet {
        grid-column: span 5 / -1;

        &::before {
            aspect-ratio: 73 / 210;
            mask-image: svg("headerBanner/shape-desktop.svg");
        }        
    }

    @include desktop {
        grid-column: span 5 / -1;
    }
  }
}
