$component: "c-latest-news";

.#{$component} {
  &__items-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  &__item {
    border-radius: $border-radius-standard;
    border: $border-standard-2023;
    overflow: hidden;
    transform: translateZ(0);
  }
  &__item-image {
    width: 100%;
    aspect-ratio: 331 / 166;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__item-tag {
    color: $purple;
    font-size: 12px;
  }
  &__item-title {
    font-weight: 400;
    font-size: 24px;
    line-height: 1;
    margin-top: 8px;
  }
  &__item-description {
    color: $grey-dark;
    font-size: 14px;
    margin: 16px 0;

    @include limit-lines(3);
  }
  &__item-link {
    text-decoration: none;
  }
  &__item-content {
    overflow: hidden;
    transform: translateZ(0);
    padding: 16px;
  }
  &__item-date-container {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  &__item-date-icon {
    width: 13px;
    height: 14px;
  }
  &__item-date {
    font-size: 14px;
  }
  @include tablet-landscape {
    &__items-wrapper {
      flex-direction: row;
    }
    &__item-link {
      display: block;
      height: 429px;
    }
    &__item-title {
      @include limit-lines(3);
    }
    &__item {
      width: 33%;
      transform: translateY(0px);
      transition: transform 0.1s;
      &:hover {
        transform: translateY(-8px);
      }
    }
    &__item-content {
      height: calc(100% - 180px);
      position: relative;
    }
    &__item-date-container {
      position: absolute;
      bottom: 16px;
    }
  }
}
