/*
Banner Block

Background decoration color can be adjusted with inline style.

Markup:
<section class="banner-block">
  <div class="banner-block__decoration"></div>
  <a class="banner-block__image" href="#">
    <img
      class="object-fit"
      src="https://via.placeholder.com/2064x728"
      alt="image title" />
  </a>
</section>

Style guide: 4-components.banner-block
*/

@use "sass:math";

$banner-block--padding--mobile: 20px;
$banner-block--padding--tablet: 40px;

.banner-block {
  margin: 55px auto;
  padding-bottom: $banner-block--padding--mobile;
  padding-right: $section-outer-padding--mobile;
  padding-top: $banner-block--padding--mobile;
  position: relative;
  width: 100%;

  @include tablet {
    width: $container-max-width--tablet;
  }
  
  @include tablet-landscape {
    margin: 80px auto;
    padding-bottom: $banner-block--padding--tablet;
    padding-right: $banner-block--padding--tablet;
    padding-top: $banner-block--padding--tablet;
    width: math.div(10, 12) * $container-max-width--tablet-landscape;
  }

  @include widescreen {
    width: math.div(10, 12) * $container-max-width--widescreen;

  }
}

.banner-block__image {
  display: block;
  height: 100%;
  padding-top: math.div(364, 1032) * 100%;
  width: 100%;
  position: relative;
}

.banner-block__decoration {
  background: #FDD70A;
  bottom: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 50%;

  @include tablet-landscape {
    width: 40%;
  }
}
