.c-home-carousel {
    overflow: hidden;
    @include full-grid;

    &__wrapper {
        grid-column: main-start / main-end;
        @include full-grid;
        overflow: hidden;
        flex-direction: column;
        height: auto;
        gap: 60px 0;
    }

    &__navigation {
        grid-column: main-start / main-end;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @include tablet-landscape {
            padding-right: 30px;
            grid-column: 2 / 8;
        }

        @include widescreen {
            padding-right: 60px;
            grid-column: 3 / 8;
        }
    }

    &__images {
        grid-column: 1 / 9;
        aspect-ratio: 1/1;
        min-height: 100%;
        height: 100%;

        @include tablet-landscape {
            grid-column: 8 / -2;
        }

        @include widescreen {
            grid-column: 8 / -3;
        }
        transform: translateY(200px);
        opacity: 0;
        transition: transform $move-in-animation-speed 0.2s,
            opacity $move-in-animation-speed 0.2s;
    }

    &__image-container {
        height: 100% !important;
        width: 100%;
        position: relative;

        &:after {
            background: linear-gradient(
                180.09deg,
                rgba(0, 0, 0, 0) 50%,
                rgba(0, 0, 0, 0.6) 93.06%
            );
            content: " ";
            @include object-fit;
        }
    }

    &__image {
        object-fit: cover;
        height: 100%;
        width: 100%;
        object-position: center;
    }
    &.in-view {
        .c-home-carousel {
            &__images {
                transform: translate(0);
                opacity: 1;
            }
        }
        .home-carousel-nav {
            transform: translate(0);
            opacity: 1;
        }
    }
}

.home-carousel-nav {
    margin-top: 16px;
    display: block;
    text-decoration: none;
    padding: 0px 20px;
    overflow: hidden;
    cursor: pointer;
    position: relative;
    color: #ababab;
    transform: translateY(200px);
    opacity: 0;
    &:nth-child(1) {
        transition: transform $move-in-animation-speed 0.3s,
            opacity $move-in-animation-speed 0.3s;
    }
    &:nth-child(2) {
        transition: transform $move-in-animation-speed 0.5s,
            opacity $move-in-animation-speed 0.5s;
    }
    &:nth-child(3) {
        transition: transform $move-in-animation-speed 0.7s,
            opacity $move-in-animation-speed 0.7s;
    }
    &:nth-child(4) {
        transition: transform $move-in-animation-speed 0.9s,
            opacity $move-in-animation-speed 0.9s;
    }

    &__content {
        opacity: 0;
        height: 0;
        max-height: 0;
        overflow: hidden;
        transition: all $transition-slow;
    }

    &__title,
    &__progress-bar {
        transition: background-color $transition-slow, color $transition-slow;
    }

    &__progress {
        width: 1px;
        position: relative;
    }

    &__description {
        margin-top: 12px;
    }

    &__button {
        margin-top: 16px;
    }

    &--active {
        color: $black;

        .home-carousel-nav {
            &__progress-bar {
                width: 1.5px;
                background-color: $purple;
                position: absolute;
                top: 0;
                left: 0;
            }

            &__description {
                color: $black;
                opacity: 0.7;
            }

            &__content {
                height: auto;
                opacity: 1;
                max-height: none;
            }
            &__title {
                color: $purple;
            }
        }

        color: $purple;

        &:hover {
            color: $purple;

            .home-carousel-nav {
                &__progress-bar {
                    background-color: $purple;
                }
                &__button {
                    color: $purple;
                }
            }
            .home-carousel-nav__button-arrow {
                @include arrow-glider-hover;
                &::after,
                &::before {
                    background-color: $purple;
                }
            }
        }
    }

    .home-carousel-nav__button {
        display: flex;
        align-items: center;

        padding-left: 0em;
        position: relative;
        font-weight: 400;

        color: $black;
        transition: color $transition-slow;
    }
    .home-carousel-nav__button-arrow {
        display: inline-block;
        margin-right: 4px;
        height: 1rem;
        width: 1rem;
        padding: 0 0.75rem;
        position: relative;

        @include arrow-glider;
        &::before,
        &::after {
            background-color: $black;
            mask-image: icon("arrow.svg");
            mask-position: left center;
            mask-repeat: no-repeat;
            mask-size: 13px;
            background-image: none;
        }
    }
}
