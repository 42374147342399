.share-icons {
  display: flex;

  &__item {
    display: flex;

    &:not(:last-child) {
      margin-right: 32px;
    }

    &--less-space {
      &:not(:last-child) {
        margin-right: 21px;
      }
    }
  }

  &__link {
    display: flex;
    align-items: center;
    text-decoration: none;

    &:hover {
      color: $green;
    }

    svg {
        scale: 1.2;
    }
  }

  &__icon {
    margin-right: 7px;
    margin-bottom: -4px;
  }

  &__label {
    font-size: 17px;
    font-weight: 600;
  }
}
