/*
Content Blocks

Default elements for content blocks

Style guide: 4-components.content-blocks
 */
.content-blocks {
  @include tablet-landscape {
    &--left {
      @include flex-percentage(12, 8);
      margin-left: 0;
    }

    &--right {
      @include flex-percentage(12, 8);
      margin-right: 0;
    }

    &--fullWidth {
      width: 100%;
    }

    &--small &__item,
    &--center &__item,
    &--middle &__item,
    &__item--videoGalleryBlock,
    &__item--imageGalleryBlock {
      margin-right: auto;
      margin-left: auto;

      @include flex-percentage(12, 8);
    }
  }

  &--no-margin {
    margin: 0;
  }

  &__heading {
    margin-bottom: 32px;
  }

  &__item--sectionTitleBlock {
    margin-bottom: 12px;
  }

  &__item--checkmarkListBlock,
  &__item--linkListBlock {
    margin-top: 12px;
    margin-bottom: 12px;
  }

  &__item {
    margin-top: 48px;
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0px;
    }
  }

  &__item--tableBlock {
    margin-top: 48px;
    margin-bottom: 48px;
  }

  &__item--contactBlock,
  &__item--newsletterSubscribeBlock {
    margin-top: 48px;
    margin-bottom: 48px;
  }

  &__item--doubleImageBlock,
  &__item--textImageBlock,
  &__item--imageBlock {
    margin-top: 32px;
    margin-bottom: 32px;

    @include tablet-landscape {
      margin-top: 40px;
      margin-bottom: 40px;
    }
  }

  &__item--latestNewsBlock {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  &__item--iframe {
    margin-bottom: 64px;
  }

  &__item--double-doubleIframeBlock {
    margin-bottom: 64px;
  }

  &__item--textBlock {
    margin-top: 10px;
  }

  &__item--videoBlock {
    margin-bottom: 64px;
  }

  &__item--cardWithLinksBlock {
    &:first-child {
      padding-top: 32px;
    }
  }

  &__item--textBlock + &__item--linkListBlock,
  &__item--textBlock + &__item--checkmarkListBlock {
    margin-top: 8px;
  }

  &__item--linkListBlock + &__item--textBlock,
  &__item--checkmarkListBlock + &__item--textBlock,
  &__item--iframe {
    margin-top: 12px;
  }

  &__item--sectionTitleBlock + &__item--questionsBlock,
  &__item--sectionTitleBlock + &__item--linkListBlock,
  &__item--sectionTitleBlock + &__item--checkmarkListBlock {
    margin-top: 10px;
  }
}

// .content-block__wrapper {
//   @include when-inside(".campaign") {
//     padding-bottom: 40px;

//     &:not(:first-child) {
//       padding-top: 40px;
//     }

//     @include tablet-landscape {
//       padding-bottom: 55px;
//       padding-top: 55px;
//     }
//   }
// }

/*
Content Block Header

Header element with 24px space below

Markup:
<div class="content-block__header" style="background: #DDD">
  <h2>Header</h2>
<div>

Style guide: 4-components.content-blocks.header
 */
.content-block__header {
  padding-bottom: 50px;

  @include tablet {
    padding-bottom: 42px;
  }

  @include tablet-landscape {
    padding-bottom: 56px;
  }
}

/*
Content Block Description

Basic block, 12px space above and below

Markup:
<div class="content-block__description" style="background: #DDD">
  Block Description
<div>

Style guide: 4-components.content-blocks.description
 */

.content-block__description {
  @include when-after(".content-block__title") {
    // TripleTopicsBlock / DescriptionCardBlock
    padding-top: 8px;
  }
}

/*
Content Block Main

Main content, 24px space above and below

Markup:
<div class="content-block__header" style="background: #DDD">
  <h2>Header</h2>
<div>

Style guide: 4-components.content-blocks.main
 */

.content-block__main {
  padding-top: $padding-size;
  padding-bottom: $padding-size;

  @include tablet-landscape {
    padding-top: 2 * $padding-size;
    padding-bottom: 2 * $padding-size;
  }
}
