.grid-block {
  gap: 1.5rem;
  display: block;

  &__grid {
    padding-top: 1.5rem;
    display: grid;
    flex-wrap: wrap;
    grid-template-columns: repeat(10, 1fr);
    gap: 0.5rem;
  }

  &__card {
    grid-column: auto / span 10;
  }

  @include tablet-landscape {
    gap: 2rem &__text {
      width: 50%;
    }

    &__grid {
      padding-top: 2rem;
      gap: 1.5rem;
    }

    &__card {
      &--narrow {
        grid-column: auto / span 4;
      }

      &--wide {
        grid-column: auto / span 6;
      }

      opacity: 0;
      transition: 0.8s all ease-in-out;

      &:nth-child(odd) {
        transform: translateX(-50%);
      }

      &:nth-child(even) {
        transform: translateX(50%);
      }

      @for $i from 1 through 10 {
        &:nth-child(#{$i}) {
          transition-delay: 0.25s * $i;
        }
      }

      .loaded & {
        opacity: 1;
        transform: none;
      }
    }
  }
}
