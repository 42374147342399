.page {
  &__item {
    display: none;

    &--active {
      display: block;
    }
  }
}

$input-row-height: 40;

.form {
  &__row {
    @include css-grid(6, 1fr, $gutter);
  }

  &__label {
    font-size: 17px;
    font-family: $text-font-stack;
    font-weight: 600;
    margin-bottom: 8px;

    &--required:after {
      display: inline-block;
      content: '*';
    }
  }

  &__field {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 36px;

    @include grid-column(1, 6);

    @include tablet-landscape {
      @include grid-column(1, 6);
    }

    &--double {
      &:first-child {
        @include grid-column(1, 3);
      }
      &:last-child {
        @include grid-column(4, 3);
      }
    }

    &--rich_text,
    &--html {
      @include grid-column(1, 6);
    }
  }

  &__field--has-error &__text,
  &__field--has-error &__textarea,
  &__field--has-error &__email,
  &__field--has-error &__check,
  &__field--has-error &__datetime-input,
  &__field--has-error &__website,
  &__field--has-error &__number,
  &__field--has-error &__phone {
    border-color: $danger;
  }

  &__text,
  &__email,
  &__textarea,
  &__select-list,
  &__select-input,
  &__datetime-input,
  &__website,
  &__number,
  &__phone,
  &__files-list-item {
    background-color: $white;
    border: 1px solid transparent;
    padding: 0 8px;
    width: 100%;
  }

  &__text,
  &__email,
  &__select-input,
  &__datetime-input,
  &__website,
  &__number,
  &__phone,
  &__files-list-item {
    position: relative;
    height: $input-row-height + px;
    line-height: $input-row-height + px;
  }

  &__select-input {
    &--open {
      border-bottom: 1px solid black;
    }
  }

  &__textarea {
    height: $input-row-height * 3 + px;
    line-height: $input-row-height + px;
  }
  &__label-group {
    display: flex;
    justify-content: flex-start;
  }

  &__instructions {
    position: relative;
    padding: 24px;
    background-color: $orange;
    margin-bottom: 8px;
  }

  &__instructions-icon {
    cursor: pointer;
    margin-top: 6px;
    margin-left: 6px;
    border: 1px solid $black;
    border-radius: 100%;
    height: 12px;
    width: 12px;
    font-size: 10px;
    color: $black;
    display: flex;
    justify-content: center;
    align-items: center;

    &--open {
      border-color: $orange;
      color: $orange;
    }
  }

  &__instructions-close {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 6px;
    margin-right: 12px;
    font-size: 20px;
    cursor: pointer;
    border: 0;
    padding: 0;
    background-color: transparent;
    &:hover {
      opacity: 0.5;
    }
  }

  &__option {
    display: flex;
    align-items: center;
    cursor: pointer;
    align-self: flex-start;
    align-items: flex-start;
  }

  &__option_input {
    display: none;
  }

  &__option_input:checked + &__check:after {
    display: inline-block;
  }

  &__check {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 0;
    flex-shrink: 0;
    overflow: hidden;
    width: 16px;
    height: 16px;
    margin-right: 12px;
    border: 1px solid black;
    margin-top: 5px;

    &:after {
      display: none;
      content: '';
      height: 10px;
      width: 10px;
      background-color: black;
    }

    &--radio {
      border-radius: 100%;

      &:after {
        border-radius: 100%;
      }
    }
  }

  &__error-message {
    color: red;
    font-size: 10px;
    text-transform: uppercase;
    font-family: $micro-font-stack;
    font-weight: 600;
  }

  &__datetime {
    position: relative;
  }

  &__datetime-input.active + &__input-icon::before {
    transform: rotate(-270deg);
  }

  &__select {
    position: relative;
  }

  &__select-list {
    background-color: #fff;
    max-height: $input-row-height * 7.5 + px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow: hidden;
    overflow-y: scroll;
  }

  &__input-icon {
    position: absolute;
    height: 100%;
    right: 0;
    top: 0;
    height: $input-row-height + px;
    margin-right: 12px;

    display: flex;
    align-items: center;
    justify-content: center;

    &::before {
      cursor: pointer;
      transition: $transition-fast;
      content: '';
      background-image: icon('chevron-left.svg');
      background-size: cover;
      transform: rotate(-90deg);
      height: $chevron-horizontal-mobile-height;
      width: $chevron-horizontal-mobile-width;

      @include tablet-landscape {
        height: $chevron-horizontal-mobile-height;
        width: $chevron-horizontal-mobile-width;
      }
    }

    &--open::before {
      transform: rotate(-270deg);

      @include tablet-landscape {
        margin-top: 0;
      }
    }
  }

  &__select-option {
    line-height: $input-row-height + px;
    width: 100%;
    cursor: pointer;

    &:not(&--selected) {
      &:hover {
        color: $green;
      }
    }

    &--selected {
      font-weight: 600;
    }
  }

  &__buttons {
    @include css-grid(2, 1fr, $gutter);

    @include tablet-landscape {
      @include css-grid(6, 1fr, $gutter);
    }

    &--page-0 {
      @include css-grid(6, 1fr, $gutter);

      @include tablet-landscape {
        @include css-grid(6, 1fr, $gutter);
      }
    }
  }

  &__button {
    &--previous {
      @include grid-column(1, 1);

      @include tablet-landscape {
        @include grid-column(2, 2);
      }
    }

    &--next {
      @include grid-column(2, 1);

      @include tablet-landscape {
        @include grid-column(4, 2);
      }
    }
  }

  &__buttons--page-0 &__button--next {
    @include grid-column(2, 4);

    @include tablet-landscape {
      @include grid-column(3, 2);
    }
  }

  &__files {
    @include css-grid(6, 1fr, $gutter);
  }
  &__files-list {
    @include grid-column(1, 6);

    margin-bottom: 8px;
  }
  &__files-list-item {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }
  &__files-input {
    display: none;
  }
  &__files-button {
    @include grid-column(3, 4);

    @include tablet-landscape {
      @include grid-column(5, 2);
    }
  }
}

input[type='file']::-webkit-file-upload-button {
  @extend .button;
  @extend .is-transparent;
  margin-right: $padding-size;
}

.flat-pickr-overrides {
  &__arrow {
    @include has-arrow-icon-left;
    padding: 0;
    height: 18px;
    width: 18px;
    position: absolute;
    right: 0;
    &::before,
    &:after {
      top: 0;
    }

    &--left {
      right: 0;
      transform: scale(-1);
    }
  }
}

.flatpickr-current-month,
.flatpickr-weekday,
.flatpickr-day {
  font-family: $micro-font-stack;
  font-size: calcEm(13px) !important;
  letter-spacing: calcEm(-0.1px, 50px) !important;
  line-height: 1.6667 !important;
  text-transform: uppercase !important;
  color: black !important;

  @include tablet {
    font-size: calcEm(12px);
  }
}

.flatpickr-current-month .flatpickr-monthDropdown-months,
.flatpickr-current-month input.cur-year,
.flatpickr-weekday {
  font-weight: 600 !important;
}

.flatpickr-monthDropdown-months {
  text-transform: uppercase !important;
}

.flatpickr-day {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;

  &.selected {
    color: black !important;
    background: transparent !important;
    border: transparent !important;
    font-weight: 600 !important;
  }

  &.today {
    border: transparent !important;
    color: $orange !important;
  }

  &.nextMonthDay,
  &.flatpickr-disabled {
    color: $grey !important;
  }
}

.flatpickr-weekday {
  font-size: 0 !important;

  &::first-letter {
    font-size: 12px !important;
  }
}

.flatpickr-calendar {
  border-radius: 0 !important;
}

.c-select {
  display: grid;
  grid-template-areas: 'select';
  min-width: 200px;

  select {
    grid-area: select;
    align-items: center;

    appearance: none;
    background-color: transparent;
    border: none;
    font-family: $title-font-stack;

    border: 1px solid $black;
    padding-left: 14px;
    height: $input-height;
    background-color: $white;
    font-weight: 300;
    color: $black;
  }

  &::after {
    grid-area: select;
    justify-self: end;
    align-self: center;

    content: '';
    width: 14px;
    height: 8px;
    background-color: $black;
    background: icon('chevron-down.svg');
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 16px;
  }
}

.fui-i {
  --fui-border-radius: 0;

  --fui-submit-btn-bg-color: #{$orange};
  --fui-submit-btn-border-color: transparent;
  --fui-submit-btn-bg-color-hover: #{darken($color: $orange, $amount: 10%)};
  --fui-submit-btn-border-color-hover: transparent;

  --fui-btn-font-weight: 500;
  --fui-btn-font-size: 18px;
  --fui-btn-padding: 12px 16px;

  --fui-font: #{$text-font-stack};

  --fui-check-check-bg-image: #{icon("rectangle.svg")};
  --fui-check-label-bg-color: transparant !important;
  --fui-check-check-border-radius: 4px !important;
  --fui-check-bg-color-checked: $white !important;
  --fui-check-border-color-checked: $white !important;
}
