.c-service-questions-block {
  @include full-grid-with-column-gap;

  row-gap: 40px;

  &__content {
    grid-column: main-start/ main-end;

    @include tablet-landscape {
      grid-column: main-start/ span 5;
    }

    @include widescreen {
      grid-column: 3 / span 4;
    }
  }

  &__articles {
    grid-column: main-start/ main-end;

    @include tablet-landscape {
      grid-column: span 7 / main-end;
    }

    @include widescreen {
      grid-column: span 6 / -3;
    }
  }
}
