/*
General Header

Use modifier '.has-breadcrumbs' for smaller padding-top

Markup:
<header class="general-header has-breadcrumbs">
  <div class="container">
    <div class="breadcrumb-list">
      <a class="breadcrumb-item is-first-parent" href="#">
        <span class="breadcrumb-item__back-to">Back to</span>
        Info page
      </a>
      <span class="breadcrumb-item">
        Detail page
      </span>
    </div>
    <div class="general-header__columns">
      <div class="general-header__content">
        <h1 class="general-header__title">
          Fust
        </h1>
        <p class="general-header__description">
          In de sierteeltketen gebruiken we verschillende verpakkingen. Die zijn
          belangrijk om uw product goed te kunnen vervoeren. Royal FloraHolland
          voorziet u dan ook graag van het juiste verpakkingsmiddel. Hier vind u
          alles over: Fust.
        </p>
      </div>
      <div class="general-header__notification-card-container">
        <div class="notification-card">
          <h6 class="notification-card__tag has-black-text">Tag</h6>
          <h5>Title</h5>
          <p class="notification-card__description">Notification card text</p>
          <a href="#" class="text-button__uppercase has-white-text">
            Read more
          </a>
        </div>
    </div>
  </div>
</header>

Style guide: 3-layout.2-headers.4-general-header
*/

/*
General Header - without breadcrumb

Markup:
<header class="general-header">
  <div class="container">
    <div class="general-header__columns">
      <div class="general-header__content">
        <h1 class="general-header__title">
          Fust
        </h1>
        <p class="general-header__description">
          In de sierteeltketen gebruiken we verschillende verpakkingen. Die zijn
          belangrijk om uw product goed te kunnen vervoeren. Royal FloraHolland
          voorziet u dan ook graag van het juiste verpakkingsmiddel. Hier vind u
          alles over: Fust.
        </p>
      </div>
      <div class="general-header__notification-card-container">
        <div class="notification-card">
          <h6 class="notification-card__tag has-black-text">Tag</h6>
          <h5>Title</h5>
          <p class="notification-card__description">Notification card text</p>
          <a href="#" class="text-button__uppercase has-white-text">
            Read more
          </a>
        </div>
    </div>
  </div>
</header>

Style guide: 3-layout.2-headers.4-general-header.without-breadcrumb
*/

@use "sass:math";

$general-header--z-index: 15;

.general-header {
  @extend .header-section;
  padding-left: $section-outer-padding--mobile;
  padding-right: $section-outer-padding--mobile;

  background-image: image('header-vormtaal-mobile.svg');
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;

  @include tablet-landscape {
    background-image: image('header-vormtaal.svg');
  }

  &--green {
    background-image: image('header-vormtaal-mobile-green.svg');

    @include tablet-landscape {
      background-image: image('header-vormtaal-green.svg');
    }
  }
}

.general-header__columns {
  @include basic-flex-box($breakpoint: 'tablet-landscape');
  justify-content: space-between;
}

.general-header__content {
  @include basic-flex-item;
  padding-bottom: 44px;
  padding-top: 28px;
  position: relative;

  @include when-inside('.has-breadcrumbs') {
    padding-top: 16px;
  }

  @include tablet-landscape {
    flex: none;
    min-height: initial;
    padding-bottom: 58px;
    padding-top: 64px;
    width: math.div(7, 12) * 100%;
  }

  @include widescreen {
    width: 50%;
  }
}

.general-header__title {
  padding-bottom: 8px;
}

.general-header__description {
  @extend .header__description;
}

.general-header__share {
  margin-top: 20px;
}

.general-header__share-item {
  &:not(:last-child) {
    margin-right: 24px;
  }
}

.general-header__notification-card-container {
  @include basic-flex-item;

  @include tablet-landscape {
    align-self: flex-end;
    flex: none;
    width: math.div(5, 12) * 100%;
  }

  @include widescreen {
    width: math.div(4, 12) * 100%;
  }
}

/*
General Header - Page Summary

Markup:
<div class="general-header__page-summary">
  <div class="general-header__page-summary__date">
    20 mei 2020
  </div>

  <div class="general-header__page-summary__interest">
    <div class="pill-list">
      <div class="pill-list__item">
        <div class="pill">
          pill text
        </div>
      </div>
      <div class="pill-list__item">
        <div class="pill">
          pill text
        </div>
      </div>
    </div>
  </div>
</div>

Style guide: 3-layout.2-headers.4-general-header.page-summary
*/
.general-header__page-summary {
  @include basic-flex-box($padding: 0, $breakpoint: 'tablet-landscape');
  align-items: center;
}

.general-header__page-summary__date,
.general-header__page-summary__interest {
  margin: 6px 0;

  @include tablet-landscape {
    margin-bottom: 0;
    margin-top: 0;
  }
}

.general-header__page-summary__date {
  @extend h6;
  font-weight: 300;

  @include tablet-landscape {
    @include basic-flex-item($padding: 0, $columns: 2);
  }
}
