.c-text-image-wider {
  @include full-grid-with-column-gap;
  row-gap: 16px;
  align-items: center;

    &__content {
        grid-column: main-start / main-end;
        display: flex;
        flex-direction: column;
        justify-content: center;

    @include tablet-landscape {
      grid-row: 1 / span 1;
      grid-column: 2 / span 6;
    }

    @include widescreen {
      grid-column: 3 / span 5;
    }
  }

  &__visual {
    grid-column: main-start / main-end;

    @include tablet-landscape {
      grid-row: 1 / span 1;
      grid-column: span 6 / -2;
    }

    @include widescreen {
      grid-column: span 5 / -3;
    }
  }

  &--imageLeft &__content {
    @include tablet-landscape {
      grid-column: span 6 / -2;
    }

    @include widescreen {
      grid-column: span 5 / -3;
    }
  }

  &--imageLeft &__visual {
    @include tablet-landscape {
      grid-column: 2 / span 6;
    }

    @include widescreen {
      grid-column: 3 / span 5;
    }
  }

  &__heading,
  &__paragraph,
  &__arrow {
    opacity: 0;
    transform: translateY(20%);
    transition: all $transition-slow ease-out;

    .loaded & {
      transform: translateY(0%);
      opacity: 1;
    }
  }

  &__paragraph {
    transition-delay: $transition-fast;
  }
  &__arrow {
    transition-delay: $transition-slow;
    margin-top: 1.5rem;
  }
}
