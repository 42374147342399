
            @import "@/styles/abstracts/all.scss";
          

#search-anker {
    @include until-tablet {
        display: none;
    }
}

.search-bar {
    color: inherit;
    &__wrapper {
        display: none;
        position: fixed;
        background-color: white;
        left: 0;
        width: 100vw;
        height: 104px;
        opacity: 0;
        z-index: 19;
        pointer-events: none;
        transform: translateY(-105px);
        transition: transform 0.5s ease-out;
        border-top: 1px solid $grey-very-light;

        @include tablet-landscape {
            display: block;
        }

        &--moves-down {
            transform: translateY(0);
        }
        &--open {
            top: v-bind(topPosition);
            opacity: 1;
            pointer-events: all;
        }
        &--temp-hidden {
            transition: opacity 0.3s ease-out;
            transform: translateY(0);
            pointer-events: none;
            opacity: 0;
        }
    }
    &__container {
        margin: 0 auto;
        height: 104px;
        @include container;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &__input {
        font-size: 48px;
        line-height: 1;
        width: 100%;
        max-width: 914px;
        border: 0;
        font-weight: 500;
        font-family: $title-font-stack;
        &::placeholder {
            color: #ccc;
            font-weight: 500;
        }
        &:active,
        &:focus {
            outline: 0;
        }
    }
    &__icon {
        width: 19px;
        height: 19px;
    }

    &__button {
        background-color: $orange;
        border: 1px solid $orange;
        color: white;
        transition: all 0.1s ease-out;
        width: 60px;
        height: 48px;
        padding: 0;
        cursor: pointer;
        &:disabled {
            color: black;
            background-color: transparent;
            border: 1px solid $black;
        }
        &--text {
            color: inherit;
            background-color: transparent;
            border: 0;
            display: flex;
            align-items: center;
            width: fit-content;
            gap: 8px;
            height: auto;
        }
    }
    &__underlay {
        @include until-tablet-landscape {
            display: none;
        }
        position: fixed;
        display: block;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 10;
        transition: backdrop-filter 0.5s ease-out;
        -webkit-backdrop-filter: blur(0px);
        backdrop-filter: blur(0px);
        pointer-events: none;
        &--open {
            pointer-events: all;
        }
        &--moves-down {
            -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
        }
    }
}
