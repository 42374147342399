.body {

    &--white {
        background-color: $white;
    }

    &--jobs {
        padding-top: $navigation-mobile-height;

        @include tablet-landscape {
            padding-top: $navigation-jobs-height;
        }
    }
}

.sidebar {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    &:not(:last-of-type) {
        margin-bottom: $padding-size * 6.5;
    }

    @include tablet-landscape {
        flex-direction: row;
        flex-wrap: wrap;
    }

    &__header {
        width: 100%;
        margin-bottom: $padding-size * 1.5;
        margin-top: $padding-size;

        @include tablet-landscape {
            margin-top: 0;

            & > * {
                @include flex-percentage(12, 3);
            }
        }
    }

    &__side {
        @include tablet-landscape {
            @include flex-percentage(12, 3);

            &--wide {
                @include flex-percentage(12, 4);
            }
        }

        &--hide-until-tablet-landscape {
            display: none;

            @include tablet-landscape {
                display: block;
            }
        }

        &--first-mobile {
            order: -1;

            @include tablet-landscape {
                order: initial;
            }
        }
    }

    &__main {
        width: 100%;

        @include tablet-landscape {
            @include flex-percentage(12, 8);
        }
    }
}

.accordion {
    margin-left: -$section-outer-padding--mobile;
    margin-right: -$section-outer-padding--mobile;

    @include tablet {
        margin-left: 0;
        margin-right: 0;
    }

    $inactive-alpha: 0.3;

    &--active > &__item {
        border-color: rgba($color: #000000, $alpha: $inactive-alpha) !important;
    }
    &--active > &__item:not(.accordion__item--active) > &__trigger {
        opacity: $inactive-alpha;

        &:hover {
            opacity: 1;
        }
    }

    &__item {
        padding-left: $section-outer-padding--mobile;
        padding-right: $section-outer-padding--mobile;
        border-bottom: 1px solid $black;

        @include tablet {
            padding-left: 0;
            padding-right: 0;
        }

        &:first-child {
            border-top: 1px solid $black;
        }
    }

    &__trigger {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: $padding-size * 2;
        padding-bottom: $padding-size * 2;

        & > * {
            font-weight: 300;
        }

        @include tablet-landscape {
            padding-top: $padding-size * 1.5;
            padding-bottom: $padding-size * 1.5;

            &:hover {
                color: $green;
            }
        }
    }

    &__trigger-icon {
        transition: $transition-fast;

        @include tablet-landscape {
            margin-right: $padding-size;
        }
    }

    &__item--active > &__trigger > * {
        font-weight: 700 !important;
    }

    &__item--active > &__trigger > &__trigger-icon {
        transform: rotate(90deg);
    }

    &__content {
        padding-top: $padding-size;
        padding-bottom: $padding-size * 2;
        display: flex;
        flex-direction: column;
    }

    &__link {
        text-decoration: none;

        &:hover {
            color: $green;
        }
    }

    &--sublevel:not(:last-child),
    &__link:not(:last-child) {
        margin-bottom: $padding-size;
    }

    &--sublevel > &__item {
        border: none;
    }

    &--sublevel > &__item > &__content {
        padding-left: $padding-size * 1.5;
        padding-right: $padding-size * 1.5;
        padding-bottom: $padding-size * 3;
        padding-top: $padding-size;

        @include tablet-landscape {
            padding-bottom: $padding-size * 2;
        }
    }

    &--sublevel > &__item > &__trigger {
        padding-top: 0px;
        padding-bottom: 0px;
    }

    @include tablet-landscape {
        // Accordion layout with sidenav
        &--has-sidenav > &__item {
            border: none !important;
        }

        &--has-sidenav > &__item > &__trigger {
            display: none;
        }

        &--has-sidenav > &__item > &__content {
            padding-top: 0px;
            padding-bottom: 0px;
        }

        //Accordion layout navigation type
        &--two-columns-tablet-landscape {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            flex-wrap: wrap;
        }

        &--two-columns-tablet-landscape > &__item {
            @include flex-percentage(2, 1);

            &:nth-child(-n + 2) {
                border-top: 1px solid $black;
            }
        }
    }
}

.l-card-with-links {
    display: flex;
    flex-direction: column;
    row-gap: 40px;
    flex-wrap: wrap;

    @include tablet-landscape {
        flex-direction: row;
        justify-content: space-between;
    }

    &__card {
        @include tablet-landscape {
            @include flex-percentage(12, 6);
        }

        @include widescreen {
            @include flex-percentage(12, 4);
        }
    }

    &__links {
        @include tablet-landscape {
            @include flex-percentage(12, 12);
        }

        @include widescreen {
            @include flex-percentage(12, 8);
        }
    }
}

.l-section-overview {
    display: grid;
    row-gap: 16px;
    padding-top: 32px;
    padding-bottom: 32px;

    @include tablet-landscape {
        grid-template-columns: repeat(3, 1fr);
        row-gap: 40px;
        column-gap: 20px;
    }

    &--1-column {
        @include tablet-landscape {
            grid-template-columns: repeat(1, 1fr);
            row-gap: 20px;
        }
    }
}

.l-content-aside {
    @include full-grid;
    position: relative;
    margin-top: -32px;

    @include tablet-landscape {
        margin-top: -60px;
    }

    @include widescreen {
        margin-top: -100px;
        grid-template-rows: 100px auto;
    }

    &__main {
        background-color: $white;
        padding: 16px;
        grid-column: main-start/ main-end;
        z-index: 2;
        padding-bottom: 40px;

        @include tablet-landscape {
            padding-top: 44px;
            padding-right: 32px;
            padding-left: 32px;
            grid-column: 3 / -3;
        }

        @include widescreen {
            grid-column: 3 / span 7;
            grid-row: 1 / span 2;
        }
    }

    &__secondary {
        grid-column: main-start/ main-end;

        @include tablet-landscape {
            grid-column: 3 / -3;
        }

        @include widescreen {
            padding-top: 40px;
            padding-left: 20px;
            padding-right: 0px;
            grid-column: span 4 / main-end;
            grid-row: 2 / span 1;
        }
    }
}

.l-blocks {
    &__item {
        border-top: $border-standard;
        padding-top: 24px;
        padding-bottom: 24px;

        @include widescreen {
            padding-top: 32px;

            &:first-of-type {
                padding-top: 0px;
                border-top: none;
            }
        }
    }
}

.c-divider {
    border: none;
    height: 1px;
    background-color: $grey;
    margin-top: 24px;
    margin-bottom: 24px;

    &--margin-top-o {
        margin-top: 0px;
    }
}

.l-section {
    margin-top: 64px;
    margin-bottom: 64px;
    position: relative;

    @include tablet-landscape {
        margin-top: 120px;
        margin-bottom: 120px;

        &:first-of-type,
        &--small-margin-next-section + & {
            margin-top: 60px;
        }
    }

    &--small {
        margin-top: 20px;
        margin-bottom: 20px;

        @include tablet-landscape {
            margin-top: 40px;
            margin-bottom: 40px;
        }
    }

    &--tiny {
        margin-top: 16px !important;
        margin-bottom: 16px !important;

        @include tablet-landscape {
            margin-top: 24px !important;
            margin-bottom: 24px !important;
        }
    }

    &--hide-overflow {
        overflow: hidden;
    }

    &--green-100 {
        background-color: $green-100;
        margin: 0px;
        padding-top: 64px;
        padding-bottom: 64px;
    }
}

.l-container {
    @include full-grid;

    & > * {
        grid-column: main-start / main-end;

        @include widescreen {
            grid-column: 3 / -3;
        }
    }

    &--full-width {
        & > * {
            @include widescreen {
                grid-column: main-start / main-end;
            }
        }
    }
}

.c-knowledge-index {
    padding-top: 48px;
    padding-bottom: 80px;

    @include full-grid-with-column-gap;

    @include tablet-landscape {
        padding-bottom: 48px;
    }

    &__refinements {
        @include tablet-landscape {
            grid-column: main-start / span 4;
        }

        @include widescreen {
            grid-column: main-start / span 3;
        }
    }

    &__hits {
        margin-bottom: 64px;
        grid-column: main-start / main-end;

        @include tablet-landscape {
            grid-column: span 8 / main-end;
        }
    }

    &__pagination {
        grid-column: main-start / main-end;

        @include tablet-landscape {
            grid-column: span 8 / main-end;
        }
    }

    &--smaller {
        @include full-grid;
        column-gap: 0px;
    }

    &--smaller &__refinements {
        @include tablet-landscape {
            grid-column: main-start / span 4;
        }

        @include widescreen {
            grid-column: 3 / span 3;
        }
    }

    &--smaller &__hits {
        @include tablet-landscape {
            grid-column: span 8 / main-end;
            margin-left: 40px;
        }

        @include widescreen {
            grid-column: span 7 / -3;
        }
    }

    &--smaller &__pagination {
        @include tablet-landscape {
            margin-left: 40px;
            grid-column: span 9 / main-end;
        }

        @include widescreen {
            grid-column: span 7 / -3;
        }
    }
}

.l-aside {
    @include full-grid-with-column-gap;

    &__main {
        grid-column: main-start / main-end;

        @include tablet-landscape {
            grid-column: span 7 / main-end;
        }

        @include widescreen {
            grid-column: span 6 / -3;
        }
    }

    &__aside {
        grid-column: main-start / main-end;

        @include tablet-landscape {
            grid-column: main-start / span 5;
        }

        @include widescreen {
            grid-column: 3 / span 4;
        }
    }
}

.l-two-thirds {
    @include full-grid;

    &__main {
        grid-column: main-start / main-end;

        @include tablet-landscape {
            grid-column: main-start / span 8;
        }

        @include widescreen {
            grid-column: 3 / span 7;
        }
    }

    &__aside {
        grid-column: main-start / main-end;

        @include tablet-landscape {
            grid-column: span 4 / main-end;
        }

        @include widescreen {
            grid-column: span 3 / -3;
        }
    }
}

.l-tarifs-search {
    @include full-grid-with-column-gap;

    &__topbar {
        display: none;

        @include tablet-landscape {
            display: flex;
            align-items: flex-end;
            grid-column: main-start / main-end;

            padding-bottom: 24px;
            border-bottom: $border-medium;
            margin-bottom: 24px;
        }

        @include widescreen {
            grid-column: 3 / -3;
        }
    }

    &__sidebar {
        display: none;

        @include tablet-landscape {
            display: flex;
            grid-column: main-start / span 3;
            align-items: start;
        }
    }

    &__main {
        grid-column: 1 / -1;

        @include tablet {
            grid-column: main-start / main-end;
        }

        @include tablet-landscape {
            grid-column: span 9 / main-end;
        }
    }
}
