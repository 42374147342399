.c-downloads-block {
    display: grid;
    gap: 20px;

    @include tablet {
        grid-template-columns: repeat(2, 1fr);
    }

    @include desktop {
        grid-template-columns: repeat(3, 1fr);
    }

    &__item {
        position: relative;
        display: flex;
        padding: 16px;
        gap: 16px;
        border: $border-standard-2023;
        border-radius: 6px;
        width: 100%;
        justify-content: space-between;
        align-items: flex-start;
        transition: all $transition-fast ease;
        text-decoration: none;
        cursor: pointer;
    }

    &__title {
        font-size: 21px;
        font-weight: 400;
        span {
            @include text-link;
        }
    }

    &__item:hover &__title {
        span {
            @include text-link-hover;
        }
    }

    &__item:hover &__button {
        background-color: $green;
    }

    &__button {
        border: 0;
        display: flex;
        flex: 0 0 32px;
        justify-content: center;
        align-items: center;
        height: 32px;
        width: 32px;
        background-color: $orange;
        transition: all $transition-fast ease;
    }
}
