@use "sass:math";

.newsletter {
  position: relative;
  width: 100%;
  background-color: $grey;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  padding: 44px 16px;

  @include tablet-landscape {
    justify-content: flex-start;
    padding: 32px 18.5px;

    background: radial-gradient(
      circle at right -40% bottom -80%,
      #fff 50%,
      $grey 50% 100%
    );
  }

  @include widescreen {
    background: radial-gradient(
      circle at right -18% bottom -230%,
      #fff 50%,
      $grey 50% 100%
    );
  }

  &__visual {
    width: math.div(100, 6) * 2%;

    @include tablet-landscape {
      flex: 0 0 (100 * 0.125 * 1%);
    }
  }

  &__image {
    max-width: 100%;
  }
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 89px;
    padding-left: 16px;
    width: math.div(100, 6) * 4%;
    z-index: 1;

    @include tablet-landscape {
      margin-left: 47px;
      padding-left: 0;
      width: 100 * 0.125 * 4%;
    }

    @include desktop {
      width: 100 * 0.125 * 3%;
    }
  }
  &__cta {
    flex: 0 0 100%;
    margin-top: 22.5px;

    .button {
      width: 100%;
    }

    @include tablet-landscape {
      flex: 0 0 100 * 0.125 * 2%;
      margin: 0;
      margin-left: auto;
    }
  }
}
