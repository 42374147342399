/*
Icon before link

Markup:
<a href="#" class="has-icon__emaillink">Link</a>
<a href="#" class="has-icon__phonelink">Link</a>
<a href="#" class="has-icon__whatsapplink">Link</a>
<a href="#" class="has-icon__facebookchatlink">Link</a>

Style guide: 4-components.icons
 */
[class^="has-icon__"],
[class*=" has-icon__"] {
  display: inline-block;
  padding-left: 1.5em;
  background-image: image("no-image.svg");
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 1em;
}

.has-icon__emaillink {
  background-image: icon("email.svg");
  background-position: 0 0.875em;
}

.has-icon__phonelink {
  background-image: icon("telephone.svg");
  background-position: 0 0.75em;
}

.has-icon__whatsapplink {
  background-image: icon("whatsapp.svg");
  background-position: 0 0.75em;
}

.has-icon__facebookchatlink {
  background-image: icon("messenger.svg");
  background-position: 0 0.75em;
}

.has-icon__link {
  background-image: icon("link.svg");
  background-size: 16px 16px;
}

.has-icon__flag--en-US {
  background-image: icon("flag-en-US.svg");
}

.has-icon__flag--nl-NL {
  background-image: icon("flag-nl-NL.svg");
}

.has-icon__flag--de-DE {
  background-image: icon("flag-de-DE.svg");
}

.icon {
  &--chevron-right {
    height: $chevron-horizontal-mobile-height;
    width: $chevron-horizontal-mobile-width;

    @include tablet-landscape {
      height: $chevron-horizontal-mobile-height;
      width: $chevron-horizontal-mobile-width;
    }
  }

  &--shield {
    background-color: $white;
    padding: 4px;
    border-radius: 200px;
    height: 32px;
    width: 32px;
  }
}
