
            @import "@/styles/abstracts/all.scss";
          

.c-label {
    font-size: 14px;
    color: $black;

    strong {
        text-transform: uppercase;
        margin-right: 0.5rem;
    }
}
