$component: "c-table-column";

.#{$component} {
    &__content {
        z-index: 2;
        grid-column: main-start / main-end;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        grid-row: 2 / span 1;
        padding-top: 24px;
        align-self: center;

        @include tablet-landscape {
            padding-top: 0px;
            grid-column: 2 / span 5;
        }

        @include widescreen {
            grid-column: 3 / span 4;
        }
    }
    &__wrapper {
        display: flex;
        flex-direction: column;
        gap: 24px;
    }
    &__table-wrapper {
        overflow-x: auto;
        padding-bottom: 8px;

        @include scrollbar-styling;
    }
    &__table {
        border-spacing: 0;
        table-layout: fixed;
    }
    &__table-row {
        &:first-of-type:not(&--header) {
            td {
                &:first-child {
                    border-top-left-radius: 6px;
                }
                &:last-child {
                    border-top-right-radius: 6px;
                }
            }
        }
        &:last-of-type {
            td {
                &:first-child {
                    border-bottom-left-radius: 6px;
                }
                &:last-child {
                    border-bottom-right-radius: 6px;
                }
            }
        }
        &--header {
            .#{$component} {
                &__table-cell {
                    border: 0;
                    vertical-align: bottom;
                    font-weight: 600;
                    &--header {
                        background-color: white;
                    }
                    &:first-of-type {
                        font-size: 28px;
                        font-weight: 400;
                        line-height: 1;
                    }
                }
            }
            & + .#{$component}__table-row {
                td {
                    &:first-child {
                        border-top-left-radius: 6px;
                    }
                    &:last-child {
                        border-top-right-radius: 6px;
                    }
                }
            }
        }
    }
    &__table-cell {
        border: 0.5px solid $grey-very-light;
        vertical-align: top;
        padding: 8px 24px;
        font-size: 14px;
        line-height: calc(22px / 14px);
        min-width: 140px;
        &--header {
            background-color: $grey-very-light;
        }
    }
    &__table-cell-flex-wrapper {
        display: flex;
        align-items: center;
        gap: 8px;
    }
    &__icon {
        width: 24px;
        height: 24px;
    }

    &__table {
        &--green {
            .#{$component} {
                &__table-cell {
                    border-color: $green-100;
                }

                &__table-row {
                    &:not(&--header) {
                        &:nth-child(odd) {
                            .#{$component} {
                                &__table-cell {
                                    &--header {
                                        background-color: $green-100;
                                        border-color: $green-100;
                                    }
                                }
                            }
                        }

                        &:nth-child(even) {
                            .#{$component} {
                                &__table-cell {
                                    &--header {
                                        background-color: $green-200;
                                        border-color: $green-200;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @include tablet-landscape {
        &__wrapper {
            &--left {
                flex-direction: row-reverse;
            }
            &--right {
                flex-direction: row;
            }
            &--full-width {
                flex-direction: column;
            }
        }
    }
}
