.c-calculation-tool {
    @include full-grid-with-column-gap;
    row-gap: 20px;

    @include tablet-landscape {
        grid-template-rows: auto 1fr;
    }

    &__main {
        grid-column: main-start / main-end;

        @include tablet-landscape {
            grid-column: main-start / span 7;
            grid-row: 1 / span 1;
        }

        @include widescreen {
            grid-column: 3 / span 7;
        }
    }

    &__secondary {
        grid-column: main-start / main-end;

        @include tablet-landscape {
            grid-column: span 5 / main-end;
            grid-row: 1 / span 2;
        }

        @include widescreen {
            grid-column: span 4 / main-end;
        }
    }

    &__button {
        grid-column: main-start / main-end;

        @include tablet-landscape {
            grid-column: main-start / span 7;
            grid-row: 2 / span 1;
        }

        @include widescreen {
            grid-column: 3 / span 7;
        }        
    }
}
