@use "sass:math";

.video-block {
  figure {
    height: 0;
    padding-top: math.div(100, 16) * 9%;
    position: relative;
  }

  iframe {
    bottom: 0;
    height: 100% !important;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100% !important;
  }

  &__text {
    padding-bottom: 24px;

    h3 {
      margin-bottom: 12px;
    }

    @include tablet-landscape {
      padding-bottom: 40px;
    }
  }

  &__background {
    display: none;
  }

  &--campaign &__wrapper {
    position: relative;
    margin-left: -$section-outer-padding--mobile;
    margin-right: -$section-outer-padding--mobile;
    padding: $section-outer-padding--mobile $section-outer-padding--mobile
      $section-outer-padding--mobile 0;

    @include tablet {
      padding: $section-outer-padding $section-outer-padding
        $section-outer-padding 0;
    }

    @include tablet-landscape {
      width: math.div(100%, 12) * 9;
      margin: 0 auto;
      padding: 38px 38px 38px 0;
    }
  }

  &--campaign &__background {
    background-color: $purple;
    display: block;
    position: absolute;
    height: 100%;
    right: 0;
    top: 0;
    bottom: 0;
    width: calc(50% + #{$padding-size * .5});

    @include tablet-landscape {
      width: 55%;
    }
  }
}
