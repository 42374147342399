.skip-to-content-link {
    position: fixed;
    top: 20px;
    left: 20px;
    background: $orange;
    border: 1px solid $orange;
    display: inline-block;
    color: $white;
    text-align: center;
    text-decoration: none;
    padding: 0.5em 1.5rem;
    font-family: $micro-font-stack;
    font-size: 0.8125em;
    letter-spacing: -0.002em;
    line-height: 1.6667;
    text-transform: uppercase;
    transform: translateY(-100px);
    transition: all ease 0.3s;
    z-index: 999;

    @mixin widescreen {
        left: calc((100dvw - 1440px) / 2);
    }

    &:focus {
        transform: translateY(0);
        background: $green;
        border-color: $green;
        color: $white;
    }
}
