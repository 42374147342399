// -----------------------------------------------------------------------------
// This file contains CSS utility/helper classes.
// -----------------------------------------------------------------------------

/**
 * Clear inner floats
 */
.clearfix::after {
    clear: both;
    content: "";
    display: table;
}

/**
 * Hide text while making it readable for screen readers
 * 1. Needed in WebKit-based browsers because of an implementation bug;
 *    See: https://code.google.com/p/chromium/issues/detail?id=457146
 */
.hide-text {
    overflow: hidden;
    padding: 0; /* 1 */
    text-indent: 101%;
    white-space: nowrap;
}

/**
 * Hide element while making it readable for screen readers
 * Shamelessly borrowed from HTML5Boilerplate:
 * https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css#L119-L133
 */
.visually-hidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.object-fit {
    @include object-fit;
}

@include until-tablet {
    .hidden-mobile {
        display: none;
    }
}

@include tablet {
    .hidden-tablet {
        display: none;
    }
}

.has-animation--slide-up {
    animation: slide-up $transition-slow forwards ease-out;
    animation-delay: 1s;
    opacity: 0;
}

$u-very-tiny: 4px;
$u-tiny: 12px; //paddingsize
$u-extra-small: 24px;
$u-small: 36px;
$u-medium: $padding-size * 4.5;
$u-big: $padding-size * 6.5;

// TEXT MODIFIERS
.has-text-centered {
    text-align: center;
}

.has-black-text {
    color: $black;
}

.has-white-text {
    color: $white;
}

.u-margin-top-big {
    display: block;
    margin-top: $u-big;
}

.u-margin-top-medium {
    display: block;
    margin-top: $u-medium;
}

.u-margin-top-small {
    display: block;
    display: block;
    margin-top: $u-small;
}

.u-margin-top-tiny {
    display: block;
    margin-top: $u-tiny;
}

.u-margin-bottom-medium {
    display: block;
    margin-bottom: $u-medium;
}

.u-margin-bottom-small {
    display: block;
    margin-bottom: $u-small;
}

.u-margin-bottom-tiny {
    display: block;
    margin-bottom: $u-tiny;
}

.u-margin-bottom-very-tiny {
    display: block;
    margin-bottom: $u-very-tiny;
}

.u-margin-right-small {
    display: block;
    margin-right: $u-small;
}

.u-margin-right-tiny {
    display: block;
    margin-right: $u-tiny;
}

.u-margin-right-very-tiny {
    display: block;
    margin-right: $u-very-tiny;
}

.u-margin-left-very-tiny {
    display: block;
    margin-left: $u-very-tiny;
}

.u-margin-top-extra-small {
    display: block;
    margin-top: $u-extra-small;
}

.u-margin-bottom-extra-small {
    display: block;
    margin-bottom: $u-extra-small;
}

.u-margin-top-auto {
    margin-top: auto;
}

// Margin helpers responsive.
.u-margin-bottom-small-responsive {
    display: block;
    margin-bottom: 16px;

    @include tablet-landscape {
        margin-bottom: 24px;
    }
}

.u-margin-top-small-responsive {
    display: block;
    margin-top: 16px;

    @include tablet-landscape {
        margin-top: 24px;
    }
}

.u-display-none {
    display: none;
}

.u-display-tablet-landscape {
    @include tablet-landscape {
        display: block;
    }
}

.u-display-tablet {
    @include tablet {
        display: block;
    }
}

.u-hidden-tablet-landscape {
    @include tablet-landscape {
        display: none;
    }
}

.u-width-100 {
    width: 100% !important;
}

.u-width-auto {
    width: auto !important;
}

.u-height-100 {
    height: 100% !important;
}

.u-half-tablet-landscape {
    width: 100%;

    @include tablet-landscape {
        width: 50%;
    }
}

.u-font-weight-300 {
    font-weight: 300 !important;
}

.u-text-transform-capitalize {
    text-transform: capitalize !important;
}

.u-is-disabled {
    pointer-events: none;
}

.u-basic-reset {
    @include basic-reset;
}

.u-clickable {
    cursor: pointer !important;
}

.u-overflow-x-scroll {
    overflow-x: scroll !important;
}

.u-flex {
    display: flex;
}

.u-flex-align-center {
    display: flex;
    align-items: center;
}
.u-flex-align-start {
    display: flex;
    align-items: flex-start;
}

.u-flex-column {
    display: flex;
    flex-direction: column;
}

.u-flex-column-gap-16 {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.u-flex-1-0-auto {
    flex: 1 0 auto;
}

.u-flex-shrink-0 {
    flex-shrink: 0;
}

.u-flex-justify-between {
    justify-content: space-between;
}

.u-text-center {
    text-align: center;
}

.u-text-right {
    text-align: right;
}

.u-object-fit {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    object-fit: cover;
    min-width: 100%;
    min-height: 100%;
    max-width: 100%;
    max-height: 100%;
}

.u-text-uppercase {
    text-transform: uppercase;
}

$u-4: 4px;
$u-8: 8px;
$u-12: 12px;
$u-16: 16px;
$u-24: 24px;
$u-32: 32px;
$u-44: 44px;
$u-64: 64px;

// Margin top helpers
.u-mt-auto {
    margin-top: auto;
}

.u-mt-4 {
    margin-top: $u-4;
}

.u-mt-8 {
    margin-top: $u-8;
}

.u-mt-12 {
    margin-top: $u-12;
}

.u-mt-16 {
    margin-top: $u-16;
}

.u-mt-24 {
    margin-top: $u-24;
}

.u-mt-32 {
    margin-top: $u-32;
}

.u-mt-44 {
    margin-top: $u-44;
}

.u-mt-64 {
    margin-top: $u-64;
}

// Margin bottom helpers
.u-mb-auto {
    margin-bottom: auto;
}

.u-mb-4 {
    margin-bottom: $u-4;
}

.u-mb-8 {
    margin-bottom: $u-8;
}

.u-mb-12 {
    margin-bottom: $u-12;
}

.u-mb-16 {
    margin-bottom: $u-16;
}

.u-mb-24 {
    margin-bottom: $u-24;
}

.u-mb-32 {
    margin-bottom: $u-32;
}

.u-mb-44 {
    margin-bottom: $u-44;
}

.u-mb-64 {
    margin-bottom: $u-64;
}

// Margin right helpers
.u-mr-auto {
    margin-right: auto;
}

.u-mr-4 {
    margin-right: $u-4;
}

.u-mr-8 {
    margin-right: $u-8;
}

.u-mr-12 {
    margin-right: $u-12;
}

.u-mr-16 {
    margin-right: $u-16;
}

.u-mr-24 {
    margin-right: $u-24;
}

.u-mr-32 {
    margin-right: $u-32;
}

.u-mr-44 {
    margin-right: $u-44;
}

.u-mr-64 {
    margin-right: $u-64;
}

// Margin left helpers
.u-ml-auto {
    margin-left: auto;
}

.u-ml-4 {
    margin-left: $u-4;
}

.u-ml-8 {
    margin-left: $u-8;
}

.u-ml-12 {
    margin-left: $u-12;
}

.u-ml-16 {
    margin-left: $u-16;
}

.u-ml-24 {
    margin-left: $u-24;
}

.u-ml-32 {
    margin-left: $u-32;
}

.u-ml-44 {
    margin-left: $u-44;
}

.u-ml-64 {
    margin-left: $u-64;
}

.u-minus-footer-spacing {
    margin-bottom: -$new-footer-margin-top;
}

.u-fade-up {
    opacity: 0;
    transform: translateY(20%);
    transition: all $transition-slow ease-out;

    .in-view & {
        transform: translateY(0%);
        opacity: 1;
    }
}

.u-fade-up-children {
    @include sequence-children(fade-up);
}

.u-scale-up-children {
    @include sequence-children(scale-up);
}

.u-scale-up {
    @include scale-up;
}

.u-fade-up {
    @include fade-up;
}

.u-fade {
    @include fade;
}

.u-text-clickable {
    cursor: pointer;
    text-decoration: none;

    @include on-event {
        text-decoration: underline;
        color: $green;
    }
}

.u-hide-tablet {
    @include tablet {
        display: none;
    }
}
