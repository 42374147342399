.c-contact-block {
  @include tablet-landscape {
    flex-direction: row;
  }
  display: flex;
  flex-direction: column;

  gap: 1.25rem;
  align-items: center;

  &__content {
    flex: 1;
    gap: 1.25rem;
    display: flex;
    flex-direction: column;
  }

  &__card {
    flex: 1;
  }
}
