@use 'sass:math';

.image-container {
  position: relative;
  aspect-ratio: 16/9;

  &--4x3 {
    aspect-ratio: 4/3;
  }

  &--1x1 {
    aspect-ratio: 1/1;
  }

  &--pointer {
    @include on-event {
      cursor: pointer;
    }
  }

  & > img,
  & > iframe {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  &--intrinsic {
    width: 100%;
    aspect-ratio: auto;

    img,
    iframe {
      position: relative;
    }
  }

  &--contain > img {
    object-fit: contain;
  }

  &__icon-play {
    position: absolute;
    width: 68px;
    height: 68px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto auto;
    text-align: center;
    z-index: 1;
    pointer-events: none;
    background-image: icon('video.svg');
    background-size: cover;

    &--small {
      width: 24px;
      height: 24px;

      @include tablet {
        width: 34px;
        height: 34px;
      }
    }
  }

  &__overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-content: center;
    height: 100%;
  }

  &__number {
    align-self: center;
    color: $grey-light;
  }
}

.logo-container {
  &__logo {
    display: none;

    @include tablet-landscape {
      display: block;
    }
  }
  &__caption {
    @extend .is-caption;
    color: $green;
    font-weight: 700;
    line-height: 1.3333;

    @include tablet-landscape {
      display: none;
    }
  }
}

.image-block {
  margin: 0 auto;

  @include tablet-landscape {
    &--center {
      @include flex-percentage(8, 6);
    }

    &--center#{&}--container-fullWidth {
      @include flex-percentage(12, 8);
    }
  }
}

.text-image {
  display: flex;
  flex-direction: column;

  @include tablet-landscape {
    flex-direction: row;
    justify-content: space-between;
  }

  &__visual {
    width: 100%;

    @include tablet-landscape {
      @include flex-percentage(2, 1);
    }
  }

  &__content {
    padding-top: $padding-size;

    @include tablet-landscape {
      padding-top: 0px;
      @include flex-percentage(2, 1);
    }
  }

  @include tablet-landscape {
    &--imageRight &__visual,
    &--imageRightSmall &__visual {
      order: 1;
    }

    &--imageRightSmall &__visual,
    &--imageLeftSmall &__visual {
      @include flex-percentage(8, 3);
    }

    &--imageRightSmall &__content,
    &--imageLeftSmall &__content {
      @include flex-percentage(8, 5);
    }

    &--imageRightSmall#{&}--container-fullWidth &__visual,
    &--imageLeftSmall#{&}--container-fullWidth &__visual {
      @include flex-percentage(12, 4);
    }

    &--imageRightSmall#{&}--container-fullWidth &__content,
    &--imageLeftSmall#{&}--container-fullWidth &__content {
      @include flex-percentage(12, 8);
    }
  }
}

.double-image {
  display: flex;
  flex-direction: column;

  @include tablet-landscape {
    flex-direction: row;
    justify-content: space-between;
  }

  &__figure {
    width: 100%;

    &--1 {
      margin-bottom: $padding-size;
    }

    @include tablet-landscape {
      @include flex-percentage(2, 1);

      &--1 {
        margin-bottom: 0;
      }
    }
  }
}

.image-modal {
  &__click {
    opacity: 0;
    transform: scale(0.9) translateY(20%);
    transition: transform $transition-slower ease-out,
      opacity $transition-slow ease-out;

    .loaded & {
      opacity: 1;
      transform: scale(1) translateY(0);
    }
  }

  &__modal {
    visibility: hidden;
    z-index: -1;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: flex-end;

    &--open {
      visibility: visible;
      z-index: calc($z-index-navigation + 1);
    }
  }

  &__background {
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
  }

  &__close {
    cursor: pointer;
    z-index: 16;
    color: $grey;
    font-weight: 600;
    position: absolute;
    right: 0;
    top: 0;
    margin-right: $padding-size * 2;
    margin-top: $padding-size * 2;
    opacity: 0.5;
    background: transparent;
    border: none;
    font-size: 30px;
    &:hover {
      opacity: 1;
    }
  }

  &__modal-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 80%;
    height: 100vh;
    z-index: 2;
  }

  &__modal--open &__inner {
    animation: animate-modal $transition-slow forwards;
    animation-delay: 0.3s;

    @keyframes animate-modal {
      0% {
        transform: translateY(-200px);
        opacity: 0;
      }
      100% {
        transform: translateY(0);
        opacity: 1;
      }
    }
  }
}

.l-image-container {
  position: relative;

  &--has-pointer {
    @include on-event {
      cursor: pointer;
    }
  }

  & > img,
  & > picture > img,
  & > iframe {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
}
