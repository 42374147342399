// coppied from _header.scss in RFHE
@use "sass:math";

.section-header {
  @include until-tablet {
    padding-top: 0;
  }
}
.header {
  display: flex;
  flex-direction: column;

  &__visual {
    padding: 64px 0;
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @include until-tablet {
      padding-top: 52px;
      padding-bottom: 48px;
    }
    @include tablet-landscape {
      flex-direction: row;
      justify-content: space-between;
    }
    @include until-widescreen {
      padding: 32px 0;
    }
    @include widescreen {
      min-height: 664px;
    }
  }

  &__content {
    width: 100%;
    order: 1;
    align-self: center;

    @include tablet-landscape {
      order: 0;
      @include flex-percentage(12, 7);
    }
  }

  &__title {
    word-break: break-word;
    width: 100%;
    font-family: $title-font-stack;
    color: $purple;
    font-size: 64px;
    line-height: 64px;
    font-weight: bold;
    letter-spacing: -1.5px;
    margin-bottom: 32px;
    @include until-tablet {
      margin-bottom: 12px;
    }

    @include tablet-landscape {
      font-size: 120px;
      line-height: 120px;
      @include until-widescreen {
        font-size: 94px;
        line-height: 94px;
      }
    }
  }

  &__strong-text {
    margin-bottom: 36px;
    font-family: $title-font-stack;
    display: block;
    color: $black;
    font-family: $title-font-stack;
    font-weight: bold;
    letter-spacing: -0.12px;
    font-size: 32px;
    line-height: 38px;
    @include until-tablet {
      margin-bottom: 24px;
    }
    @include tablet-landscape {
      @include flex-percentage(7, 5);
      font-size: 38px;
      line-height: 48px;
      letter-spacing: -0.15px;
    }
  }

  &__button {
    .button {
      @include widescreen {
        @include flex-percentage(7, 3);
      }
    }
  }

  &__leafs {
    @include until-tablet-landscape {
      order: 2;
      width: 90%;
      margin-left: auto;
      margin-right: auto;
      padding-bottom: 10%;
      .header__leaf {
        top: 0;
      }
    }
    width: 100%;
    margin-bottom: 32px;
    padding-top: 88%;
    position: relative;

    @include tablet-landscape {
      position: absolute;
      z-index: -1;
      right: 0;
      top: 57%;
      transform: translate(0, -50%);
      padding-top: 50%;
      margin-bottom: 0;
      @include flex-percentage(12, 8);
      @include until-widescreen {
        padding-top: 45%;
        width: 60%;
        top: 50%;
      }
    }
  }

  &__leaf {
    content: "";
    bottom: 0;
    display: block;
    height: math.div(106, 107) * 113.8%;
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: contain;
    position: absolute;
    width: math.div(81, 122) * 113.8%;

    &--left {
      background: $purple-light;
      left: -5.1%;
      mask-image: image("leaf-left.svg");
    }
    &--right {
      background-color: $green-dark;
      left: -5.4%;
      mask-image: image("leaf-right.svg");
      transform: translateX(47%);
      background-size: cover;
    }
  }

  &__paragraph {
    font-family: $title-font-stack;
    font-size: 21px;
    line-height: 28px;
    @include tablet-landscape {
      padding-top: 48px;
      @include flex-percentage(12, 6);
    }
  }
  &__link {
    margin: 1em 0;
  }

  &__description {
    font-size: 1.5em;
    line-height: 1.5;

    @include tablet-landscape {
      font-size: 1.25em;
    }
  }
}

// coppied from _notFound.scss in RFHE
.notFound {
  .header {
    &__title {
      color: $danger;
    }
    &__content {
      @include tablet-landscape {
        min-height: 350px;
      }
    }
    &__content-mobile {
      order: 3;
      margin-top: $padding-size;
      @include tablet-landscape {
        display: none;
      }
    }
    @include until-tablet-landscape {
      &__content {
        .header__button,
        .header__content-title,
        .header__content-text {
          display: none;
        }
      }
    }

    &__leaf--left {
      background: $grey;
    }
    .wysiwyg {
      a {
        color: $orange;
      }
    }
    &__button {
      margin-top: 22px;
    }
    &__content-title,
    &__content-text {
      margin-bottom: 8px;
      @include tablet-landscape {
        @include flex-percentage(7, 5);
      }
    }
    &__content-text {
      font-weight: 300;
    }
  }
}
