$fag-question-icon-width: 12px;

.faq {
  padding-top: 30px;

  @include tablet-landscape {
    padding-top: 0;
  }

  &__category {
    &:not(:first-of-type) {
      margin-top: 64px;
    }
  }
  &__items {
    margin-top: 16px;
  }
  &__item {
    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }
  &__question {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    padding-left: calc(#{$fag-question-icon-width} + #{$padding-size});
    font-weight: 600;

    &::before {
      width: $fag-question-icon-width;
      height: 16px;
      content: '';
      background-size: cover;
      background-image: icon("chevron-left.svg");
      position: absolute;
      left: 0;
      transform: scaleX(-1);

      transition: all .3s ease;
    }
  }

  &__answer {
    display: none;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  &__question {
    &:active,
    &:link,
    &:focus {
      text-decoration: underline;
    }
  }

  &__question--active + &__answer {
    display: block;
  }

  &__question--active::before {
    transform: scaleX(-1) rotate(-90deg);
  }

  &__button {
    margin-top: 12px;
  }
}
