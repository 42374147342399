.c-video-text-block {
    @include full-grid-with-column-gap;
    row-gap: 40px;
    align-items: center;

    &__content {
        grid-column: main-start / main-end;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @include tablet-landscape {
            grid-column: 2 / span 5;
        }

        @include widescreen {
            grid-column: 3 / span 4;
        }
    }

    &__player {
        cursor: pointer;
        grid-column: main-start / main-end;

        @include tablet-landscape {
            grid-column: span 7 / main-end;
        }

        @include widescreen {
            grid-column: span 6 / -3;
        }
    }

    &--videoLeft &__content {
        @include tablet-landscape {
            grid-column: span 5 / main-end;
        }

        @include widescreen {
          grid-column: span 4 / -3;
        }
    }

    &--videoLeft &__player {
        position: relative;

        @include tablet-landscape {
            grid-column: main-start / span 7;
            grid-row: 1 / span 1;
        }

        @include widescreen {
            grid-column: 3 / span 6;
        }
    }
}

.c-play-icon {
    color: $white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    z-index: 3;
    height: 88px;

    @include on-event {
        color: $grey-very-light;
    }
}
