@use "sass:math";

.teaser-single-block {
  padding: 8em 0;

  &--white {
    background: $white;
    margin: 0 -3.75rem;
    padding: 8em 3.75em;
  }

  &__item-summary {
    @include basic-flex-item;
    padding-bottom: 20px;
    padding-top: 24px;
    min-height: 272px;

    @include tablet {
      padding-top: 34px;
      min-height: 264px;
    }

    @include tablet-landscape {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-top: 2 * $padding-size;
      padding-right: 3 * $padding-size;
    }
  }

  &__image-container {
    position: relative;
    padding-top: math.div(257, 380) * 100%;

    @include tablet {
      padding-top: math.div(420, 720) * 100%;
    }

    @include tablet-landscape {
      padding-top: math.div(333, 566) * 100%;
    }

    @include widescreen {
      padding-top: math.div(444, 760) * 100%;
    }
  }

  &__item {
    @include basic-flex-box($breakpoint: "tablet-landscape");
  }

  &__header-title,
  &__header-read-more {
    @include basic-flex-item;
    flex: none;
  }

  &__header {
    @include basic-flex-box;
    align-items: baseline;
    justify-content: space-between;
  }

  &__header-title {
    @include until-tablet {
      padding-bottom: $padding-size;
    }
  }

  &__header-read-more {
    @include until-tablet {
      padding-bottom: 1.5 * $padding-size;
    }
  }

  &__description {
    @include tablet {
      width: 85%;
    }

    @include desktop {
      width: 65%;
    }
  }

  &__items {
    position: relative;

    &::before {
      content: "";
      background: $white;
      bottom: 0;
      height: 411px;
      left: -$section-outer-padding--mobile;
      position: absolute;
      right: -$section-outer-padding--mobile;
      z-index: -1;
    }

    &--white {
      &::before {
        // TODO: grey rectangle is not visible on mobile
        @include tablet-landscape {
          background: $grey-very-light !important;
          z-index: 0 !important;
        }
      }

      .teaser-single-block__item-summary {
        z-index: 1;
      }
    }

    @include mobile {
      &::before {
        height: 460px;
      }
    }

    @include tablet {
      top: 40%;

      &::before {
        height: 350px;
        left: -$section-outer-padding;
        right: -$section-outer-padding;
      }
    }

    @include tablet-landscape {
      padding-top: 2em;
      padding-bottom: 2em;

      &::before {
        bottom: 0;
        height: auto;
        left: auto;
        right: 0;
        top: 0;
        width: math.div(8.5, 12) * 100%;
        z-index: -1;
      }
    }
  }

  &__item-image {
    padding-left: 0.75rem;
    padding-right: 0.75rem;

    @include tablet-landscape {
      flex: none;
      width: math.div(7, 12) * 100%;
    }
  }

  &__item-title {
    padding-bottom: 12px;
    padding-top: 12px;

    @include tablet-landscape {
      padding-bottom: 10px;
    }

    @include tablet {
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }

  &__item-cta {
    padding-top: 12px;

    @include tablet {
      padding-top: 44px;
    }

    @include tablet-landscape {
      padding-top: 32px;
    }
  }
}
