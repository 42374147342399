.cb-banner-block {
  @include standard-grid;

  padding: 20px $section-outer-padding--mobile;
  margin-left: -$section-outer-padding--mobile;
  margin-right: -$section-outer-padding--mobile;
  font-size: 1.25em;
  box-shadow: 1px 1px 20px -5px rgba(0,0,0,0.29);

  @include tablet {
    padding: 0;
    margin-left: 0px;
    margin-right: 0px;
  }

  &--light-theme {
    color: $purple;
    background-color: $white;
  }

  &--dark-theme {
    color: $white;
    background-color: $green-dark;
  }

  &--light-theme &__visual {
    grid-row: 1;
    padding-bottom: 20px;

    @include tablet {
      padding-bottom: 0;
    }
  }

  &--dark-theme &__visual {
    padding-top: 20px;

    @include tablet {
      padding-top: 0;
    }
  }

  &__content {
    position: relative;
    grid-column: 1 / -1;
    display: flex;
    flex-direction: column;
    gap: 8px;

    @include tablet {
      grid-column: 1 / span 6;
      padding-top: 40px;
      padding-bottom: 40px;
      padding-left: 26px;
    }
  }

  &__visual {
    position: relative;
    overflow: hidden;
    grid-column: 1 / -1;

    @include tablet {
      grid-column: span 6 / -1;
    }
  }

  &__image-container {
    position: relative;
    height: 180px;

    @include tablet {
      height: 100%;

      &::before {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        content: "";
        mask-image: svg("articleHeader/article_header_shape.svg");
        z-index: 2;
        height: 100%;
        right: 0;
        mask-repeat: no-repeat;
        mask-position: left;
        mask-size: contain;
        scale: 1.005;
        background-color: #fff;
      }
    }
  }

  &__visual &__cta {
    @include tablet {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);

      display: flex;
      flex-direction: column;
      justify-content: center;
      width: auto;
      max-width: 100%;
      height: 100%;
      z-index: 2;
    }
  }

}
