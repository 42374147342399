/*
Typography

Typographic elements

Style guide: 2-base.typography
*/

body {
    background-color: $grey-very-light;
    color: $text-color;
    font-family: $text-font-stack;
    font-size: $font-size-base;
    font-weight: 300;
    line-height: 1.5;
    word-break: break-word;
}

/*
Headings

Markup:
<h1>h1 Title Halyard Display Bold 50/56</h1>
<h2>h2 Title Halyard Display Bold 38/48 </h2>
<h3>h3 Title Halyard Display Bold 28/36 </h3>
<h4>h4 Title Halyard Display Bold 21/28 </h4>
<h4 class="is-text">h4 Title Halyard Display Book 21/28</h4>
<h5>h5 link text HALYARD MICRO bold 12/20</h5>
<h5 class="is-text">h5 link text HALYARD MICRO book 12/20</h5>
<h6>h6 caption LINK HALYARD MICRO Bold 9/20</h6>
<h6 class="is-text">h6 caption LINK HALYARD MICRO book 9/20 </h6>

Style guide: 2-base.typography.headings
 */

h1,
.t-heading-1,
h2,
.t-heading-2,
h3,
.t-heading-3,
h4,
.t-heading-4,
h5,
.t-heading-5,
h6,
.t-heading-6,
.title {
    @include heading-modifiers;

    font-family: $title-font-stack;
    font-weight: 700;

    &.is-text {
        font-weight: 300;
        letter-spacing: 0;
    }
}

h1,
.t-heading-1 {
    font-size: calcEm(42px);
    letter-spacing: calcEm(-0.25px, 50px);
    line-height: 1.12;

    @include tablet {
        font-size: calcEm(50px);
    }
}

h2,
.t-heading-2 {
    font-size: calcEm(35px);
    letter-spacing: calcEm(-0.15px, 50px);
    line-height: 1.2631;

    @include tablet {
        font-size: calcEm(38px);
    }
}

h3,
.t-heading-3 {
    font-size: calcEm(29px);
    letter-spacing: calcEm(-0.11px, 50px);
    line-height: 1.2631;

    @include tablet {
        font-size: calcEm(28px);
    }
}

h4,
.t-heading-4 {
    font-size: calcEm(24px);
    letter-spacing: 0;
    line-height: 1.3333;

    @include tablet {
        font-size: calcEm(21px);
    }
}

h5,
.t-heading-5 {
    font-family: $micro-font-stack;
    font-size: calcEm(13px);
    letter-spacing: calcEm(-0.1px, 50px);
    line-height: 1.6667;
    text-transform: uppercase;

    @include tablet {
        font-size: calcEm(12px);
    }

    .is-thin {
        font-family: $micro-font-stack;
        font-weight: 300;
    }
}

h6,
.t-heading-6,
.is-caption {
    font-family: $micro-font-stack;
    font-size: calcEm(10px);
    letter-spacing: calcEm(0.1px, 50px);
    line-height: 2.2222;
    text-transform: uppercase;

    @include tablet {
        font-size: calcEm(9px);
    }
}

.is-caption {
    font-weight: 300;
}

.title {
    @include heading-modifiers;
}

.heading-1 {
    @extend h1;
    @include heading-modifiers;
}

.heading-2 {
    @extend h2;
    @include heading-modifiers;
}

.heading-3 {
    @include heading-modifiers;
}

.heading-4 {
    @extend h4;
    @include heading-modifiers;
}

.heading-5 {
    @include heading-modifiers;
}

.heading-6 {
    @extend h6;
    @include heading-modifiers;
}

/*
Text

Markup:
<strong>Body Subheader Halyard Text Bold 16/24</strong>
<p>
  Body Copy Halyard Text Book 16/24<br />
  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris vulputate porta quam ornare dictum. Donec auctor
  ligula eget iaculis pulvinar. Donec placerat id erat at convallis. Sed quis sollicitudin mi. Ut sit amet
  facilisis lacus. Praesent sollicitudin risus in ultricies lobortis.
</p>

Style guide: 2-base.typography.text
 */

strong {
    font-weight: 700;
}

/*
Links

Basic styles for links

Markup:
<a href="#">Body Link Halyard Text Book 16/24</a>

Style guide: 2-base.typography.links
 */

a {
    color: $text-color;
    text-decoration: underline;

    @include on-event {
        color: $text-color;
        text-decoration: none;
    }
}

.wysiwyg {
    h1,
    h2,
    h3,
    h4,
    p:not(:last-child) {
        margin-bottom: 10px;
    }

    ul {
        margin-top: $padding-size;
        list-style-type: disc;

        li {
            margin-left: $padding-size * 2;

            &:not(:last-child) {
                margin-bottom: $padding-size;
            }
        }
    }

    table {
        @extend .table__table;
    }

    thead {
        @extend .table__header;
    }

    tr {
        @extend .table__row;
    }

    th {
        @extend .table__header;
    }

    tbody {
        tr {
            @extend .table__row--body;
        }
    }

    td {
        @extend .table__data;
    }

    a {
        @extend .link;
        @extend .link--orange;
        @extend .link--decorated;
    }
}

.paragraph {
    @include typography-modifiers;

    &--grey-medium {
        color: $grey-medium;
    }

    &--bold {
        font-weight: 700;
    }
}

.highlight {
    color: currentColor;
    font-weight: 600;

    &__thin {
        color: currentColor;
        font-weight: 300 !important;
    }
}

.heading-6 {
    @extend h6;
    color: $grey-dark;
    font-weight: 300;
}

.link {
    cursor: pointer;
    text-decoration: none;
    &:hover,
    &:hover > * {
        color: #0cc988;
    }

    &--hover-black:hover,
    &--hover-black:hover > * {
        color: $text-color;
    }

    &--hover-underline:hover,
    &--hover-underline:hover > * {
        text-decoration: underline;
    }

    &--white {
        color: $white;
    }

    &--black {
        color: $black;
    }

    &--decorated {
        text-decoration: underline !important;
    }

    &--orange {
        color: $orange;
    }

    &--bold {
        font-weight: 700;
    }

    &--big {
        font-size: 18px;
    }
}

.link-arrow {
    @include has-arrow-icon-left;

    &--bold {
        font-weight: 700;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    &--orange {
        color: $orange;

        @include on-event {
            color: $green;
        }
    }

    &--has-shadow {
        text-shadow: 1px 1px rgba($black, 0.3);
    }
}

.label {
    font-family: $micro-font-stack;
    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase;
}

.caption {
    @include image-caption;
}

.anchor {
    display: block;
    position: relative;
    top: -120px;
    visibility: hidden;
}

.t-link-arrow {
    @include has-arrow-icon-left;
    cursor: pointer;
    text-decoration: none;

    &:hover {
        color: $green;
    }

    &--black {
        &::before,
        &::after {
            background-color: $black;
        }
    }

    &--black-hover {
        &:hover {
            color: $black;

            &::before,
            &::after {
                background-color: $black;
            }
        }
    }

    &--white {
        color: $white;

        &::before,
        &::after {
            background-color: $white;
        }
    }

    &--bold {
        font-weight: 600;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    &--mirrored {
        transform: rotate(180deg);

        &::after,
        &::before {
            transform: translateY(2px) !important;
        }
    }

    &--big {
        font-size: 18px;

        &::before,
        &::after {
            mask-position: 0px 9px;
        }
    }

    &--small {
        font-size: 14px;

        &::before,
        &::after {
            mask-position: 0px 7px;
        }
    }
}

.t-quote {
    font-style: italic;
    font-family: $title-font-stack;
    font-size: 21px;
    font-weight: 300;
}

.t-label {
    font-weight: 300;
    font-family: $title-font-stack;
    font-size: 12px;

    &--big {
        font-size: 14px;

        &::before {
            mask-position: 0px 10px;
        }
    }
}

.t-card-title {
    font-size: 14px;
    font-weight: 400;

    @include limit-lines(3);

    @include tablet-landscape {
        font-size: 24px;
        font-weight: 700;
    }

    @include heading-modifiers;
}

.t-text {
    &--title {
        font-family: $title-font-stack;
    }
}

.t-note {
    display: flex;
    font-size: 12px;
    font-weight: 300s;
    display: flex;
    align-items: center;

    &::before {
        background-color: $text-color;
        margin-right: 6px;
        content: "";
        height: 14px;
        width: 14px;

        mask-image: icon("calendar.svg");
        display: inline-block;
        mask-size: contain;
        mask-repeat: no-repeat;

        @include tablet-landscape {
            height: 16px;
            width: 16px;
        }
    }

    @include tablet-landscape {
        font-size: 14px;
    }

    &--white-mobile::before {
        @include until-tablet-landscape {
            background-color: $white;
        }
    }
}

// 2023 classes

.t-tag {
    color: $purple;
    font-size: 14px;
    font-weight: 400;
}

.t-h {
    font-family: $title-font-stack;
    font-weight: 400;
    text-transform: none;

    @include typography-modifiers;
}

.t-h1 {
    @extend .t-h;
    @include typography-modifiers;
    font-size: 40px;
    font-weight: 600;

    @include tablet {
        font-size: 48px;
    }

    &--big {
        @include tablet {
            font-size: 72px;
        }
    }

    &--small {
        font-size: 32px;

        @include tablet {
            font-size: 36px;
        }
    }
}

.t-h2 {
    @extend .t-h;
    @include typography-modifiers;
    font-size: 32px;

    @include tablet {
        font-size: 36px;
    }
}

.t-h3 {
    @extend .t-h;
    @include typography-modifiers;
    font-size: 26px;

    @include tablet {
        font-size: 28px;
    }
}

.t-h4 {
    @extend .t-h;
    @include typography-modifiers;
    font-size: 22px;

    @include tablet {
        font-size: 24px;
    }
}

.t-h5 {
    @extend .t-h;
    @include typography-modifiers;
    font-size: 18px;

    @include tablet {
        font-size: 21px;
    }
}

.t-h6 {
    @extend .t-h;
    @include typography-modifiers;
    font-size: 16px;

    @include tablet {
        font-size: 18px;
    }
}

.t-subtitle {
    font-size: 14px;
    font-weight: 400px;
}

.t-paragraph {
    font-size: 18px;

    @include typography-modifiers;

    &--big {
        font-size: 24px;
    }

    &--is-small {
        font-size: 16px;
    }

    &--is-smaller {
        font-size: 14px;
    }

    &--tiny {
        font-size: 12px;
    }

    &--has-font-weight-600 {
        font-weight: 600;
    }
}

.t-text-link {
    font-family: $title-font-stack;
    font-weight: 400;
    font-size: 18px;
    cursor: pointer;

    @include typography-modifiers;
}

.t-icon {
    display: flex;
    height: 19px;
    width: auto;

    svg {
        height: 100% !important;
        width: auto !important;
    }

    &--small {
        height: 16px;
    }

    &--green-main {
        color: $green-main;
    }
}

.t-redactor {
    font-size: 18px;
    font-weight: 300;
    line-height: 1.5;
    word-break: break-word;

    @include typography-modifiers;

    &--is-small {
        font-size: 16px;
    }

    &--is-smaller {
        font-size: 14px;
    }

    strong,
    b {
        font-weight: 700;
    }

    ul,
    ol,
    p {
        margin-bottom: 16px;
        margin-top: 16px;
    }

    ul {
        list-style: none;

        li {
            position: relative;
            padding-left: 20px;
            &::before {
                content: "";
                position: absolute;
                width: 3px;
                height: 3px;
                top: calc(27px / 2);
                left: 5px;
                border-radius: 0.1px;
                background-color: black;
            }
        }
    }

    ol {
        padding-left: 14px;
        list-style: decimal;
    }

    a {
        display: inline;
        font-family: $text-font-stack;
        font-weight: 400;
        color: $black;

        @include on-event {
            color: $green;
        }
    }

    img {
        width: 100% !important;
        height: auto !important;
    }

    h1 {
        @extend .t-h1;
    }

    h2 {
        @extend .t-h2;
    }

    h3 {
        @extend .t-h3;
    }

    h4 {
        @extend .t-h4;
    }

    h5 {
        @extend .t-h5;
    }

    table {
        display: grid;
        overflow-x: auto;
        max-width: fit-content;
        border: 0.5px solid $grey-very-light;
        border-radius: 6px;
        border-spacing: 0;
        @include scrollbar-styling;

        tbody {
            min-width: max-content;
            tr {
                &:first-child {
                    td {
                        &:first-child {
                            border-top-left-radius: 5.4px;
                        }
                        &:last-child {
                            border-top-right-radius: 5.4px;
                        }
                    }
                }
                &:last-child {
                    td {
                        &:first-child {
                            border-bottom-left-radius: 5.4px;
                        }
                        &:last-child {
                            border-bottom-right-radius: 5.4px;
                        }
                    }
                }
            }
            td {
                border: 0.5px solid $grey-very-light;
                vertical-align: top;
                padding: 8px 24px;
                font-size: 18px;
                line-height: calc(22px / 14px);
                min-width: 140px;
            }
        }
    }
}

.t-back-button {
    a {
        margin-left: 4px;
        font-size: 12px;
        text-decoration: none;

        @include on-event {
            text-decoration: underline;
        }
    }

    &--white {
        color: $white;

        a {
            color: $white;
        }
    }
}


#body--events {
    color: $text-color;
    font-family: $text-font-stack;
    font-size: $font-size-base;
    font-weight: 200;
    line-height: 1.5;
    word-break: break-word;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .title {
    font-family: $title-font-stack;
    font-weight: 700;

    &.is-text {
        font-weight: 300;
        letter-spacing: 0;
    }
    }

    h3 {
    font-size: calcEm(29px);
    letter-spacing: calcEm(-0.11px, 50px);
    line-height: 1.2631;

    @include tablet {
        font-size: calcEm(28px);
    }
    }

    h6,
    .is-caption {
    font-family: $micro-font-stack;
    font-size: calcEm(10px);
    letter-spacing: calcEm(0.1px, 50px);
    line-height: 2.2222;
    text-transform: uppercase;

    @include tablet {
        font-size: calcEm(9px);
    }
    }

    .is-caption {
    font-weight: 300;
    }

    @mixin heading-modifiers {
    &--danger {
        color: $danger;
    }

    &--primary {
        color: $color-primary;
    }

    &--secondary {
        color: $color-secondary;
    }

    &--white {
        color: $white;
    }

    &--dark {
        color: $black;
    }

    &--grey {
        color: $grey;
    }

    &--forest {
        color: $green-forest;
    }

    &--thin {
        font-weight: 300;
    }

    &--bold {
        font-weight: 700;
    }

    &--capitalize {
        text-transform: capitalize;
    }

    &--uppercase {
        text-transform: uppercase;
    }

    &--margin-reset {
        margin: 0;
    }

    &--small-mobile {
        @include until-tablet-landscape {
        font-size: calcEm(29px);
        }
    }

    &--centered-desktop {
        @include desktop {
        width: 100%;

        text-align: center;
        }
    }
    }

    .t-heading-1 {
    font-size: 40px;
    line-height: 40px;
    font-weight: 600;

    @include tablet {
        font-size: 80px;
        line-height: 72px;
    }

    @include heading-modifiers;
    }

    .t-heading-2 {
    font-size: 32px;
    line-height: 32px;
    margin-bottom: 8px;

    @include tablet {
        font-size: 48px;
        line-height: 48px;
    }

    @include heading-modifiers;
    }

    .t-heading-3 {
    font-size: 24px;
    line-height: 24px;
    margin-bottom: 8px;

    @include tablet {
        font-size: 38px;
        line-height: 38px;
    }

    @include heading-modifiers;
    }

    .t-heading-4 {
    font-size: 22px;
    line-height: 26px;
    font-weight: 700;
    margin-bottom: 8px;

    @include tablet {
        font-size: 24px;
        line-height: 30px;
    }

    @include heading-modifiers;
    }

    .t-heading-5 {
    font-family: $micro-font-stack;
    font-size: 12px;
    text-transform: uppercase;
    margin-bottom: 4px;

    @include heading-modifiers;
    }

    .t-heading-6 {
    @extend h6;
    @include heading-modifiers;
    }

    /*
    Text

    Markup:
    <strong>Body Subheader Halyard Text Bold 16/24</strong>
    <p>wysiwyg
    Body Copy Halyard Text Book 16/24<br />
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris vulputate porta quam ornare dictum. Donec auctor
    ligula eget iaculis pulvinar. Donec placerat id erat at convallis. Sed quis sollicitudin mi. Ut sit amet
    facilisis lacus. Praesent sollicitudin risus in ultricies lobortis.
    </p>

    Style guide: 2-base.typography.text
    */

    strong {
    font-weight: 700;
    }

    /*
    Links

    Basic styles for links

    Markup:
    <a href="#">Body Link Halyard Text Book 16/24</a>

    Style guide: 2-base.typography.links
    */

    a {
    // color: $text-color;
    // text-decoration: underline;

    @include on-event {
        color: $text-color;
        text-decoration: none;
    }
        &.button {
            &:hover {
                color: white !important;
            }
        }
    }

    .t-wysiwyg {
    h1,
    h2,
    h3,
    h4,
    p {
        &:not(:last-child) {
        margin-bottom: 10px;
        }
    }

    ul {
        margin-top: $padding-size;
        list-style-type: disc;

        li {
        margin-left: $padding-size * 2;

        &:not(:last-child) {
            margin-bottom: $padding-size;
        }
        }
    }

    table {
        @extend .table__table;
    }

    thead {
        @extend .table__header;
    }

    tr {
        @extend .table__row;
    }

    th {
        @extend .table__header;
    }

    tbody {
        tr {
        @extend .table__row--body;
        }
    }

    td {
        @extend .table__data;
    }

    a {
        @extend .t-link;
        @extend .t-link--orange;
    }
    }

    .t-paragraph {
    &--white {
        color: $white;
    }

    &--bold {
        font-weight: 700;
    }
    }

    .highlight {
    color: currentColor;
    font-weight: 600;

    &__thin {
        color: currentColor;
        font-weight: 300 !important;
    }
    }

    .heading-6 {
    @extend h6;
    color: $grey-dark;
    font-weight: 300;
    }

    .t-link {
    font-size: 16px;
    cursor: pointer;
    text-decoration: none;

    &:hover,
    &:hover > * {
        color: #0cc988;
    }

    &--hover-black:hover,
    &--hover-black:hover > * {
        color: $text-color;
    }

    &--hover-underline:hover,
    &--hover-underline:hover > * {
        text-decoration: underline;
    }

    &--white {
        color: $white;
    }

    &--micro-style {
        color: $orange;
        font-weight: 700;
        text-transform: uppercase;
        font-family: $micro-font-stack;
        text-decoration: underline;
    }

    &--decorated {
        text-decoration: underline;
    }

    &--orange {
        color: $orange;
    }

    &--bold {
        font-weight: 700;
    }

    &--big {
        font-size: 18px;
    }
    }

    .t-link-arrow {
    @include has-arrow-icon-left;

    &:hover {
        color: $green;
    }

    &--bold {
        font-weight: 700;
        text-decoration: none;

        &:hover {
        text-decoration: underline;
        }
    }

    &--mirrored {
        transform: rotate(180deg);

        &::after,
        &::before {
        transform: translateY(2px) !important;
        }
    }
    }

    .t-label {
    font-family: $micro-font-stack;
    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase;
    }

    .t-caption {
    margin-top: 8px;
    font-family: $micro-font-stack;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 0.1px;
    line-height: 20px;
    }

    .anchor {
    display: block;
    position: relative;
    top: -120px;
    visibility: hidden;
    }

    .t-small-text {
    font-size: 14px;
    text-decoration: none;

    &--link {
        &:hover {
        color: $color-tertiary;
        }
    }
    }

    .t-link-block {
    font-size: 16px;

    a {
        @extend .t-link;
        @extend .t-link--orange;
    }
    }

    .t-highlight {
    strong,
    a {
        background-repeat: no-repeat;
        background-image: linear-gradient(transparent calc(100% - 0.5rem), $white 0.5rem);
        background-size: 0% 92%;
    }

    &--dark {
        strong,
        a {
        background-image: linear-gradient(transparent calc(100% - 0.5rem), $green-light 0.5rem);
        }
    }
    }

    .t-quote {
    font-style: italic;
    font-family: $title-font-stack;
    font-size: 21px;
    line-height: 30px;
    font-weight: 300;
    }

    .button,
input[type="button"] {
  font-size: 18px;
  font-weight: 700;
  background: $orange;
  border: 1px solid $orange;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $white;
  text-align: center;
  text-decoration: none;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 4px;
  padding-bottom: 6px;
  text-transform: uppercase;

  &.button--green {
    background: $green;
    border: 1px solid $green;
  }

  &:hover {
    cursor: pointer;
  }

  &.has-transition {
    transition: background-color $transition-fast ease-in-out,
      border-color $transition-fast ease-in-out;
  }

  @include on-event {
    background: $green;
    border-color: $green;
    color: $white;
  }

  &.is-transparent {
    background: transparent;
    color: $orange;

    &.has-transition {
      transition: color $transition-fast ease-in-out, border-color $transition-fast ease-in-out;
    }

    @include on-event {
      background: transparent;
      color: $green;
    }
  }

  &.less-padding {
    padding: 0.5em;
  }

  &.is-disabled {
    background: $grey;
    border-color: $grey;
    cursor: default;
    pointer-events: none;

    &.is-transparent {
      background: transparent;
      color: $grey;
    }
  }

  &.is-full-width {
    display: block;
    padding-left: 0;
    padding-right: 0;
  }

  &.is-full-width-mobile {
    @include until-desktop {
      display: block;
      padding-left: 0;
      padding-right: 0;
    }
  }

  &.w-100\% {
    width: 100%;
  }
}
}
