
            @import "@/styles/abstracts/all.scss";
          

.c-table {
    overflow-x: scroll;

    &__table {
        border-collapse: separate !important;
        width: 100%;
        border-collapse: collapse;
        border-spacing: 0;
    }

    &__cell {
        padding: 0.5rem 1rem;
        text-align: left;
        white-space: nowrap;

        &:first-child {
            background-color: $grey-light;
        }

        &:not(:first-child) {
            border-bottom: 1px solid $grey-light;
            border-right: 1px solid $grey-light;
        }
    }

    &__body &__row:first-child &__cell:first-child {
        border-top-left-radius: 6px;
    }

    &__body &__row:last-child &__cell:first-child {
        border-bottom-left-radius: 6px;
    }

    &__body &__row:first-child &__cell:last-child {
        border-top-right-radius: 6px;
    }

    &__body &__row:last-child &__cell:last-child {
        border-bottom-right-radius: 6px;
    }

    &__body &__row:first-child &__cell:not(:first-child) {
        border-top: 1px solid $grey-light;
    }

    &__header {
        font-weight: 600;
        font-size: 14px;
        white-space: nowrap;
        padding: 0.5rem 1rem;

        &:first-child {
            color: transparent;
        }
    }
}
