
            @import "@/styles/abstracts/all.scss";
          

.c-filter-button {
    @include basic-reset;

    display: flex;
    border: 1px solid $black;
    border-radius: $border-radius-standard;
    cursor: pointer;
    font-size: 16px;
    overflow: hidden;
    width: max-content;

    @include button-hover;

    @include tablet-landscape {
        font-size: 18px;
    }

    &__detail {
        font-family: $title-font-stack;
        padding: 8px;
        background-color: $black;
        color: $white;

        @include tablet-landscape {
            padding: 14px;
        }
    }

    &__label {
        padding: 8px;
        color: $black;
        width: fit-content;
        flex-shrink: 0;

        @include tablet-landscape {
            padding: 14px;
        }
    }

    &--is-active {
        border-color: $green;
    }

    &--is-active &__detail {
        background-color: $green;
    }
}
