// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

/// Default body fontsize
$font-size-base: 16px;

/// grid spacing
$gutter: 24px;
$padding-size: 0.75rem; // for RFHE components.
$section-outer-padding--mobile: 1rem;
$section-outer-padding: 2 * $padding-size;
$section-outer-padding--widescreen: 5 * $padding-size;

/// container widths
$container-max-width--tablet: 720px;
$container-max-width--tablet-landscape: 984px;
$container-max-width--widescreen: 1320px;

/// Regular font family
/// @type List
$text-font-stack: "halyard-text", "Open Sans", "Helvetica Neue Light",
    "Helvetica Neue", "Helvetica", "Arial", sans-serif !default;
$title-font-stack: "halyard-display", "Open Sans", "Helvetica Neue Light",
    "Helvetica Neue", "Helvetica", "Arial", sans-serif !default;
$micro-font-stack: "halyard-micro", "Open Sans", "Helvetica Neue Light",
    "Helvetica Neue", "Helvetica", "Arial", sans-serif !default;

// Code (monospace) font family
// @type List
$code-font-stack: "Courier New", "DejaVu Sans Mono", "Bitstream Vera Sans Mono",
    "Monaco", monospace !default;

// Relative or absolute URL where all assets are served from
// @type String
// @example scss - When using a CDN
//   $base-url: 'http://cdn.example.com/assets/';
$base-url: "/web/assets/" !default;

// ======
// NAVBAR
// ======
$navbar-height: 92px;
$navigation-mobile-height: 85px;
$navigation-mobile-z-index: 899;
$navigation-mobile-button-height: 52px;
$border-standard: 1px solid $grey-light;
$border-medium: 1px solid $grey;

// =================
// TRANSITION SPEEDS
// =================

$transition-faster: 0.1s;
$transition-fast: 0.2s;
$transition-slow: 0.5s;
$transition-slower: 1s;

// ==============
// BULMA SETTINGS
// ==============
$column-gap: $gutter;

// ==============
// SHADOWS
// ==============
$shadow-sm: 0 1px 2px 0 rgba($black, 0.05);
$shadow-text: 1px 0px 0px black;

$overlay-background-color: rgba($black, 0.6);

$chevron-horizontal-mobile-height: 20px;
$chevron-horizontal-mobile-width: 16px;
$chevron-horizontal-mobile-width: 16px;
$chevron-horizontal-mobile-width: 12px;

// ==============
// CONTAINERS
// ==============
$video-player-max-width: 1280px;

// ==============
// STANDARDS
// ==============
$border-standard-2023: 1px solid $grey;
$border-radius-standard: 6px;
$input-border: 1px solid $black;
$input-height: 44px;

// 2023
$card-shadow: 0px 4px 50px 0px rgba($black, 0.1);
$navbar-index: $navigation-mobile-z-index;
$placeholder-color: rgba($black, 0.5);
$c-video-header-index: 1;
$move-in-animation-speed: 0.5s cubic-bezier(0.31, 0.64, 0.37, 1);

$padding-bottom-menu-level-one: 20px;
$spacing-menu-items-level-one: 12px;
$spacing-menu-items-level-two: 16px;
$height-top-blocks: 108px;
$navigation-top-height: 36px;
$navigation-main-height: 86px;
$navigation-height: $navigation-top-height + $navigation-main-height;
$z-index-navigation: $navigation-mobile-z-index;
$padding-top-secondary-block: 34px;
$animation-speed: 0.3s ease;

// Footer
$new-footer-margin-top: 100px;

// JOBS
$navigation-jobs-height: 90px;
