.iframe-block {
    @include tablet-landscape {
        display: flex;
        justify-content: space-between;
    }

    &__content {
        margin-bottom: $padding-size * 2;

        @include tablet-landscape {
            @include flex-percentage(12, 4);
            margin-bottom: 0px;
        }

        @include widescreen {
            @include flex-percentage(12, 6);
        }
    }

    &__text {
        margin-top: $padding-size;
    }

    &__secondary-text {
        margin-top: 8px;
        font-size: 13px;
    }

    &__iframe-container {
        display: flex;
        justify-content: center;

        iframe {
            width: 100%;
        }

        @include tablet-landscape {
            @include flex-percentage(12, 7);

            &--left {
                order: -1;
            }
        }

        @include widescreen {
            @include flex-percentage(12, 5);
        }

        iframe {
            max-width: 100%;
        }
    }
}

.double-iframes {
    @include tablet-landscape {
        display: flex;
        justify-content: space-between;
    }

    &__item {
        &:not(:last-child) {
            margin-bottom: 64px;
        }
        @include tablet-landscape {
            @include flex-percentage(12, 5);

            display: flex;
            flex-direction: column;
            justify-content: flex-start;

            &:not(:last-child) {
                margin-bottom: 0px;
            }
        }
    }

    &__content {
        margin-bottom: $padding-size * 2;
    }

    &__text {
        @include tablet-landscape {
            margin-top: auto;
            min-height: 72px;
        }
    }

    &__secondary-text {
        margin-top: $padding-size;
        font-size: 13px;
        margin-bottom: $padding-size;
    }

    &__iframe-container {
        display: flex;
        justify-content: center;

        iframe {
            width: 100%;
        }
    }
}

.c-questions {
    @include full-grid-with-column-gap;

    @include tablet-landscape {
        grid-template-rows: auto auto 1fr;
        align-items: start;
    }

    &__content {
        grid-column: main-start / main-end;
        height: fit-content;

        @include tablet-landscape {
            grid-column: 2 / span 5;
            grid-row: 1 / span 1;
        }

        @include widescreen {
            grid-column: 3 / span 4;
        }
    }

    &__questions {
        grid-column: main-start / main-end;

        @include tablet-landscape {
            grid-column: span 6 / -2;
            grid-row: 1 / span 3;
        }

        @include widescreen {
            grid-column: span 5 / -3;
        }
    }

    &__description {
        margin-top: 16px;
        margin-bottom: 24px;

        @include tablet-landscape {
            margin-bottom: 0px;
        }
    }

    &__button {
        grid-column: main-start / main-end;
        margin-top: 24px;

        @include tablet-landscape {
            margin-top: 0px;
            grid-column: 2 / span 5;
            grid-row: 2 / span 1;
            margin-top: 24px;
        }

        @include widescreen {
            grid-column: 3 / span 4;
        }
    }
}
