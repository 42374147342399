
            @import "@/styles/abstracts/all.scss";
          

.c-input-number {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;

    &__input {
        height: 48px;
        line-height: 48px;
        padding-left: 16px;
        padding-right: 16px;
        border: 1px solid $grey;
        border-radius: 6px;
    }
}
