.c-cards-overview {
  display: grid;
  column-gap: 24px;

  &--card-count-2 {
    @include tablet-landscape {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &--card-count-3 {
    @include tablet-landscape {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &__header {
    background-color: $green-200;
    padding: 24px;
    border-radius: 6px;
    margin-bottom: 8px;

    @include tablet-landscape {
      grid-row: 1 / span 1;
    }
  }

  &__links {
    border: $border-standard;
    border-radius: 6px;
    padding: 0 24px;
    margin-bottom: 32px;

    @include tablet-landscape {
      margin-bottom: 0px;
      grid-row: 2 / span 1;
    }
  }

  &__icon-container {
    margin-bottom: 16px;
  }

  &__icon {
    height: 40px;
    width: auto;
  }

  &__list-item {
    padding: 8px 0;
    & + & {
      border-top: $border-standard;
    }

    &:first-child {
      padding-top: 16px;
    }

    &:last-child {
      padding-bottom: 16px;
    }
  }
  &__link {
    text-decoration: none;
    font-size: 14px;
  }

  &__button {
    margin-top: 16px;
  }

  @include tablet-landscape {
    &--card-count-2 &__links--1 {
      grid-column: 1 / span 1;
    }

    &--card-count-2 &__links--2 {
      grid-column: 2 / span 1;
    }

    &--card-count-3 &__links--1 {
      grid-column: 1 / span 1;
    }

    &--card-count-3 &__links--2 {
      grid-column: 2 / span 1;
    }

    &--card-count-3 &__links--3 {
      grid-column: 3 / span 1;
    }
  }

  &--domain-green-main &__header {
    background-color: $green-200;
  }

  &--domain-purple-main &__header {
    background-color: $purple-300;
  }
}
