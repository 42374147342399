/*
Latest News Block

Markup:
<div class="latest-news-block" style="background: #DDD">
  <div class="latest-news-block__header">
    <div class="latest-news-block__header-title">
      <h2>Latest News Block</h2>
    </div>
    <div class="latest-news-block__header-read-more">
      <a href="#" class="text-button__arrow">Latest News Block</a>
    </div>
  </div>
  <div class="latest-news-block__main">
    <div class="latest-news-block__items">
      <div class="latest-news-block__item">
        <div class="latest-news-block__item__image">
          <figure class="image">
            <img class="object-fit" src="http://via.placeholder.com/1920x1920" />
          </figure>
        </div>
        <div class="latest-news-block__item__summary">
          <h6 class="is-text">00 month 0000</h6>
          <h3 class="latest-news-block__item-title">Item Title</h3>
          <p class="latest-news-block__item-description">Short Description</p>
          <div class="latest-news-block__item-cta">
            <a href="#" class="text-button__uppercase">Read more</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="latest-news-block__tab-list">
    <div class="latest-news-block__tab-container">
      <div class="latest-news-block__tab">
        <h6 class="is-text">00 month 0000</h6>
        <div class="latest-news-block__tab-title">
          Short Title
        </div>
      </div>
    </div>
    <div class="latest-news-block__tab-container">
      <div class="latest-news-block__tab">
        <h6 class="is-text">00 month 0000</h6>
        <div class="latest-news-block__tab-title">
          Short Title
        </div>
      </div>
    </div>
    <div class="latest-news-block__tab-container">
      <div class="latest-news-block__tab">
        <h6 class="is-text">00 month 0000</h6>
        <div class="latest-news-block__tab-title">
          Short Title
        </div>
      </div>
    </div>
    <div class="latest-news-block__tab-container">
      <div class="latest-news-block__tab">
        <h6 class="is-text">00 month 0000</h6>
        <div class="latest-news-block__tab-title">
          Short Title
        </div>
      </div>
    </div>
  </div>
</div>
<div class="latest-news-block__tab-list-mobile">
  <div class="is-active">
    1
  </div>
  <div>
    2
  </div>
  <div>
    3
  </div>
  <div>
    4
  </div>
</div>

Style guide: 4-components.latest-news
 */

@use "sass:math";

.latest-news-block {
  &__item-summary {
    @include basic-flex-item;
    padding-bottom: 20px;
    padding-top: 24px;
    min-height: 272px;

    @include tablet {
      padding-top: 34px;
      min-height: 264px;
    }

    @include tablet-landscape {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-top: 2 * $padding-size;
      padding-right: 3 * $padding-size;
    }
  }

  &__image-container {
    position: relative;
    padding-top: math.div(257, 380) * 100%;

    @include tablet {
      padding-top: math.div(420, 720) * 100%;
    }

    @include tablet-landscape {
      padding-top: math.div(333, 566) * 100%;
    }

    @include widescreen {
      padding-top: math.div(444, 760) * 100%;
    }
  }

  &__item {
    @include basic-flex-box($breakpoint: "tablet-landscape");
  }

  &__header-title,
  &__header-read-more {
    @include basic-flex-item;
    flex: none;
  }

  &__header {
    @include basic-flex-box;
    padding-bottom: 2em;
    align-items: baseline;
    justify-content: space-between;

    @include tablet {
      padding-bottom: 3.5em;
    }
    &--less-padding{
      padding-bottom: 1em;
      @include tablet {
        padding-bottom: 1.5em;
      }
    }
  }

  &__header-title {
    @include until-tablet {
      padding-bottom: $padding-size;
    }
  }

  &__header-read-more {
    @include until-tablet {
      padding-bottom: 1.5 * $padding-size;
    }
  }

  &__items {
    position: relative;

    &::before {
      content: "";
      background: $white;
      bottom: 0;
      height: 411px;
      left: -$section-outer-padding--mobile;
      position: absolute;
      right: -$section-outer-padding--mobile;
      z-index: -1;
    }

    @include mobile {
      &::before {
        height: 460px;
      }
    }

    @include tablet {
      top: 40%;

      &::before {
        height: 350px;
        left: -$section-outer-padding;
        right: -$section-outer-padding;
      }
    }

    @include tablet-landscape {
      padding-top: 2em;
      padding-bottom: 2em;

      &::before {
        bottom: 0;
        height: auto;
        left: auto;
        right: 0;
        top: 0;
        width: math.div(8.5, 12) * 100%;
        z-index: -1;
      }
    }
  }

  &__item-image {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    @include tablet-landscape {
      flex: none;
      width: math.div(7, 12) * 100%;
    }
  }

  &__item-title {
    padding-bottom: 12px;
    padding-top: 12px;

    @include tablet-landscape {
      padding-bottom: 10px;
    }

    @include tablet {
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }

  &__item-cta {
    padding-top: 12px;

    @include tablet {
      padding-top: 44px;
    }

    @include tablet-landscape {
      padding-top: 32px;
    }
  }

  &__tab-list {
    display: none;

    @include tablet {
      @include basic-flex-box;
      padding-top: 3em;
    }
  }

  &__tab-list-mobile {
    @extend h5;
    @include basic-flex-box(0, "none");
    font-weight: 300;
    justify-content: center;
    padding-top: 2em;
    padding-bottom: 2em;

    & > * {
      padding: 0 2.5em;

      &.is-active {
        font-weight: 700;
      }
    }

    @include tablet {
      display: none;
    }
  }

  &__tab-mobile {
    cursor: pointer;
    text-decoration: none;
  }

  &__tab-container {
    @include basic-flex-item;
    flex: none;
    width: 25%;
  }

  &__tab {
    color: $grey-dark;
    border-top: 1px solid currentColor;
    cursor: pointer;
    display: block;
    height: 120px;
    padding-top: 0.5em;
    text-decoration: none;

    &--active {
      font-weight: 700;
      color: $black;
    }

    &:hover,
    &:active,
    &:focus {
      color: $green;
    }
  }

  &__tab-title {
    color: currentColor;
  }
}
