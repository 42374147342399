/*
Header Slides

Markup:
<header class="header-section" style="background: #DDD">
  <div class="header-slides">
    <div class="header-slide">
      <div class="header-slide__section">
        <div class="header-slide__container">
          <div class="header-slide__content">
            <h1 class="header-slide__title">Page title, header title</h1>
            <div class="header-slide__cta">
              <a href="#" class="button">Read More</a>
            </div>
          </div>
        </div>
      </div>
      <div class="header-slide__leafs-wrapper">
        <div class="header-slide__leafs">
          <div class="rfh-leafs--diagonal">
            <div class="leaf leaf--left"></div>
            <div class="leaf leaf--right">
              <img
                class="object-fit"
                src="https://via.placeholder.com/1600x1200"
                alt="image title" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="header-slides__slide-indicators">
      <div class="header-slides__slide-indicator">
        1
      </div>
      <div class="header-slides__slide-indicator">
        2
      </div>
      <div class="header-slides__slide-indicator">
        3
      </div>
      <div class="header-slides__slide-indicator">
        4
      </div>
    </div>
  </div>
</header>

Style guide: 3-layout.2-headers.2-header-slides
 */

@use "sass:math";

.header-slides {
  height: 528px;
  overflow: hidden;
  width: 100%;

  @include mobile {
    height: 664px;
  }

  @include tablet {
    height: 848px;
  }

  @include tablet-landscape {
    height: 745px;
  }

  @include desktop {
    height: 940px;
  }

  @include widescreen {
    height: 940px;
  }
}

.header-slide {
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  top: 0;
}

.header-slide__section {
  @extend .section;
  padding-bottom: 0;
  padding-top: 0;
}

.header-slide__container {
  @extend .container;
  position: relative;
  z-index: 1;
}

.header-slide__content {
  padding-top: 24px;
  position: absolute;
  z-index: 5;

  @include tablet {
    padding-top: 48px;
    width: calc(8 / 12) * 100%;
  }

  @include tablet-landscape {
    width: 50%;
  }

  @include desktop {
    padding-top: 88px;
  }
}

.header-slide__title {
  min-height: 168px;

  @include tablet {
    min-height: 192px;
  }

  @include widescreen {
    min-height: 136px;
  }
}

.header-slide__cta {
  flex: none;
  padding-top: 28px;
  @include IE {
    padding-top: 0;
  }

  .button {
    min-width: calc(8 / 12) * 100%;

    @include tablet {
      min-width: 50%;
    }

    @include tablet-landscape {
      min-width: 33.333%;
    }
  }
}

.header-slide__leafs-wrapper {
  position: absolute;
  overflow: hidden;
  height: 100%;
  bottom: 0;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 0;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: none;
  }
}

.header-slide__leafs {
  position: absolute;
  overflow: hidden;
  right: 20%;
  top: 112px;
  transform: translateX(20%);

  .rfh-leafs--diagonal {
    width: 680px;
  }

  @include tablet {
    right: 30%;
    top: -15px;
    transform: translateX(30%);

    .rfh-leafs--diagonal {
      width: 1160px;
    }
  }

  @include tablet-landscape {
    right: 50%;
    top: -170px;
    transform: translateX(640px);

    .rfh-leafs--diagonal {
      width: 1215px;
    }
  }

  @include desktop {
    right: 50%;
    top: -170px;
    transform: translateX(850px);

    .rfh-leafs--diagonal {
      width: 1500px;
    }
  }

  @include widescreen {
    right: 50%;
    top: -325px;
    transform: translateX(900px);

    .rfh-leafs--diagonal {
      width: 1709px;
    }
  }
}

.header-slides__slide-indicators {
  display: none;
  z-index: 1;

  @include tablet {
    @include basic-flex-box(1rem, "none");
    cursor: pointer;
    left: 50%;
    padding-top: 2em;
    padding-bottom: 2em;
    position: absolute;
    top: 304px;
    transform: translateX(-#{$container-max-width--tablet * 0.5});
  }

  @include tablet-landscape {
    top: 312px;
    transform: translateX(-#{calc($container-max-width--tablet-landscape * 0.5)});
  }

  @include desktop {
    top: 336px;
  }

  @include widescreen {
    top: 296px;
    transform: translateX(-#{calc($container-max-width--widescreen * 0.5)});
  }
}

.header-slides__slide-indicator {
  @extend h5;
  font-weight: 300;
  padding: 0 1rem;

  &.is-active {
    font-weight: 700;
  }
}

.header-slides__slide-controls {
  .header-slides__slide-controls__prev,
  .header-slides__slide-controls__next {
    height: 32px;
    position: absolute;
    top: 346px;
    width: 32px;
    background: transparent icon("arrow-square-fill-grey.svg") center center
      no-repeat;
    background-size: 32px;
    z-index: 10;
  }

  .header-slides__slide-controls__prev {
    transform: scaleX(-1);
    left: 0;
  }

  .header-slides__slide-controls__next {
    content: ">";
    color: yellow;
    right: 0;
  }

  @include tablet {
    display: none;
  }
}

.header-slides__ie-header {
  display: none;
  background-color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  justify-content: flex-end;
  flex-direction: column;
  padding-bottom: 48px;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: flex;
  }

  @include tablet {
    flex-direction: row;
    padding-top: 240px;
    padding-bottom: 0;
  }

  @include tablet-landscape {
    padding-top: 96px;
  }
}

.header-slides__ie-image-wrapper {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: math.div(100%, 16) * 9;

  @include tablet {
    width: 50%;
    height: 0;
    padding-top: math.div(50%, 16) * 9;
  }

  @include tablet-landscape {
    width: 40%;
    height: 0;
    padding-top: math.div(40%, 16) * 9;
  }
}

.header-slides__ie-image {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100%;
  min-width: 100%;
}
