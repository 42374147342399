.c-cards-header {
    &__container {
        @include container;
    }

    &__titles {
        @include section-h-sides;

        padding-top: 20px;
        padding-bottom: 20px;
        max-width: $jumbotron;
        background-color: $green-dark;
    }

    &__section {
        @include section-h-sides;

        background-image: linear-gradient(
            $green-dark 0 10%,
            transparent 10% 100%
        );
        max-width: $jumbotron;

        @include tablet-landscape {
            background-image: linear-gradient(
                $green-dark 0 66%,
                transparent 66% 100%
            );
        }
    }

    &__cards {
        display: grid;
        row-gap: 16px;
        column-gap: 16px;

        @include tablet-landscape {
            margin-top: 0;
            grid-template-columns: repeat(5, 1fr);
        }
    }

    &__card {
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 0px;
        padding-top: calc(100% / 350 * 80);
        transition: all ease $transition-faster;
        background-color: $grey-light;

        &:hover,
        &:focus {
            transform: translateY(-1px) scale(101%);
            cursor: pointer;
            cursor: pointer;
        }

        &:active {
            transform: translateY(1px) scale(100%);
        }

        @include tablet-landscape {
            padding-top: calc(100% / 260 * 210);
        }
    }

    &__card-content {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        padding-bottom: 16px;
        padding-left: 16px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        transition: all ease $transition-faster;
        z-index: 3;
    }

    &__card:hover {
        &:hover,
        &:focus {
            box-shadow: 0 2px 4px 0 rgba($black, 0.15);
        }

        &:active {
            box-shadow: none;
        }
    }

    &__link {
        display: inline-block;
    }

    &__arrow-link {
        display: none;

        @include tablet-landscape {
            display: block;
        }
    }

    &__arrow {
        position: absolute;
        right: 0;
        bottom: 0;
        margin: 16px;
        margin-bottom: 16px;
        height: 16px;
        width: auto;

        @include tablet-landscape {
            display: none;
        }
    }

    &__shadow {
        z-index: 2;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background: linear-gradient(rgba($black, 0.2) 30%, rgba($black, 0.7));
    }
}

.photo-header {
    background: $white;
    max-width: $widescreen;
    margin: 0 auto;
    overflow: hidden;
    position: relative;

    &__title {
        padding-bottom: 8px;
    }

    &__image-container {
        display: block;
        margin: 0 auto;
        max-width: $container-max-width--tablet;
        aspect-ratio: 2/1;
        position: relative;
        width: 100%;

        @include tablet-landscape {
            aspect-ratio: auto;
            background: $green;
            bottom: 0;
            mask-image: image("leaf-right.svg");
            mask-position: left -470px;
            mask-repeat: no-repeat;
            mask-size: 1100px;
            padding-top: 0;
            position: absolute;
            right: 0;
            top: 0;
            @include flex-percentage(2, 1);
        }
    }

    &__container {
        @extend .container;
    }

    &__content {
        position: relative;
        padding-left: $section-outer-padding--mobile;
        padding-right: $section-outer-padding--mobile;

        @include tablet {
            padding-left: 0;
            padding-right: 0;
        }
    }

    &__text-column {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-bottom: 44px;
        padding-top: 28px;

        @include tablet-landscape {
            min-height: 460px;
            padding-bottom: 56px;
            padding-top: 64px;
            @include flex-percentage(12, 5);
        }
    }

    &__searchbar {
        width: 100%;
        margin-top: $padding-size * 2;
        margin-bottom: $padding-size;
    }

    &__most-visited {
        padding-top: $padding-size * 3;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    &__search-button {
        min-width: columnWidth(6, 4);

        @include tablet-landscape {
            min-width: columnWidth(5, 2);
        }
    }

    &__sub-heading {
        padding-bottom: 12px;
    }

    &__title,
    &__sub-heading,
    &__description,
    &__cta {
        @include IE {
            margin: 0;
        }
    }

    &__description {
        flex: 1;
        padding-bottom: 20px;
    }

    &__notification-card-container {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        transform: translateY(-100%);
        margin-left: $section-outer-padding--mobile;
        margin-right: $section-outer-padding--mobile;

        @include tablet {
            margin-left: 0px;
            margin-right: 0px;
        }

        @include tablet-landscape {
            width: calc(5 / 12) * 100%;
            bottom: 0;
            top: auto;
            left: auto;
            transform: translateY(0);
        }

        @include widescreen {
            width: calc(4 / 12) * 100%;
        }
    }

    &--greenDark {
        background-color: $green-dark;
        color: $white;
    }

    &--greenDark &__image-container {
        background-color: $green-dark;
    }

    &--purpleDark {
        background-color: $purple;
        color: $white;
    }

    &--purpleDark &__image-container {
        background-color: $purple;
    }
}

.c-common-header {
    max-width: $widescreen;
    margin: 0 auto;

    aspect-ratio: 375/112;
    background-color: $purple-light;
    display: flex;
    justify-content: center;
    align-items: center;

    background-image: svg("commonHeader/background-mobile.svg");
    background-position: right;
    background-size: contain;
    background-repeat: no-repeat;

    @include tablet-landscape {
        aspect-ratio: 1440/246;

        background-image: svg("commonHeader/background-desktop.svg");
    }
}

.c-article-header {
    max-width: $widescreen;
    margin: 0 auto;
    background-color: $purple-200;

    @include full-grid;

    @include tablet-landscape {
        min-height: 375px;
    }

    @include widescreen {
        min-height: 425px;
    }

    &__content {
        padding-top: 24px;
        padding-bottom: 32px;

        grid-column: main-start/main-end;

        @include tablet-landscape {
            grid-column: main-start / span 6;
            grid-row: 1 / span 1;
            padding-top: 40px;
            padding-right: 20px;
            padding-bottom: 84px;

            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        @include widescreen {
            padding-bottom: 124px;
            grid-column: 3 / span 6;
        }
    }

    &__visual {
        position: relative;
        overflow: hidden;
        width: 100%;
        aspect-ratio: 16/9;
        grid-column: side-start / side-end;

        @include tablet-landscape {
            aspect-ratio: revert;
            grid-column: span 7 / side-end;
            grid-row: 1 / span 1;

            &::before {
                content: "";
                background-image: svg("articleHeader/article_header_shape.svg");
                height: 100%;
                position: absolute;
                left: -1px;
                top: 0;
                bottom: 0;
                right: 0;
                z-index: 2;
                background-position: left;
                background-repeat: no-repeat;
            }
        }

        @include widescreen {
            grid-column: span 6 / side-end;
        }
    }
}

.c-header {
    @include full-grid-with-column-gap-jumbotron;
    background-color: $purple-main;

    @include tablet-landscape {
        min-height: 460px;
        grid-template-rows: 1fr;
    }

    &__content {
        position: relative;
        padding-top: 56px;
        padding-bottom: 40px;
        grid-column: main-start/main-end;
        display: flex;
        flex-direction: column;
        z-index: 2;

        @include tablet-landscape {
            grid-column: main-start/ span 6;
            padding-top: 80px;
            padding-bottom: 64px;
            grid-row: 1 / span 2;
            justify-content: flex-end;
        }

        @include widescreen {
            grid-column: 3 / span 4;
        }
    }

    &--is-big &__content {
        @include tablet-landscape {
            padding-bottom: 32px;
        }
    }

    &__visual {
        &:not(&--mobile-visible) {
            @include tablet-landscape {
                display: block;
            }

            display: none;
        }

        position: relative;
        background-color: $purple-300;
        overflow: hidden;
        grid-column: side-start/side-end;
        aspect-ratio: 16/9;
        align-self: stretch;

        @include tablet-landscape {
            display: block;
            grid-column: span 8 / side-end;
            aspect-ratio: auto;
            grid-row: 1 / span 3;

            &::before {
                background-color: $purple;
                position: absolute;
                display: block;
                content: "";
                mask-image: svg(
                    "generalHeader203/header-shape-desktop-small.svg"
                );
                mask-repeat: no-repeat;
                mask-position: left bottom;
                height: 101%;
                top: 0;
                left: -1px;
                bottom: 0;
                right: 0;
                z-index: 2;
            }

            @include widescreen {
                grid-column: span 7 / side-end;
            }
        }

        img {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            width: 100%;
            height: auto;
            min-width: 100%;
            min-height: 100%;
            object-fit: cover;
        }
    }

    &__button {
        margin-top: 2.25rem;
        display: block;
    }

    &__heading {
        margin-bottom: 0.75rem;
        display: block;
    }

    &__back-link-container {
        display: flex;
        gap: 8px;
        align-items: center;
        padding-bottom: 16px;
        margin-bottom: auto;
    }

    &__breadcrumbs {
        margin-bottom: 16px;

        @include tablet-landscape {
            position: absolute;
            top: 0;
            margin-top: 32px;
            margin-bottom: 0px;
        }
    }

    &__card {
        position: relative;
        z-index: 3;
        background-color: $white;
        padding-top: 32px;
        padding-bottom: 32px;
        border-radius: 6px;
        grid-column: main-start/main-end;
        z-index: 3;
        box-shadow: $card-shadow;
        grid-row: 3 / span 1;

        @include tablet-landscape {
            padding-left: 40px;
            padding-right: 40px;
            transform: none;
            margin-bottom: 56px;
            grid-column: 2 / -2;
            grid-row: 3 / span 1;
            box-shadow: none;
        }

        @include widescreen {
            grid-column: 3 / -3;
        }
    }

    &__card-title {
        margin-bottom: 24px;
        margin-left: 40px;
        margin-right: 40px;

        @include tablet-landscape {
            margin-left: 0px;
            margin-right: 0px;
        }
    }

    &__card-links {
        display: flex;
        flex-direction: column;
        row-gap: 16px;

        @include tablet-landscape {
            flex-direction: row;
            column-gap: 48px;
        }
    }

    &__card-link {
        padding-left: 40px;
        padding-right: 40px;

        &:not(:last-child) {
            border-bottom: 1px solid $grey-very-light;
            padding-bottom: 16px;

            @include tablet-landscape {
                border-bottom: none;
                padding-bottom: 0px;
            }
        }

        @include tablet-landscape {
            padding-left: 0px;
            padding-right: 0px;
        }
    }
    &__searchbar {
        margin-top: 24px;
    }

    &__details {
        display: flex;
        gap: 16px 24px;
        color: $green-dark;
        font-size: 16px;
        margin-top: 1.5rem;
        flex-wrap: wrap;

        &--top {
            margin-bottom: 1.5rem;
        }

        @include widescreen {
            font-size: 18px;
        }
    }

    &__detail {
        display: flex;
        flex-wrap: nowrap;
        gap: 0.5rem;
        align-items: center;

        svg {
            height: 18px;
            width: auto;
        }
    }

    &--is-small {
        @include tablet-landscape {
            min-height: 260px;
        }
    }

    &--is-medium {
        @include tablet-landscape {
            min-height: 380px;
        }
    }

    &--is-big {
        @include tablet-landscape {
            min-height: 547px;
        }
    }

    &--is-green-main {
        background-color: $green-main;
    }

    &--is-green-main &__visual {
        background-color: $green-200;

        &::before {
            @include tablet-landscape {
                background-color: $green-main;
            }
        }
    }

    $greens: "is-green-100" $green-100, "is-green-200" $green-200;

    @each $class, $hex in $greens {
        &--#{$class} {
            background-color: $hex;
        }

        &--#{$class} &__visual {
            background-color: $green-dark;

            &::before {
                @include tablet-landscape {
                    background-color: $hex;
                }
            }
        }

        &--#{$class} &__card-link {
            border-color: darken($green-800, 5%);
        }

        &--#{$class} &__card {
            background-color: $green-dark;
        }
    }

    &--is-green-200 &__card {
        background-color: $green-dark;
    }

    &--is-green-100 {
        background-color: $green-100;
    }

    &--is-green-100 &__visual {
        background-color: $green-200;

        &::before {
            @include tablet-landscape {
                background-color: $green-100;
            }
        }
    }

    &--is-green-100 &__card {
        background-color: $green-200;
    }

    &--is-purple-200 &__card-link {
        border-color: darken($purple-main, 10%);
    }
    &--is-purple-200 {
        background-color: $purple-300;
    }

    &--is-purple-200 &__visual {
        background-color: $purple;

        &::before {
            @include tablet-landscape {
                background-color: $purple-300;
            }
        }
    }

    &--is-purple-200 &__card {
        background-color: $purple-main;
    }

    &__card-background {
        background: linear-gradient(
            180deg,
            transparent 0% 150px,
            $white 150px 100%
        );
        z-index: 2;
        grid-column: side-start / side-end;
        grid-row: 3 / span 1;
        height: 100%;

        @include tablet-landscape {
            display: none;
        }
    }

    &--has-mobile-shape &__visual {
        @include tablet-landscape {
            grid-row: 1 / span 3;
            mask-image: none;
        }

        display: block;
        grid-row: 3 / span 1;
        mask-image: svg("vacancies/leaf2.svg");
        mask-size: cover;
        mask-position: top;
    }

    &--show-image-mobile &__visual {
        display: block;

        img {
            height: 100%;
        }
    }

    &--has-padding-bottom &__content {
        padding-bottom: 90px;

        @include tablet-landscape {
            padding-bottom: 110px;
        }
    }

    &--animated {
        @include tablet-landscape {
            position: relative;
            overflow: hidden;

            .c-header__visual {
                grid-column: 1 / -1;
                background-image: right center;
                &::before {
                    display: none;
                }
            }

            &::after {
                position: absolute;
                content: "";
                width: 110%;
                height: 300%;
                background-color: $green-light;
                border-radius: 100%;
                z-index: 0;
                top: -45%;
                left: -55%;
                transform: translateX(-100%);
                transition: $transition-slower all ease-out;
            }

            &.loaded {
                &::after {
                    transform: translateX(0%);
                }

                .c-header {
                    &__paragraph,
                    &__heading,
                    &__content {
                        opacity: 1;
                        transform: translateY(0);
                    }
                }
            }

            .c-header__content {
                opacity: 0;
                display: flex;
                position: relative;
                z-index: 2;
                transition: $transition-slower all ease-out;
                transition-delay: $transition-slow;
            }

            &__paragraph,
            &__heading {
                opacity: 0;
                transition: $transition-slower all ease-out;
                transition-delay: $transition-slow;
                transform: translateY(10%);
            }

            &__paragraph {
                transition-delay: $transition-slower;
            }

            &__heading {
                transition-delay: $transition-slow;
            }
        }
    }
}

.c-video-header {
    @include full-grid-jumbotron;

    &__content {
        grid-column: main-start / main-end;
        grid-row: 1 / span 1;
        z-index: $c-video-header-index;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__visual {
        grid-column: 1 / -1;
        grid-row: 1 / span 1;
        position: relative;
        height: 0;
        width: 100%;
        padding-bottom: calc(100% / 1440 * 600);
        overflow: hidden;
    }

    &__image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        @include tablet-landscape {
            width: 100%;
            height: 100%;
        }
    }
}

.c-hero-header-with-icons {
    position: relative;
    overflow: hidden;
    margin-top: -$navigation-mobile-height;

    @include tablet-landscape {
        margin-top: -$navigation-height;
    }

    &__image {
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__content {
        position: relative;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 1;

        color: $white;
        background: linear-gradient(#0000, #0008 88%);
        text-align: center;
    }

    &__title {
        max-width: 730px;
        padding-inline: 22px;
        margin-top: $navigation-mobile-height + 36px;

        @include tablet-landscape {
            margin-top: $navigation-height + 94px;
        }
    }

    &__description {
        max-width: 716px;
        margin-top: $u-16;
        padding-inline: 22px;
        transform: translateY(200px);
        opacity: 0;
        transition: transform $move-in-animation-speed 0.9s,
            opacity $move-in-animation-speed 0.9s;

        @include tablet {
            margin-top: $u-24;
        }
    }

    &__cta {
        margin-top: $u-24;
        transform: translateY(200px);
        opacity: 0;
        transition: transform $move-in-animation-speed 1.1s,
            opacity $move-in-animation-speed 1.1s;

        @include tablet {
            margin-top: $u-32;
        }
    }

    &__highlights {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: calc(100% - 44px);
        margin-top: 54px;
        margin-bottom: 22px;

        color: $black;
        background-color: $white;
        border-radius: 6px;
        transform: translateY(200px);
        opacity: 0;
        transition: transform $move-in-animation-speed 1.3s,
            opacity $move-in-animation-speed 1.3s;

        @include tablet {
            flex-direction: row;
            width: min(90%, 1130px);
            margin-top: 74px;
            margin-bottom: 76px;
        }
    }

    &__highlight {
        flex: 1 1 0px;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: $u-8;
        padding-inline: 20px;
        padding-block: 12px;

        font-weight: 600;
        text-decoration: none;

        &:not(:last-child) {
            border-bottom: 1px solid $grey;
        }

        @include tablet {
            flex-direction: column;
            padding-inline: $u-16;
            padding-block: $u-24;

            &:not(:last-child) {
                border-bottom: 0;
                border-inline-end: 1px solid $grey;
            }
        }
    }

    &__highlight-icon {
        width: 32px;
        height: 32px;
        path {
            fill: #380f92;
        }

        @include tablet {
            width: 56px;
            height: 56px;
        }
    }
    &.in-view {
        .c-hero-header-with-icons {
            &__cta,
            &__description,
            &__highlights {
                transform: translateY(0);
                opacity: 1;
            }
        }
    }
}

.c-hero-search {
    background-color: lightblue;
    position: relative;
    display: flex;
    align-items: center;
    height: calc(100vh - $navigation-mobile-height);

    @include tablet-landscape {
        // minus navigation and top bar height
        height: calc(100vh - $navigation-main-height - 36px);
    }

    &::after {
        background: linear-gradient(
            to right,
            rgba(0, 0, 0, 0.5) 0%,
            rgba(0, 0, 0, 0) 96%
        );
        bottom: 0;
        content: "";
        display: block;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;

        @include tablet-landscape {
            background: linear-gradient(
                to right,
                rgba(0, 0, 0, 0.5) 0%,
                rgba(0, 0, 0, 0) 62%
            );
        }
    }

    &__container {
        @include full-grid;
        height: 100%;
        align-content: end;
        z-index: 2;
        justify-content: left;
        padding-bottom: 56px;

        @include tablet {
          align-content: center;
          padding-bottom: 0;
        }
    }

    &__visual {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    &__title {
        grid-column: main-start/main-end;
        margin-bottom: 24px;

        @include tablet {
            grid-column: main-start / span 9;
            margin-bottom: 40px;
        }

        @include widescreen {
            grid-column: main-start / span 6;
        }
    }

    &__description {
        grid-column: main-start/main-end;
        margin-bottom: 24px;

        @include tablet {
            grid-column: main-start / span 9;
            margin-bottom: 40px;
        }

        @include widescreen {
            grid-column: main-start / span 6;
        }
    }

    &__searchbar {
        grid-column: main-start/main-end;
        display: flex;
        flex-direction: column;
        row-gap: 16px;
        align-items: flex-start;

        @include tablet {
            grid-column: main-start / span 9;
            flex-direction: row;
            column-gap: 16px;
        }

        @include widescreen {
            grid-column: main-start / span 6;
        }
    }

    &__search-input {
        @include tablet {
            width: 100%;
        }
    }

    &--jobs {
        @include tablet-landscape {
            height: calc(100vh - $navigation-jobs-height);
        }
    }
}

.c-events-header {
    @include full-grid;
    
        overflow: hidden;
        position: relative;
        column-gap: 0px;
        font-kerning: none;
    
        &::before {
            display: flex;
    
            content: "";
            background-image: svg("hero_header/header-shape-wit-mobile.svg");
            background-size: contain;
            background-repeat: no-repeat;
            aspect-ratio: 375 / 31;
    
            position: absolute;
            z-index: 6;
    
            right: 0;
            bottom: 0;
            left: 0;
            width: 100%;
    
            @include tablet-landscape {
                background-image: svg("hero_header/header-shape-wit-desktop.svg");
                aspect-ratio: 1440/221;
                width: 100%;
                transform: translateY(47%);
            }
    
            @include desktop {
                content: initial;
            }
        }


        .button {
            font-size: 18px;
            font-weight: 700;
            background: #ff7200;
            border: 1px solid #ff7200;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;

            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            color: #ffffff !important;
            text-align: center;
            text-decoration: none !important;
            padding-left: 24px;
            padding-right: 24px;
            padding-top: 4px;
            padding-bottom: 6px;
            text-transform: uppercase;
        }


        .c-pill {
        display: inline-block;
        background: $grey;
        border: 1px solid $grey;
        border-radius: 100px;
        line-height: 1.5;
        margin-bottom: 6px;
        margin-top: 6px;
        padding-bottom: 6px;
        padding-top: 4px;
        padding-right: 18px;
        padding-left: 18px;
        text-decoration: none;
        font-weight: 600;

        &--square {
            border-radius: 0px;
        }

        &--red {
            color: $white;
            border: 1px solid $danger;
            background-color: $danger;
        }

        &--primary {
            background-color: $color-primary-light;
            color: $color-primary-dark;
            border: 1px solid $color-primary-light;
        }

        &--primary#{&}--active {
            background-color: $color-primary;
            border-color: $color-primary;
            color: $white;
        }

        &--green {
            color: $white;
            border: 1px solid $green;
            background-color: $green;
        }

        &--grey-dark {
            color: $white;
            border: 1px solid $grey-dark;
            background-color: $grey-dark;
        }

        &--outline {
            border: 1px solid white;
            background-color: transparent;
            color: white
        }

        &--heading-4 {
            @extend .t-heading-4;
        }

        &.is-small,
        &--small {
            @extend h6;
            font-weight: 300;
            margin-bottom: 4px;
            margin-top: 4px;
            padding-left: 8px;
            padding-right: 8px;
        }

        &--clickable {
            cursor: pointer;
        }

        &.is-active {
            background: $white;
            border-color: $white;
        }

        &--close {
            margin: 0;
            padding-top: 2px;
            padding-bottom: 2px;
            @extend h5;
            font-weight: 300;
            padding-right: 30px;
            position: relative;
            svg {
            position: absolute;
            top: 50%;
            right: 11.5px; //weird antialiasing thing
            height: 12px;
            width: 12px;
            transform: translateY(-50%);
            }
            // pill close styling
            @include until-tablet-landscape {
            display: inline-block;
            background: $grey;
            border: 1px solid $grey;
            border-radius: 12px;
            line-height: 1.5;
            padding-left: 10px;
            padding-right: 32px;
            text-decoration: none;
            background: $white;
            border-color: $white;
            svg {
                display: block;
            }
            }
            // transparent grey button styling
            @include tablet-landscape {
            color: $grey-dark;
            background: transparent;
            border: none;
            text-align: left;
            padding: 0.5em 2 * $padding-size;
            padding-left: 0;
            font-weight: normal;
            svg {
                display: none;
            }
            &:hover {
                text-decoration: underline;
                cursor: pointer;
            }
            }
        }
        }

        .l-pill-list {
        display: flex;
        flex-wrap: wrap;
        column-gap: 8px;

        &--justify-center {
            justify-content: center;
        }
    }
    
        &__content-container {
            position: relative;
            grid-column: side-start / side-end;
            grid-row: 2 / span 1;
            background-color: $color-primary;
            height: 100%;
            z-index: -1;
    
            @include tablet-landscape {
                grid-column: side-start / span 6;
                grid-row: 1 / span 1;
                z-index: 1;
            }
    
            @include desktop {
                background-color: transparent;
            }
        }
    
        &__content {
            grid-column: main-start / main-end;
            grid-row: 2 / span 1;
            padding-bottom: 80px;
            padding-top: 56px;
            z-index: 3;
    
            @include tablet {
                padding-bottom: 120px;
            }
    
            @include tablet-landscape {
                grid-column: 2 / span 7;
                grid-row: 1 / span 1;
    
                padding-top: 140px;
                padding-bottom: 200px;
            }
    
            @include widescreen {
                grid-column: 3 / span 6;
            }
        }
    
        &__visual {
            position: relative;
            grid-column: side-start/ side-end;
            height: 0px;
            padding-top: calc(100% / 16 * 9);
            overflow: hidden;


            .image {
                height: 100%;
                position: unset;
            }   
    
            @include tablet-landscape {
                height: 100%;
                padding-top: 0px;
                grid-column: -9 / side-end;
                grid-row: 1 / span 1;
            }
    
            @include desktop {
                grid-column: 4 / side-end;
    
                transform: translateX(150px);
            }
    
            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
    
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;

            }
    
            &::before {
                display: flex;
    
                content: "";
                background-image: svg("hero_header/header-shape-paars-mobile.svg");
                aspect-ratio: 375 / 31;
                background-size: contain;
                background-repeat: no-repeat;
    
                z-index: 3;
                width: 100%;
                position: absolute;
                right: 0;
                bottom: 0;
                left: 0;
    
                @include tablet-landscape {
                    background-image: svg("hero_header/header-shape-paars-desktop.svg");
                    top: -1px;
                    left: -1px;
                    bottom: 0;
                    right: auto;
                    height: 100%;
                    width: auto;
                    aspect-ratio: 262 / 694;
                }
    
                @include desktop {
                    content: initial;
                }
            }
        }
    
        &__leaf-left {
            display: none;
    
            @include desktop {
                display: block;
    
                position: absolute;
                left: -14%;
                top: -22%;
    
                width: 1122px;
                height: 1480px;
            }
        }
    
        &__leaf-bottom {
            display: none;
    
            @include desktop {
                display: block;
    
                position: absolute;
                bottom: -18.5%;
    
                width: 100%;
    
                opacity: 0;
                visibility: hidden;
            }
        }
    
        &__heading {
            margin-bottom: 16px;
    
            @include tablet-landscape {
                margin-bottom: 32px;
                max-width: 80%;
            }
    
            @include desktop {
                max-width: 75%;
            }
        }
    
        &__pills {
            margin-bottom: 16px;
    
            @include tablet-landscape {
                margin-bottom: 32px;
            }
        }
    
        &__description {
            margin-bottom: 16px;
    
            @include tablet-landscape {
                margin-bottom: 32px;
            }
    
            @include desktop {
                max-width: 70%;
            }
        }
    
        &__buttons {
            display: flex;
            row-gap: 16px;
            column-gap: 24px;
            flex-wrap: wrap;
        }
    
        &__button {
            width: 100%;
    
            @include tablet {
                width: auto;
            }
        }
    
        &--small::before {
            @include desktop {
                content: "";
            }
        }
    
        &--small &__visual {
            // grid-column: -9 / side-end;
    
            transform: initial;
    
            &::before {
                @include tablet-landscape {
                    background-image: svg("hero_header/header-shape-paars-desktop-small.svg"
                        );
                    aspect-ratio: 230/398;
                }
    
                @include desktop {
                    content: "";
                }
            }
        }
    
        &--small &__content {
            @include widescreen {
                grid-column: 3 / span 5;
            }
        }
    
        &--small &__content-container {
            @include desktop {
                background-color: $color-primary;
            }
        }


        margin-top: 90px;

        @media screen and (min-width: 1440px) {
            margin-top: 0px;
        }
}
