.search {
  position: relative;

  &__input {
    width: 100%;
    border-radius: $green-dark;
    border-width: 1px;
    padding: 13px;
    padding-left: 56px;
    border-radius: 6px !important;
    transition: border 0.2s ease;

    &:focus,
    &:focus-within {
      outline: none;
      border-color: $orange;
    }
    &--results {
      border-radius: 6px 6px 0 0 !important;
    }
  }

  &__dropdown {
    position: absolute;
    top: 48px;
    background-color: white;
    width: 100%;
    padding: 13px;
    padding-left: 56px;
    border: 1px solid $grey;
    border-top: 0;
    border-radius: 0 0 6px 6px;
    z-index: 2;
    max-height: 300px;
    overflow-y: auto;
  }

  &__item {
    & + & {
      margin-top: 16px;
    }
  }

  &__link {
    text-decoration: none;
    font-size: 14px;
    display: flex;
    gap: 8px;
    align-items: center;
  }

  &__item-icon {
    flex: 0 0 16px;
    fill: $grey;
  }

  &__highlight {
    &--thin {
      color: inherit;
    }
    &--thick {
      color: inherit;
      font-weight: bold;
      background-color: inherit;
    }
  }

  &__close {
    @include basic-reset;
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;

    @include tablet-landscape {
      opacity: 0.7;
      @include on-event {
        opacity: 1;
      }
    }
  }

  &__icon {
    color: black;
    position: absolute;
    left: 16px;
    top: 50%;
    transform: translateY(-50%);

    &--clickable {
      cursor: pointer;
    }
  }
}

.c-filtering {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  list-style: none;

  &:not(&--is-root-level) {
    padding-top: 16px;
    padding-left: 16px;
  }
}

.c-chevron {
  width: 13px;

  &--big {
    width: 19px;
  }

  &--rotated {
    transform: rotate(180deg);
  }
}
