.c-pop-up {
    margin-left: $section-outer-padding--mobile;
    margin-right: $section-outer-padding--mobile;
    max-width: 470px;

    @include tablet-landscape {
        margin-left: 0px;
        margin-right: 0px;
    }
}
