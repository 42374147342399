
            @import "@/styles/abstracts/all.scss";
          

.c-card-colored {
    display: none;

    @include tablet-landscape {
        display: flex;
    }

    flex-direction: column;
    align-items: flex-start;
    row-gap: 16px;

    background-color: $green-light;
    padding-top: 40px;
    padding-right: 24px;
    padding-bottom: 40px;
    padding-left: 24px;

    background-image: svg("cardColored/shape.svg");
    background-repeat: no-repeat;
    background-position: bottom right;
}
