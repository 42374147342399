/*
Events Colors

These colors are specific to the events theme
some colors are re-used from the default theme, and can be found in the colors.scss.
these vars are used in specific events components.
 */


$purple-dark: #270047;
$purple-light: #d5cbe7;
$purple-extra: #5b2692;

$green-forest: #003e1a;
$green-dark: #3a4d41;
$color-primary-extra: $purple-extra;
$color-primary-dark: $purple-dark;
$color-primary: $purple;
$color-primary-light: $purple-light;

$color-secondary: $green-dark;
$color-tertiary: $green;
$color-quartary: $orange;
