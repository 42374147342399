.c-accordion {
    border-bottom: $border-standard-2023;

    &:not(:last-child) {
        margin-bottom: 16px;
    }

    &[open] &__icon {
        transform: rotate(180deg);
    }

    &[open] &__summary {
        padding-bottom: 24px;
    }

    &__summary {
        display: flex;
        cursor: pointer;
        padding-bottom: 16px;
    }

    &__content {
        padding-bottom: 32px;
    }

    &__icon {
        margin-left: auto;
        display: flex;
        align-items: center;
    }

    summary::-webkit-details-marker {
        display: none;
    }
}

.l-faq {
  @include tablet-landscape {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: $gutter;
  }
}

.c-details {
    border-top: 1px solid black;
    padding-top: 12px;
    padding-bottom: 12px;

    &__summary {
        position: relative;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        &:after {
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto 0;
            content: "";
            mask-image: icon("chevron-down.svg");
            mask-size: contain;
            mask-repeat: no-repeat;
            background-color: $orange;
            aspect-ratio: 2 / 1;
            width: 16px;
            height: 8px;
            transition: transform 0.2s ease;
            transform: rotate(-90deg);
        }
    }

    &__title {
        padding-right: 32px;
    }

    &[open] > &__summary {
        font-weight: 600;

        &:after {
            transform: rotate(0deg);
        }
    }

    &__content {
        padding-top: 12px;
        padding-bottom: 12px;
    }

    &--has-border-bottom {
        padding: 0;
        border-top: none;
        border-bottom: 1px solid $grey-very-light;
    }

    &--has-border-bottom &__content {
        padding-top: 0px;
        padding-bottom: 24px;
    }
}
