@use "sass:math";

.image-gallery {
  margin-top: 80px;
  margin-bottom: 80px;

  &__main {
    display: flex;
    flex-direction: column;
  }

  &__image {
    bottom: 0;
    height: auto;
    left: 0;
    margin: 0 auto;
    max-width: 100%;
    min-height: 100%;
    object-fit: cover;
    position: absolute;
    right: 0;
    top: 0;
  }

  &__player {
    width: 100%;
    max-width: $video-player-max-width;
    margin: 0 auto;
  }  

  &__primary-caption {
    margin-top: 4px;
    font-family: $micro-font-stack;
    font-size: 9px;
    text-transform: uppercase;
    letter-spacing: 0.1px;
    height: 40px;
    width: 100%;
  }

  &__primary-image {
    cursor: pointer;
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 0;
    padding-top: math.div(100%, 16) * 9;
  }

  &__secondary-images {
    margin: 0 (-$padding-size * 0.5);
    display: flex;
  }

  &__secondary-images-item {
    position: relative;
    width: 25%;
    padding: 0 ($padding-size * 0.5);
  }
  
  &__modal {
    visibility: hidden;
    z-index: -1;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 15;
    background-color: rgba(0, 0, 0, 0.8);

    display: flex;
    justify-content: center;
    align-items: flex-end;

    &--open {
      visibility: visible;
      z-index: 15;
    }
  }

  &__close {
    cursor: pointer;
    z-index: 16;
    color: $grey;
    font-weight: 600;
    position: absolute;
    right: 0;
    top: 0;
    margin-right: $padding-size * 2;
    margin-top: $padding-size * 2;
    opacity: 0.5;
    background: transparent;
    border: none;
    &:hover {
      opacity: 1;
    }
  }

  &__modal-inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 90vh;
  }

  &__details {
    height: 90%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    opacity: 0;
  }

  &__details-button {
    opacity: 0.5;
    height: 32px;
    width: 32px;
    background-color: black;
    border: none;
    cursor: pointer;
    background-image: icon("arrow-grey.svg");
    background-size: 22px auto;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    @include until-tablet {
      position: absolute;
      right: 0;
    }

    &:hover,
    &:focus {
      opacity: 1;
    }

    @include tablet {
      margin: 0 $section-outer-padding;
    }
    @include widescreen {
      margin: 0 $section-outer-padding--widescreen;
    }

    &--previous {
      left: 0;
      transform: rotate(180deg);
    }
  }

  &__modal--open &__details {
    animation: animate-modal $transition-slow forwards;
    animation-delay: 0.3s;

    @keyframes animate-modal {
      0% {
        transform: translateY(-200px);
        opacity: 0;
      }
      100% {
        transform: translateY(0);
        opacity: 1;
      }
    }
  }

  &__modal-image {
    height: auto;
    margin: 0 auto;
    max-height: 90%;
    max-width: 100vw;
    width: auto;

    @include tablet {
      max-width: 80vw;
    }
  }

  &__navigation {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: center;
  }

  &__navigation-title {
    color: $grey-light;
    font-family: $micro-font-stack;
    font-size: 13px;
    text-transform: uppercase;
    letter-spacing: 0.1px;
  }

  &__indicator {
    display: flex;
    justify-content: center;
    padding-top: $padding-size;
    padding-bottom: $padding-size * 2;
  }

  &__indicator-item {
    cursor: pointer;
    width: 18px;
    height: 3px;
    position: relative;
    background-color: $grey;
    &:after {
      width: 18px;
      height: 12px;
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
    &:not(:last-child) {
      margin-right: 10px;
    }

    &--active {
      background-color: $orange;
    }
  }
}
