$component: "c-two-images";

.#{$component} {
  align-items: flex-end;
  &__image-wrapper {
    margin-top: 32px;
  }
  &__image-container {
    & + & {
      margin-top: 16px;
    }
    &:first-child {
      .#{$component} {
        &__image-title {
          margin-bottom: 32px;
        }
      }
    }
  }
  &__image {
    width: 100%;
    aspect-ratio: 555 / 382;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  @include tablet-landscape {
    &__image-wrapper {
      display: flex;
      gap: 20px;
    }
    &__image-container {
      width: 100%;
      & + & {
        margin-top: 0;
      }
    }
    &__image {
      max-width: 555px;
    }
    &__image-title {
      margin-top: 16px;
    }
  }
}
