
            @import "@/styles/abstracts/all.scss";
          

$component: "temp-search-bar";

.#{$component} {
    background-color: $white;
    position: relative;
    z-index: 23;
    &__container {
        position: fixed;
        z-index: -1;
        background-color: $white;
        top: -100dvh;
        left: 0;
        width: 100%;
        height: 100dvh;
        padding: 0 8px;
        transition: all 0.3s ease;
        &--active {
            top: $navigation-mobile-height;
        }
    }
    &__input {
        left: 0;
        width: 100%;
        border: 0;
        padding: 10px 15px;
        border: 2px solid $green;
        border-radius: 4px;
    }
}
