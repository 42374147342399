.c-tabs {
  padding-left: 16px;
  padding-right: 16px;
  border-bottom: $border-standard-2023;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @include tablet-landscape {
    padding-left: 40px;
    padding-right: 40px;
  }

  &__list {
    display: flex;
    column-gap: 24px;

    @include tablet-landscape {
      column-gap: 48px;
    }
  }

  &__item {
    margin-top: 24px;
    border-bottom: 5px solid transparent;
    flex: 0 0 auto;

    &:hover {
      border-bottom: 5px solid rgba($green, 0.2);
    }

    @include tablet-landscape {
      margin-top: 0;
    }

    &--active,
    &--active:hover {
      border-bottom: 5px solid $green;
    }
  }

  &__link {
    color: rgb($black, 0.3);
    transition: all 0.1s ease-in-out;
    display: inline-block;
    text-decoration: none;
    font-family: $title-font-stack;
    font-size: 18px;
    padding-bottom: 12px;

    @include tablet-landscape {
      font-size: 21px;
    }
  }

  &__item--active &__link {
    color: $black;
    pointer-events: none;
  }

  &__item:not(.c-tabs__item--active):hover &__link {
    transform: translateY(-1px);
  }
}
