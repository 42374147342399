.c-text-links-block {
  @include full-grid;
  row-gap: 24px;

  &__content {
    grid-column: main-start / main-end;

    @include tablet-landscape {
      grid-column: main-start / span 5;
      grid-row: 1 / span 1;
    }

    @include widescreen {
      grid-column: 3 / span 4;
    }
  }

  &__card {
    grid-column: main-start / main-end;
    border: $border-standard-2023;
    border-radius: $border-radius-standard;
    padding: 32px 24px;
    background-color: $white;

    @include tablet-landscape {
      grid-column: span 6 / main-end;
      grid-row: 1 / span 1;
      display: flex;
      justify-content: space-between;
      column-gap: 16px;
    }

    @include widescreen {
      grid-column: span 5 / -3;
    }
  }

  &__visual {
    display: none;

    @include tablet-landscape {
      display: flex;
      flex: 0 0 41%;
    }
  }

  &__links {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    margin-right: auto;
  }

  &--hasCardLeft &__content {
    @include tablet-landscape {
      grid-column: span 5 / main-end;
    }

    @include widescreen {
      grid-column: span 4 / -3;
    }
  }

  &--hasCardLeft &__card {
    @include tablet-landscape {
      grid-column: main-start / span 6;
    }
    @include widescreen {
      grid-column: 3 / span 5;
    }
  }
}
