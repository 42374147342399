.c-testimonials {
  &__inner {
    position: relative;
  }

  &__slide {
    position: absolute;

    @include desktop {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      gap: 1.5rem;

      width: 100%;
    }

    @include widescreen {
      justify-content: space-evenly;
    }

    &.controls-hidden {
      .c-testimonials__slide-controls {
        display: none;
      }
    }
  }

  &__slide-content {
    @include desktop {
      width: 100%;
      max-width: 512px;
      padding-top: 4rem;
    }

    > * {
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
    }
  }

  &__title {
    margin-bottom: 6px;

    @include desktop {
      margin-bottom: 1.5rem;
    }
  }

  &__quote {
    margin-bottom: 1rem;

    @include desktop {
      margin-bottom: 38px;
    }
  }

  &__footer {
    margin-bottom: 3rem;
  }

  &__image {
    margin: 0 auto;
    width: 50vw;
    max-width: 400px;

    opacity: 0;
    visibility: hidden;

    @include desktop {
      margin: initial;
    }
  }

  &__controls {
    display: flex;
    justify-content: space-between;

    position: absolute;
    bottom: 60px;

    width: 100%;

    @include desktop {
      display: none;
    }
  }

  &__slide-controls {
    display: none;

    @include desktop {
      display: flex;
      gap: 2rem;

      position: absolute;
      bottom: 0;
      z-index: 1;

      visibility: visible;
      opacity: 1;
      transform: translateX(-1rem);

      pointer-events: all;
    }

    @include widescreen {
      bottom: 1.5rem;
    }
  }

  &__button {
    @include basic-reset;

    height: 2rem;
    width: 2rem;

    border: 1px solid transparent;
    border-radius: 50%;

    transition: border 300ms;
    cursor: pointer;

    @include on-event {
      border: 1px solid $green-dark;
    }
  }
}
