
            @import "@/styles/abstracts/all.scss";
          

.c-filter-component {
    display: flex;
    flex-direction: column;

    &__main {
        position: fixed;
        display: flex;
        flex-direction: column;
        top: 0;
        right: 24px;
        bottom: 0;
        left: 0;
        background-color: $white;
        width: 100%;
        height: 100%;
        z-index: $navigation-mobile-z-index + 2;
        transform: translateX(-100%);
        transition: transform 0.3s ease-in-out;
        display: flex;

        @include tablet-landscape {
            position: static;
            transform: none;
            z-index: auto;

            border: $border-standard-2023;
            border-radius: 6px;
            padding: 22px 16px;
        }
    }

    &--is-open &__main {
        transform: translateX(0);
    }

    &__container {
        width: 100%;

        &--main-bar {
            display: flex;
            flex-direction: column;
            row-gap: 16px;
            align-items: center;

            @include mobile {
                flex-direction: row;
                justify-content: space-between;
            }
        }

        &--footer {
            display: flex;
            justify-content: flex-start;
            align-items: stretch;
            column-gap: 8px;
        }

        @include until-tablet {
            @include section-h-sides;
        }

        @include until-tablet-landscape {
            @include container;
        }

        &--flex-end {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
        }
    }

    &__footer {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: $white;
        z-index: calc($navigation-mobile-z-index - 1);
        border-top: $border-standard-2023;
        display: flex;
        align-items: center;
        padding-top: 20px;
        padding-bottom: 20px;

        @include tablet-landscape {
            display: none;
        }
    }

    &__main-header {
        @include until-tablet {
            @include section-h-sides;
        }

        border-bottom: $border-standard-2023;
        padding-bottom: 20px;
        padding-top: 20px;
        margin-bottom: 20px !important;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include until-tablet-landscape {
            @include container;
        }

        @include tablet-landscape {
            padding-top: 0px;
        }
    }

    &__refinement {
        margin-bottom: 20px;
        &:last-child {
            margin-bottom: 0;
        }
    }

    &__refinement-title {
        padding-bottom: 8px;
        border-bottom: $border-standard-2023;
        margin-bottom: 16px;
    }

    &__main-filters {
        width: 100%;
        height: calc(100vh - 120px);
        overflow-y: auto;
        padding-bottom: 100px;

        @include tablet-landscape {
            height: auto;
            padding-bottom: 0px;
        }
    }

    &__main-bar {
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: $white;
        border-top: $border-standard-2023;
        display: flex;
        align-items: center;
        padding-bottom: 20px;
        padding-top: 20px;

        @include tablet-landscape {
            display: none;
        }
    }

    &__close {
        @include tablet-landscape {
            display: none;
        }
    }
}
