.c-testimonial-card {
    background-color: $green-200;
    border-radius: 6px;
    overflow: hidden;
    box-shadow: $card-shadow;

    @include tablet-landscape {
        display: flex;
    }

    &__visual {
        position: relative;
        @include tablet-landscape {
            flex: 0 0 33%;
        }
    }

    &__visual-container {
        position: relative;
        padding-top: calc(100 / 16) * 9%;
        overflow: hidden;

        @include tablet-landscape {
            padding-top: 0px;
            height: 100%;
        }
    }

    &__image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        z-index: 0;
    }

    &__content {
        padding: 24px;

        @include tablet-landscape {
            padding: 40px;
        }
    }

    &__quote {
        margin-top: 44px;
        position: relative;

        &::before {
            position: absolute;
            content: "";
            mask-image: svg("/testimonialCard/quotation_mark.svg");
            top: -44px;
            left: 0;
            height: 29px;
            width: 29px;
            background-color: $green-main;
            mask-repeat: no-repeat;
            mask-size: contain;
        }
    }

    &__cta {
        margin-top: 28px;
    }

    &__name {
        margin-top: 40px;
        font-size: 16px;
        font-weight: 600;
    }

    &__title {
        font-size: 16px;
        font-weight: 300;
    }

    &__shape {
        display: none;
        color: $green-200;

        @include tablet-landscape {
            display: block;
            position: absolute;
            bottom: 0;
            right: -1px;
            height: 100%;
            z-index: 2;
            width: auto;
        }
    }

    &--purple-200 {
        background-color: $purple-200;
    }

    &--purple-200 &__quote {
        &::before {
            background-color: $purple-main;
        }
    }

    &--purple-200 &__shape {
        color: $purple-200;
    }
}

.grid-card {
    position: relative;
    display: flex;
    min-height: 130px;

    @include tablet-landscape {
        min-height: 260px;
        &--topLeft {
            .grid-card__overlay {
                background: linear-gradient(
                    180deg,
                    rgba(0, 0, 0, 0.5) 4.42%,
                    rgba(0, 0, 0, 0.05) 100%
                );
            }

            .grid-card__text {
                justify-content: start;
                align-items: start;
            }
        }

        &--topRight {
            .grid-card__overlay {
                background: linear-gradient(
                    253.39deg,
                    rgba(0, 10, 4, 0.6) 0%,
                    rgba(0, 10, 4, 0.06) 100%
                );
            }

            .grid-card__text {
                justify-content: start;
                align-items: end;
            }
        }

        &--bottomLeft {
            .grid-card__overlay {
                background: linear-gradient(
                    179.82deg,
                    rgba(3, 91, 40, 0) 0.16%,
                    rgba(0, 10, 4, 0.6) 76.07%
                );
            }

            .grid-card__text {
                justify-content: end;
                align-items: start;
            }
        }

        &--bottomRight {
            .grid-card__overlay {
                background: linear-gradient(
                    253.39deg,
                    rgba(0, 10, 4, 0.6) 0%,
                    rgba(0, 10, 4, 0.06) 100%
                );
            }

            .grid-card__text {
                justify-content: end;
                align-items: start;
            }
        }
    }

    &__text {
        position: relative;
        width: 100%;
        color: $white;
        justify-content: end;
        padding: 1rem;
        display: flex;
        flex-direction: column;

        &--number {
            font-size: calcEm(40px);
            font-weight: 800;
            line-height: 1;
            text-shadow: 0px 4px 15px #0000004d;
        }
        &--subtitle {
            font-size: calcEm(12px);
            margin-top: calcEm(4px);
            font-weight: 300;
            text-shadow: 0px 4px 15px #0000004d;
        }
        @include tablet-landscape {
            padding: 1.5rem;

            &--number {
                font-size: calcEm(78px);
            }
            &--text {
                font-size: calcEm(24px);
            }
            &--subtitle {
                font-size: calcEm(21px);
            }
        }
    }

    &__overlay {
        position: absolute;
        height: 100%;
        width: 100%;
        background: linear-gradient(
            180deg,
            rgba(0, 10, 4, 0.05) 0%,
            rgba(0, 10, 4, 0.5) 100%
        );
    }

    &__image {
        position: absolute;
        width: 100%;
        height: 100%;
        @include object-fit;
    }
}

.image-card {
    position: relative;
    text-decoration: none;
    display: flex;
    padding: 1rem;
    flex-direction: column;
    color: $white;
    justify-content: end;
    aspect-ratio: 330/200;

    @include tablet-landscape {
        aspect-ratio: 280/330;
    }

    &:visited,
    &:active,
    &:hover {
        color: $white;
    }

    &:hover {
        .image-card {
            &__image {
                filter: brightness(0.9);
            }

            &__link {
                gap: 1.5rem;
            }
        }
    }

    &__image {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
        object-fit: cover;
        z-index: -1;
        transition: all $transition-fast;

        &::before {
            content: "";
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            background: linear-gradient(
                180.09deg,
                rgba(0, 26, 11, 0) 50%,
                rgba(0, 26, 11, 0.4) 93.06%
            );
        }
    }

    &__title {
        font-size: 1.5rem;
    }

    &__link {
        font-size: 0.875rem;
        margin-top: 0.5rem;
        display: flex;
        align-items: center;
        gap: 1rem;
        transition: all $transition-fast;
    }
}

.c-card-disruption {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &__tags {
        margin-bottom: 16px;
        display: flex;
        column-gap: 8px;
        flex-wrap: wrap;
        row-gap: 8px;
    }

    &__time-details {
        &:not(:last-child) {
            margin-bottom: 16px;
        }
    }

    &__time-details {
        display: grid;
        grid-template-columns: auto auto;
        justify-content: flex-start;
        column-gap: 22px;
        row-gap: 8px;
    }

    &__status {
        display: flex;
        align-items: center;
        margin-bottom: 16px;

        @include tablet {
            position: absolute;
            top: 0;
            right: 24px;
        }
    }

    &__status-icon {
        display: none;
        margin-right: 8px;
        color: $green;
    }

    &__title {
        margin-bottom: 16px;
    }

    &--resolved &__status-icon {
        display: flex;
    }

    &--small &__status {
        @include tablet {
            position: inherit;
            top: auto;
            right: auto;
        }
    }

    &--small &__title {
        display: none;
    }

    &--small &__status {
        order: -1;
    }
}

.c-card-outline {
    border: $border-standard-2023;
    border-radius: $border-radius-standard;
    background-color: $white;
    padding-top: 16px;
    padding-right: 24px;
    padding-bottom: 16px;
    padding-left: 24px;

    &--no-padding {
        padding: 0;
    }
}

.c-image-cards {
    display: grid;
    gap: 1.25rem;
    grid-template-columns: repeat(1, 1fr);

    @include tablet-landscape {
        grid-template-columns: repeat(4, 1fr);
    }

    &__item {
        position: relative;
        text-decoration: none;
        display: flex;
        padding: 1rem;
        flex-direction: column;
        color: $white;
        justify-content: end;
        aspect-ratio: 330/200;

        &:visited,
        &:active,
        &:hover {
            color: $white;
        }

        @include tablet-landscape {
            aspect-ratio: 280/330;
        }
    }

    &__image {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
        object-fit: cover;
        z-index: -1;
        transition: all $transition-fast;

        &::before {
            content: "";
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            background: linear-gradient(
                180.09deg,
                rgba(0, 26, 11, 0) 50%,
                rgba(0, 26, 11, 0.4) 93.06%
            );
        }
    }

    &__title {
        font-size: 1.5rem;
    }

    &__link {
        font-size: 0.875rem;
        margin-top: 0.5rem;
        display: flex;
        align-items: center;
        gap: 1rem;
        transition: all $transition-fast;
    }

    &__item:hover &__image {
        filter: brightness(0.9);
    }

    &__item:hover &__link {
        gap: 1.5rem;
    }
}
