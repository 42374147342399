// IMPORT BULMA
@import "bulma/sass/utilities/_all.sass";
@import "bulma/sass/grid/columns";
@import "bulma/sass/elements/image.sass";


// BULMA ADDITIONS
.columns {
  &.is-tablet-landscape {
    display: block;

    & > .column {
      width: 100%;
    }
  }

  @include tablet-landscape {
    &.is-tablet-landscape {
      display: flex;

      & > .column.is-one-fifth {
        width: 20%;
      }
    }
  }
}
