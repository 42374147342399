// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------

/*
Buttons

Style guide: 4-components.buttons
 */

[class^="text-button__"],
[class*=" text-button__"] {
    @include on-event {
        color: $green;
    }
}

/*
Text Buttons - arrow

Basic styles for text buttons

:hover - hover state

Markup:
<a href="#" class="text-button__arrow {{modifier_class}}">Body Link Halyard Text Book 16/24</a>
<a href="#" class="text-button__arrow is-inversed {{modifier_class}}" style="background: #999">Body Link Halyard Text Book 16/24</a>

Style guide: 4-components.buttons.text-buttons-arrow
 */
.text-button__arrow {
    @include has-arrow-icon-left;

    &.is-green {
        background-color: $green;
        color: $white;
        padding-top: 8px;
        padding-bottom: 12px;
        padding-left: 3em;
        padding-right: 16px;

        &::before {
            top: calc(0.25em + 8px);
            left: 1em;
            background-image: url("/web/assets/icons/arrow-white.svg");
        }

        &::after {
            top: calc(0.25em + 8px) !important;
        }

        @include on-event {
            &::after {
                left: 1em;
            }
        }
    }
}

/*
Text Buttons - uppercase

:hover - hover state

Markup:
<a href="#" class="text-button__uppercase {{modifier_class}}">Body Link Halyard Text Book 16/24</a>

Style guide: 4-components.buttons.text-buttons-uppercase
 */
.text-button__uppercase {
    @extend h5;
    color: $orange;
    text-decoration: underline;

    @include on-event {
        text-decoration: underline;
    }

    &.has-white-text {
        color: $white;

        @include on-event {
            color: $green;
        }
    }
    &--underline {
        text-decoration: underline;
    }
}

/*
Block buttons

:hover - hover state
.is-disabled - button disabled
.is-wide - more padding

Markup:
<a href="#" class="button {{modifier_class}}">Read More</a>
<a href="#" class="button is-transparent {{modifier_class}}">Read More</a>

Style guide: 4-components.buttons.block-buttons
 */

.button,
input[type="button"] {
    @extend h5;
    background: $orange;
    border: 1px solid $orange;
    display: inline-block;
    color: $white;
    text-align: center;
    text-decoration: none;
    padding: 8px 16px;

    &.button--green {
        background-color: $green;
        border: 1px solid $green;

        @include on-event {
            background-color: darken($color: $green, $amount: 10%);
            border-color: darken($color: $green, $amount: 10%);
        }
    }

    &.button--green-main {
        background: $green-main;
        border: 1px solid $green-main;

        @include on-event {
            background-color: darken($color: $green-main, $amount: 10%);
            border-color: darken($color: $green-main, $amount: 10%);
        }
    }

    &:hover {
        cursor: pointer;
    }

    &.has-transition {
        transition: background-color $transition-fast ease-in-out,
            border-color $transition-fast ease-in-out;
    }

    @include on-event {
        background: $green;
        border-color: $green;
        color: $white;
    }

    &.is-transparent {
        background: transparent;
        color: $orange;

        &.has-transition {
            transition: color $transition-fast ease-in-out,
                border-color $transition-fast ease-in-out;
        }

        @include on-event {
            background: transparent;
            color: $green;
        }
    }

    &.less-padding {
        padding: 0.5em;
    }

    &.is-disabled {
        background: $grey;
        border-color: $grey;
        cursor: default;
        pointer-events: none;

        &.is-transparent {
            background: transparent;
            color: $grey;
        }
    }

    &.is-wide {
        padding-left: 4 * $padding-size;
        padding-right: 4 * $padding-size;
    }

    &.is-full-width {
        display: block;
        padding-left: 0;
        padding-right: 0;
    }

    &.is-full-width-mobile {
        @include until-desktop {
            display: block;
            padding-left: 0;
            padding-right: 0;
        }
    }

    &.w-100\% {
        width: 100%;
    }
}

.button--load-more {
    line-height: 1;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 1em;
    padding-top: 1em;
    width: calc(8 / 12) * 100%;

    @include mobile {
        width: 248px;
    }

    @include tablet-landscape {
        padding-bottom: 0.75em;
        padding-top: 0.75em;
        width: 25%;
    }
}

.c-button-solid {
    display: inline-flex;
    min-width: fit-content;
    align-items: center;
    transition: background-color 0.3s, border-color 0.3s;
    background-color: $orange;
    border: 1px solid $orange;
    color: $white;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 16px;
    padding-right: 16px;
    text-decoration: none;
    cursor: pointer;
    font-weight: 500;
    font-size: 18px;
    font-family: $title-font-stack;

    @include tablet-landscape {
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 24px;
        padding-right: 24px;
    }

    @include on-event {
        color: $white;
        background-color: darken($color: $orange, $amount: 10%);
        border-color: darken($color: $orange, $amount: 10%);
    }

    &__arrow {
        display: inline-block;
        margin-right: 1rem;
        height: 1rem;
        width: 1rem;
        padding: 0 0.75rem;
        position: relative;

        @include arrow-glider;
    }

    &:hover {
        .c-button-solid__arrow {
            @include arrow-glider-hover;
        }
    }

    &--green {
        background-color: $green;
        border: 1px solid $green;

        @include on-event {
            background-color: darken($color: $green, $amount: 10%);
            border-color: darken($color: $green, $amount: 10%);
        }
    }

    &--black {
        background-color: $black;
        border: 1px solid $black;

        @include on-event {
            background-color: lighten($color: $black, $amount: 20%);
            border-color: lighten($color: $black, $amount: 20%);
        }
    }

    &:disabled {
        background-color: $grey;
        border-color: $grey;
        cursor: default;
        pointer-events: none;
    }

    &--small {
        font-size: 16px;
    }
    &--reverse {
        .c-button-solid__arrow {
            @include arrow-glider(left);
        }
        &:hover {
            .c-button-solid__arrow {
                @include arrow-glider-hover(left);
            }
        }
    }

    &--fit-content {
        width: fit-content;
    }
}

.c-button {
    color: $black;
    border: $grey 1px solid;
    border-radius: 6px;
    padding: 8px 16px;
    font-family: $title-font-stack;
    font-size: 18px;
    background-color: transparent;
    font-weight: 200;

    @include on-event {
        cursor: pointer;
        transform: translateY(-1px);
    }

    &:active {
        transform: translateY(1px);
    }

    &--active {
        background-color: $black;
        color: $white;
        border-color: $black;

        @include on-event {
            transform: translateY(0);
        }
    }
}

.c-load-more {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 24px;

    hr {
        display: none;

        @include tablet-landscape {
            display: block;
        }
    }
}
