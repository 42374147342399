.link-list {
  &__item {
    &:not(:last-child) {
      margin-bottom: $padding-size;
    }
  }
  &__link {
    @include has-arrow-icon-left;
  }

  &--two-columns {
    @include tablet {
      grid-template-columns: repeat(2, 1fr);
      display: grid;
      column-gap: $gutter;
    }
  }
}
