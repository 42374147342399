/*
CTA Icon block

Markup:
<div class="cta-icon-block">
  <div class="rfh-leafs is-icon">
    <div class="leaf leaf--right"></div>
    <div class="leaf leaf--left"></div>
  </div>
  <div class="cta-icon-block__text">
    <div>
      Nieuw bij Royal FloraHolland?
    </div>
    <a href="#" class="text-button__uppercase">
      Word aanvoerder
    </a>
  </div>
</div>

Style guide: 4-components.cta-icon
*/

/*
CTA Icon block - list

Icon block with multiple links

Markup:
<div class="cta-icon-block">
  <div class="rfh-leafs is-icon">
    <div class="leaf leaf--right"></div>
    <div class="leaf leaf--left"></div>
  </div>
  <div class="cta-icon-block__text">
    <div>
      Nieuw bij Royal FloraHolland?
    </div>
    <a href="#" class="text-button__uppercase">
      Word aanvoerder
    </a>
    <a href="#" class="text-button__uppercase">
      Word aanvoerder
    </a>
    <a href="#" class="text-button__uppercase">
      Word aanvoerder
    </a>
  </div>
</div>

Style guide: 4-components.cta-icon-2
*/

.cta-icon-block {
  display: flex;

  .cta-icon-block__text {
    padding-left: 16px;
    line-height: 1.3;

    a {
      display: block;
    }
  }
}
