/*
Breadcrumbs

Markup:
<div class="breadcrumb-list">
  <a class="breadcrumb-item">Homepage</a>
  <a class="breadcrumb-item">Pillar page</a>
  <a class="breadcrumb-item">Grouping page</a>
  <a class="breadcrumb-item">
    <span class="breadcrumb-item__back-to">Back to</span>
    Info page
  </a>
  <span class="breadcrumb-item">Detail page</span>
</div>

Style guide: 4-components.breadcrumbs
*/

.breadcrumb-list {
    padding-top: 24px;
    list-style-type: none;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    &--updates {
        padding-top: 16px;
        &::before {
            content: "";
            width: 14px;
            height: 14px;
            margin-bottom: -2.5px; //aligns with text.
            display: inline-block;
            margin-right: 12px;
            background-image: icon("linked.svg");
            background-size: contain;
        }
    }
}

.breadcrumb-item {
    @extend h5;
    color: $grey-dark;
    display: none;
    font-weight: 300;
    text-decoration: none;

    &--updates {
        display: inline-block;
        color: $black;
        &::after {
            content: ")";
            display: inline-block;
            margin: 0 4px;
        }
    }

    &--active {
        color: $black;
        font-weight: 700;
        &::after {
            content: none;
        }
    }

    &.is-first-parent {
        display: inline-block;
    }

    &--is-white,
    &--is--white:last-child {
        color: $white;
    }
}

.breadcrumb-item__back-to {
    &::before {
        content: "<";
        margin-right: 4px;
    }
}

@include tablet-landscape {
    .breadcrumb-item {
        display: inline-block;

        &::after {
            content: ")";
            display: inline-block;
            margin: 0 4px;
        }
    }

    .breadcrumb-item__back-to {
        display: none;
    }
}

.c-breadcrumbs {
    color: $white;
    display: flex;
    font-size: 12px;
    column-gap: 8px;

    &__item {
        color: currentColor;
        display: flex;
        align-items: center;
    }

    &__link {
        color: currentColor;
        text-decoration: underline;
        display: flex;
        align-items: center;

        &--last {
            font-weight: 700;
            text-decoration: none;
        }

        @include on-event {
            color: currentColor;
            text-decoration: underline;
        }
    }

    &__icon {
        margin-left: 8px;
        width: 6px;
        height: 10px;
        transform: translateY(-3px);
    }

    &--is-black {
        color: $black;
    }

    &--is-white {
        color: $white;
    }
}
