@use "sass:math";

.agenda-index {
  @include basic-flex-box($breakpoint: "tablet-landscape");
  justify-content: space-between;
}

.agenda-index--slim .agenda-index__sidebar {
  display: none;

  @include tablet-landscape {
    display: flex;
  }
}

.agenda-index__sidebar {
  @include basic-flex-item;

  @include tablet-landscape {
    flex: none;
    width: math.div(3, 12) * 100%;
  }
}

.agenda-index__sidebar__year-list {
  padding-bottom: 32px;

  @include tablet-landscape {
    border-bottom: 1px solid $black;
  }
}

.agenda-index__sidebar__month-list {
  @include basic-flex-box($breakpoint: none, $multiline: true);
  padding-bottom: 42px;

  @include tablet-landscape {
    padding-top: 2px;
    padding-bottom: 0;
    flex-direction: column;
  }
}

.agenda-index__sidebar__month-item {
  @include basic-flex-item($columns: 4);

  @include tablet-landscape {
    width: auto;
  }
}

.agenda-index__sidebar__month {
  @extend h6;

  border-top: 1px solid $black;
  display: block;
  color: $grey-dark;
  font-weight: 300;
  text-align: center;
  text-decoration: none;
  padding-bottom: 18px;
  padding-top: 8px;
  font-size: 13px;

  &.is-active {
    color: $black;
    font-weight: 700;
  }

  &.is-disabled {
    color: $grey;
  }

  @include tablet-landscape {
    border-top: none;
    padding-bottom: 6px;
    padding-top: 6px;
    text-align: left;
    font-size: 12px;
  }
}

.agenda-index__main {
  @include basic-flex-item;

  @include tablet-landscape {
    flex: none;
    width: math.div(8, 12) * 100%;
  }
}

.agenda-index__content-list__item {
  padding-bottom: 75px;
}

.agenda-index__content-list__image {
  background: $grey;
  padding-top: math.div(220, 380) * 100%;
  position: relative;
  width: 100%;

  @include tablet-landscape {
    padding-top: math.div(504, 872) * 100%;
  }
}

.agenda-index {
  &__title {
    margin-top: 15px;
    margin-bottom: 4px;
  }
  &__description {
    margin-bottom: 8px;

    @include tablet-landscape {
      margin-bottom: 4px;
    }
  }

  &__link {
    margin-top: $padding-size;
  }
}
