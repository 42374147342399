@use "sass:math";

* {
  -webkit-overflow-scrolling: touch;
}

.section-content-blocks-scrollable {
  padding-bottom: $padding-size;

  @include tablet-landscape {
    padding-top: $padding-size * 6;
    padding-bottom: $padding-size * 6;
  }
}

.scrollable-content {
  position: relative;
  display: flex;
  flex-direction: column;

  :target {
    scroll-margin-top: calc($navigation-mobile-height + 80px);

    @include tablet-landscape {
      scroll-margin-top: calc($navbar-height + 20px);
    }
  }

  &--centered {
    justify-content: center;
  }

  @include tablet-landscape {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  &__navigation {
    order: -1;
    background-color: $grey-light;
    overflow: scroll;
    padding: $padding-size 0;
    scroll-behavior: smooth;
    z-index: 2;

    &::-webkit-scrollbar {
      display: none;
    }

    &--sticky {
      top: $navigation-mobile-height;
      position: sticky;
    }

    &--hidden {
      display: none;
    }

    &--white {
      background-color: $white;
    }

    @include full-width-element($until: "tablet", $hasCenteredContainer: true);

    @include tablet-landscape {
      order: 0;
      top: calc($navigation-height + 24px);
      white-space: inherit;
      @include flex-percentage(12, 3);
      overflow: visible;
    }
  }

  &__navigation-list {
    display: flex;
    justify-content: space-between;

    @include tablet-landscape {
      flex-direction: column;
      width: auto !important;
      padding: 0;
    }
  }

  &__navigation-item {
    $navigation-item-line-height: 20;
    flex: 1;
    border-top: 1px solid black;
    opacity: 0.3;
    // text-decoration: none;
    // text-transform: uppercase;
    // font-family: $micro-font-stack;
    font-size: 13px;
    min-width: math.div(100, 6) * 4%;
    line-height: $navigation-item-line-height + px;
    min-height: $navigation-item-line-height * 2 + px;
    padding-top: 4px;
    font-weight: 300;

    & > * {
      font-weight: 300;
    }

    &--active,
    &--active > * {
      opacity: 1;
      font-weight: 700;
    }

    &:not(:last-child) {
      margin-right: $padding-size;

      @include tablet-landscape {
        margin-right: 0;
      }
    }

    @include tablet-landscape {
      min-width: auto;
      min-height: auto;
      font-size: 12px;
      margin-bottom: $padding-size * 2;
    }

    &:hover {
      cursor: pointer;
    }
  }

  &__navigation-title {
    text-decoration: none;
    text-transform: uppercase;
    font-family: $micro-font-stack;
    display: inline-block;
    width: 100%;
  }

  &__content {
    width: 100%;
    padding-top: $padding-size * 3;

    @include tablet-landscape {
      @include flex-percentage(12, 8);

      &--medium {
        @include flex-percentage(12, 6);
      }

      &--small {
        @include flex-percentage(12, 4);
      }
    }
    @include tablet {
      padding-top: 0;
    }
  }

  &__block {
    display: none;

    &--active {
      display: flex;
      flex-direction: column;
    }
  }
}
