.emergency-banner__section {
  position: relative;
  background-color: $danger;
  margin-left: auto;
  margin-right: auto;
  max-width: $widescreen;
  padding: 0.75rem $section-outer-padding--mobile;
  width: 100%;
}

.emergency-banner {
  align-items: center;
  display: flex;

  @include tablet {
    justify-content: flex-start;
  }
  &__icon {
    height: 38px;
    width: 38px;
  }

  &__content {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 0 1.125rem 0 0.75rem;

    .paragraph {
      @include limit-lines(2);
    }
  }

  &__cta {
    align-items: center;
    display: flex;
    text-decoration: none;

    &::after {
      background-image: icon("emergency-arrow.svg");
      background-size: cover;
      content: "";
      height: 14px;
      margin: 0 0 0 9px;
      width: 20px;
    }
  }

  &__close {
    background: transparent;
    border: none;
    color: $white;
    cursor: pointer;
    font-size: calcEm(22px);
    font-weight: 600;
    margin-right: $padding-size;
    margin-top: $padding-size;
    position: absolute;
    right: 0;
    top: 0;
  }
}
