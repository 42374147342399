$component: 'c-photo-divider';

.#{$component} {
  @include full-grid;

  &__content {
    grid-column: main-start / main-end;
    margin-bottom: 32px;
  }
  &__image-container {
    width: 100%;
    aspect-ratio: 331 / 200;
    overflow: hidden;
    & > *, img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__background {
    width: 100dvw;
    aspect-ratio: 375 / 200;
    position: absolute;
    left:0;
    bottom:0;
    z-index: -1;
  }
  @include tablet {
    &__content {
      margin-bottom: 40px;
    }
    &__image-container {
      aspect-ratio: 1130 / 451;
    }
    &__background {
      aspect-ratio: 1440 / 409;
      max-height: 409px;
    }
  }
  @include widescreen {
    &__content {
      grid-column: 3 / span 10;
    }
  }
}
