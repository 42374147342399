// ====================
// Scroll Out transions
// ====================
[data-scroll] {
  transition: opacity $transition-slow, transform $transition-slow;
}
[data-scroll="in"] {
  opacity: 1;
  transform: translateY(0);
}
[data-scroll="out"] {
  opacity: 0;
  transform: translateY(50%);
}

// =====================
// VUE NAMED TRANSITIONS
// =====================
.fade-enter-active,
.fade-leave-active {
  transition: opacity $transition-fast;
}

.fade-slow-enter-active,
.fade-slow-leave-active {
  transition: opacity $transition-slow;
}

.fade-enter,
.fade-leave-to,
.fade-slow-enter,
.fade-slow-leave-to {
  opacity: 0;
}

.slide-left-slow-enter-active,
.slide-left-slow-leave-active {
  transition: all $transition-slow;
  transition-timing-function: ease-in-out;
}

.slide-left-slow-enter,
.slide-left-slow-leave-to {
  opacity: 0;
  transform: translateX(-200px);
}
