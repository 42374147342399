.featured-item-block {
  display: flex;
  flex-direction: column;
  padding: 50px 0;

  @include tablet {
    align-items: center;
    flex-direction: row;
    padding: 120px 0;
  }

  &--white {
    background: $white;
    margin: -3rem -3.75rem;
    padding: 50px 3.75em;

    @include tablet {
      padding: 120px 3.75em;
    }
  }

  &--shape {
    background: url("/web/assets/images/featuredItemBlockBg-mobile.svg") no-repeat;
    background-size: cover;
    margin: 7.5rem -3.75rem -4.5rem;
    padding: 100px 3.75em 0 3.75em;

    @include tablet {
      background: url("/web/assets/images/featuredItemBlockBg.svg") no-repeat;
      padding: 120px 3.75em;
    }
  }

  &__media {
    width: 100%;
    @include tablet {
      width: 50%;
    }
  }

  &__content {
    width: 100%;
    @include tablet {
      width: 50%;
    }

    &--left {
      padding: 0 0 20px 0;
      @include tablet {
        padding: 0 100px 0 0;
      }
    }

    &--right {
      @include tablet {
        padding: 0 0 0 100px;
      }
    }
  }
}
