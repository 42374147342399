// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------

/*
Headers

Style guide: 3-layout.2-headers
 */

/*
Header Section

Markup:
<header class="header-section">
  <div class="container">
    <div style="background: #DDD">Header Block</div>
  </div>
</header>

Style guide: 3-layout.2-headers.section
 */

.header-section {
  max-width: $jumbotron;
  margin: 0 auto;
  //overflow: hidden;
  position: relative;
  width: 100%;
}

.header__description {
  font-size: 1.5em;
  line-height: 1.5;

  @include tablet-landscape {
    font-size: 1.25em;
  }
}
