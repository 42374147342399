.card-index {
  $gutter-row: 80px;
  padding-bottom: -$gutter-row;
  padding-top: $gutter-row;

  @include tablet-landscape {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__item {
    display: flex;
    padding-bottom: $gutter-row;
    animation: slide-up $transition-slower * 2;

    @include tablet-landscape {
      @include flex-percentage(2, 1);
    }
  }

  &--three-columns &__item {
    @include tablet-landscape {
      @include flex-percentage(3, 1);
    }
  }
}

.l-list-inline-bordered {
  display: grid;
  flex-direction: column;

  @include tablet-landscape {
    grid-template-columns: repeat(3, 1fr);
    flex-direction: row;
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: 32px;

      @include tablet-landscape {
        margin-bottom: 0px;
      }
    }

    @include tablet-landscape {
      padding-bottom: 20px;
      padding-left: 24px;
      padding-right: 24px;

      &:not(:first-child) {
        border-left: $border-standard;
      }
    }
  }
}

.c-checkmark-list {
  display: flex;
  row-gap: 16px;
  flex-direction: column;

  &__item {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 14px;
    font-weight: 300;
  }

  &__icon {
    color: $green;
    display: block;
    margin-right: 8px;
    transform: translateY(2px);

    &--greyed-out {
      color: $grey;
    }
  }
}

.l-list {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  padding-right: 24px;
  padding-bottom: 20px;
  padding-left: 24px;

  &__item {
    &:not(:first-child) {
      padding-top: 16px;
    }

    &:not(:last-child) {
      padding-bottom: 16px;
    }
  }

  &--bordered {
    border: $border-standard-2023;
    border-radius: $border-radius-standard;
  }

  &--bordered &__item:not(:last-child) {
    border-bottom: $border-standard-2023;
  }
}

.l-list-inline {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 12px;
}
