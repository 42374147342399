/*
Pill

Markup:
<div class="pill">
  pill text
</div>

Style guide: 4-components.pill
*/
.pill {
  display: inline-block;
  background: $grey;
  border: 1px solid $grey;
  border-radius: 12px;
  line-height: 1.5;
  margin-bottom: 6px;
  margin-top: 6px;
  padding-left: 10px;
  padding-right: 10px;
  text-decoration: none;

  &--red {
    color: $white;
    border: 1px solid $danger;
    background-color: $danger;
  }
  &--green {
    color: $white;
    border: 1px solid $green;
    background-color: $green;
  }
  &--grey-dark {
    color: $white;
    border: 1px solid $grey-dark;
    background-color: $grey-dark;
  }

  &.is-small,
  &--small {
    @extend h6;
    font-weight: 300;
    margin-bottom: 4px;
    margin-top: 4px;
    padding-left: 8px;
    padding-right: 8px;
  }

  &--clickable {
    cursor: pointer;
  }

  &.is-active {
    background: $white;
    border-color: $white;
  }

  &--close {
    margin: 0;
    padding-top: 2px;
    padding-bottom: 2px;
    @extend h5;
    font-weight: 300;
    padding-right: 30px;
    position: relative;
    svg {
      position: absolute;
      top: 50%;
      right: 11.5px; //weird antialiasing thing
      height: 12px;
      width: 12px;
      transform: translateY(-50%);
    }
    // pill close styling
    @include until-tablet-landscape {
      display: inline-block;
      background: $grey;
      border: 1px solid $grey;
      border-radius: 12px;
      line-height: 1.5;
      padding-left: 10px;
      padding-right: 32px;
      text-decoration: none;
      background: $white;
      border-color: $white;
      svg {
        display: block;
      }
    }
    // transparent grey button styling
    @include tablet-landscape {
      color: $grey-dark;
      background: transparent;
      border: none;
      text-align: left;
      padding: 0.5em 2 * $padding-size;
      padding-left: 0;
      font-weight: normal;
      svg {
        display: none;
      }
      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}

/*
Pill List

Markup:
<div class="pill-list">
  <div class="pill-list__item">
    <div class="pill">
      pill text
    </div>
  </div>
  <div class="pill-list__item">
    <div class="pill">
      pill text
    </div>
  </div>
</div>

Style guide: 4-components.pill-list
*/
.pill-list {
  @include basic-flex-box($padding: 4px, $breakpoint: "none", $multiline: true);
  align-items: center;

  &__title {
    display: block;
    width: 100%;
    @include basic-flex-item($padding: 4px, $flex: 1 0 100%);
  }
  &__item {
    @include basic-flex-item($padding: 4px, $flex: "none");
    line-height: 0;
  }
}

.c-pills {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  &__item {
    color: $white;
    background-color: $purple;
    color: $white;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 4px;
    padding-bottom: 5px;
    font-weight: 400;
    font-family: $title-font-stack;
    letter-spacing: 0.03em;
    text-decoration: none;

    @include on-event {
      color: $white;
    }

    &--orange {
      background-color: $orange;
    }

    &--small {
      font-size: 12px;
      padding-left: 6px;
      padding-right: 6px;
      padding-top: 3px;
      padding-bottom: 4px;
    }

    &--clickable {
      cursor: pointer;

      @include on-event {
        background-color: $purple-on-hover;
      }

      &:active {
        background-color: $purple;
      }
    }

    &--clickable#{&}--orange {
      @include on-event {
        background-color: $orange-on-hover;
      }

      &:active {
        background-color: $orange;
      }
    }
  }
}

.c-tag {
  display: flex;
  padding: 5px 8px;
  align-items: center;
  border-radius: $border-radius-standard;
  border: 1px solid $black;
  background-color: $grey-light;
  font-size: 12px;
  width: fit-content;

  &--red {
    border: 1px solid $red-main;
    background-color: $red-200;
  }

  &--orange {
    border: 1px solid $orange-main;
    background-color: $orange-200;
  }

  &--green {
    border: 1px solid $green-main;
    background-color: $green-200;
  }
}
