
            @import "@/styles/abstracts/all.scss";
          

.c-tarif-title {
    margin-bottom: 16px;
    padding-left: 16px;
    padding-right: 16px;

    @include tablet {
        padding-left: 0;
        padding-right: 0;
    }
}

.c-tarifs-results {
    font-size: 14px;

    &__header-row {
        height: 16px;
        background-color: $green-main;
        color: $white;

        @include tablet {
            display: flex;
            height: auto;
            padding: 16px;
            border-top-left-radius: $border-radius-standard;
            border-top-right-radius: $border-radius-standard;
        }
    }

    &__header-column {
        display: none;

        @include tablet {
            display: flex;
            font-weight: 600;
        }
    }

    &__body-column {
        &--description {
            display: flex;
            align-items: center;

            @include tablet-landscape {
                display: inline-block;
            }
        }
    }

    &__footnote {
        margin-left: auto;

        svg {
            transform: translateY(3px);
        }

        @include tablet {
            display: none;
            margin-left: 8px;

            &:hover {
                cursor: pointer;
            }
        }

        &--filled {
            @include tablet {
                display: inline-block;
            }
        }
    }

    &__body-row {
        display: flex;
        flex-wrap: wrap;
        row-gap: 8px;
        padding: 16px;
        background-color: $white;
        justify-items: space-between;
        border-bottom: $border-medium;

        &:nth-child(odd) {
            background-color: $green-100;
        }

        @include tablet {
            flex-wrap: nowrap;
            border-left: $border-medium;
            border-right: $border-medium;

            &:last-child {
                border-bottom-left-radius: $border-radius-standard;
                border-bottom-right-radius: $border-radius-standard;
            }
        }
    }

    &__body-row,
    &__header-row {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        column-gap: 12px;

        @include tablet {
            grid-template-columns: 5fr 100px 100px 7fr;
        }
    }

    &__body-column,
    &__header-column {
        &--description {
            grid-column: 1 / -1;

            @include tablet {
                grid-column: auto;
                order: 0;
            }
        }

        &--price {
            justify-self: end;

            @include tablet {
                justify-self: start;
                order: 1;
            }
        }

        &--code {
            @include tablet {
                order: 2;
            }
        }

        &--explanation {
            display: none;

            @include tablet {
                display: block;
                order: 3;

                p {
                    display: inline;
                    margin-right: 4px;
                }

                button {
                    display: inline;
                }
            }
        }
    }
}

.c-tarif-modal {
    position: relative;
    width: 90vw;
    background-color: $white;
    padding: 16px;
    border: $border-medium;
    border-radius: $border-radius-standard;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    max-width: 555px;

    &__close {
        position: absolute;
        top: 16px;
        right: 16px;
    }

    &__header {
        display: grid;
        grid-template-columns: repeat(2, auto);
        justify-content: start;
        row-gap: 16px;
        column-gap: 8px;
        padding-bottom: 16px;
    }

    &__header-title {
        grid-column: 1 / -1;
        padding-right: 24px;
    }

    &__body {
        padding-top: 16px;
        border-top: $border-medium;
        margin-bottom: 16px;
    }

    &__footer {
        padding-top: 16px;
        border-top: $border-medium;
    }

    &__header-info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 16px;
    }
}
