.vacancy-row {
  @include full-grid-with-column-gap;
  position: relative;
  z-index: 2;
  // transform: translateY(-60px);
  margin-top: -60px;

  &__container {
    grid-template-columns: repeat(1, 1fr);
    column-gap: 24px;
    display: grid;
    align-self: stretch;
    gap: 0.5rem;
    grid-column: main-start/main-end;

    @include tablet-landscape {
      grid-column: 2 / span 12;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 1.5rem;
    }

    @include widescreen {
      grid-column: 3 / span 10;
    }

    &:nth-child(2) {
      display: none !important;
    }
  }
}

.vacancy {
  width: 100%;
  background-color: $green-100;
  color: $green-dark;
  padding: 1.5rem;
  padding-right: 3rem;
  border: 1px solid $green-light;
  border-radius: $border-radius-standard;
  text-decoration: none;
  gap: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
  transition: $transition-slow all;
  background: linear-gradient(
    48deg,
    $green-200 0%,
    $green-200 35%,
    $green-100 50%
  );
  background-size: 300% 300%;
  background-position: 100% 100%;

  &:hover {
    background-position: 0% 100%;
  }

  &__info {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    font-size: 14px;
  }

  &__text {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    line-height: 1.5;
    white-space: nowrap;
  }

  &__button {
    position: absolute;
    background-color: $green;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 0;
    overflow: hidden;
    right: 0;
    padding-left: 1rem;
    padding-right: 1rem;
    height: 40px;
  }

  &__button-label {
    display: none;

    @include tablet-landscape {
      display: block;
      margin-left: 8px;
    }
  }

  &--has-transition {
    transition: $transition-slow transform ease-out,
      $transition-slow opacity ease-out,
      $transition-fast background-position ease-out;
    opacity: 0;
    transform: translateY(40%);

    .loaded & {
      transform: translateY(0);
      opacity: 1;
    }

    @for $x from 1 through 3 {
      &:nth-child(#{$x}) {
        transition-delay: 200ms * $x, 200ms * $x, 0s;
      }
    }
  }
}

.vacancy-banner {
  display: flex;
  border-radius: $border-radius-standard;
  overflow: hidden;

  &--white {
    background-color: $white;
    border: 1px solid $grey;
  }

  &--purple-200 {
    background-color: $purple-200;
  }

  &--green-200 {
    background-color: $green-200;
  }

  &__text {
    width: 150%;
    text-align: center;
    padding: 1.5rem;
  }

  &__visual {
    display: none;
  }

  @include tablet-landscape {
    &__text {
      width: 45%;
      text-align: left;
      padding: 2rem;

      &--overview {
        width: 55%;
      }
    }

    &__visual {
      display: block;
      width: 55%;
      position: relative;
      aspect-ratio: revert;
      mask-image: svg("vacancies/leaf-mask.svg");
      min-height: 150%;
      mask-size: cover;

      &--overview {
        width: 45%;
      }
    }
  }

  @include mobile {
    &__text {
      padding: 2rem;
    }
  }

  &__image {
    @include object-fit;
  }
}

.vacancy-header-bar {
  &__wrapper {
    transition: all $transition-fast;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 4;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      #ffffff 72.54%
    );
    opacity: 0;
    transform: translateY(100%);
  }

  &:not(.in-view).loaded &__wrapper {
    opacity: 1;
    transform: translateY(0);
  }

  &__title,
  &__details {
    display: none;
  }

  &__content {
    display: flex;
    justify-content: center;
    padding: 1.5rem 0;
    grid-column: 1 / -1;
  }

  @include tablet-landscape {
    &__wrapper {
      background: transparent;
      bottom: auto;
      top: calc($navbar-height - 2px);
      transform: translateY(-100%);
    }

    &__container {
      background-color: $green-dark;
      color: $white;
      grid-column: side;
      @include full-grid;
    }

    &__content {
      grid-column: 2 / -2;
      display: flex;
      align-items: center;
      padding: 1.5rem 0;
      gap: 3rem;
      justify-content: space-between;

      @include widescreen {
        grid-column: 2 / -2;
      }
    }

    &__title {
      display: block;
      font-size: calcEm(26px);
      font-weight: 600;
    }

    &__details {
      display: flex;
      flex-wrap: wrap;
      gap: 0.5rem 1.5rem;
      font-size: 1.1rem;
      flex-grow: 1;
    }

    &__detail {
      display: flex;
      gap: 0.5rem;
      align-items: center;
    }
  }
}

.jobs-bar {
  @include full-grid-with-column-gap;
  margin-top: -20px;
  row-gap: 24px;

  @include tablet-landscape {
    margin-top: -60px;
    grid-auto-rows: 1fr;
  }

  &__searchbar {
    z-index: $c-video-header-index + 1;
    grid-column: main-start / main-end;

    @include tablet-landscape {
      grid-column: 2 / 10;
    }

    @include widescreen {
      grid-column: 3 / 9;
    }

    &--nocta {
      @include tablet-landscape {
        grid-column: 2 / 14;
      }

      @include widescreen {
        grid-column: 3 / 13;
      }
    }
  }

  &__cta {
    z-index: $c-video-header-index + 1;
    gap: 0.5rem;
    display: flex;
    flex-direction: column;
    grid-column: main-start / main-end;

    @include tablet-landscape {
      grid-column: 10 / 14;
    }

    @include widescreen {
      grid-column: 9 / 13;
    }
  }

  &__title {
    font-weight: 600;
    font-size: 21px;
    line-height: 1;
  }

  &__description {
    font-size: 0.875rem;
  }

  &__button {
    margin-top: 1rem;
  }

  &__pills {
    display: flex;
    margin-top: 0.5rem;
    gap: 0.66rem;
    flex-wrap: wrap;
  }

  &__pill {
    background-color: $green-dark;
    color: $white;
    padding: 0.5rem 0.8rem;
    text-decoration: none;

    @include tablet-landscape {
      font-size: 0.8rem;
    }

    @include on-event {
      background-color: darken($green-dark, 10%);
      color: $white;
    }

    &--orange {
      background-color: $orange;

      @include on-event {
        background-color: darken($orange, 10%);
      }
    }
  }
}
