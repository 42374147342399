.c-image-banner {
    @include full-grid;
    align-items: end;
    margin-bottom: 40px;

    @include tablet-landscape {
        margin-bottom: 0px;
    }

    &__image {
        position: relative;
        grid-column: side-start/side-end;
        grid-row: 1 / span 2;
        aspect-ratio: 375/263;

        @include tablet-landscape {
            min-height: 480px;
            aspect-ratio: auto;
            grid-row: 1 / span 3;
        }
        
        img {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;  
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    
    &__content {
        z-index: 2;
        grid-column: main-start/main-end;
        grid-row: 2 / span 2;
        transform: translateY(40px);

        @include tablet-landscape {
        transform: none;
         grid-column: main-start / span 6;
         grid-row: 2 / span 1;
        }
        
        @include widescreen {
            grid-column: 3 / span 5;
        }
    }

    &--hasCardRight &__content {
        @include tablet-landscape {
            grid-column: span 6 / main-end;
        }

        @include widescreen {
            grid-column: span 5 / -3;
        }
    }
}
