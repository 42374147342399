.scroll-cards-block {
  &__slider {
    height: auto;
    margin-top: 50px;
  }

  .swiper-slide {
    height: auto;
    width: auto;
    display: flex;
  }

  .swiper-container {
    position: relative;
    padding-bottom: 4em;
  }

  .swiper-scrollbar {
    z-index: 3;
  }

  .swiper-scrollbar-drag {
    background-color: $purple-main;
  }

  &--green-main {
    .swiper-scrollbar-drag {
      background-color: $green-main;
    }
  }
}

.scroll-card {
  background-color: $white;
  border-radius: $border-radius-standard;
  border: 1px solid $purple-light;
  width: 210px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  float: left;
  overflow: hidden;

  &__text {
    padding: 1.5rem;
  }

  &__number {
    font-size: 3.5rem;
    font-weight: 600;
  }

  &__subtitle {
    font-weight: 300;
    font-size: 1.3rem;
  }

  &__image {
    width: 100%;
    overflow: hidden;
    margin: 0 auto;
  }

  &--purple-200 {
    background-color: $purple-200;
    border-color: $purple-200;
  }

  &--purple-200 &__number {
    color: $purple-main;
  }

  &--green-200 {
    background-color: $green-200;
    border-color: $green-200;
  }

  &--green-200 &__number {
    color: $green-main;
  }
}
