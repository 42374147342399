
            @import "@/styles/abstracts/all.scss";
          

.c-results-list {
    @include basic-reset;

    &__item {
        animation-name: slideInFromRight;
        animation-duration: 0.5s;

        &:not(:last-child) {
            border-bottom: $border-standard-2023;
        }
    }

    &__link {
        padding-top: 16px;
        padding-bottom: 16px;
        display: grid;
        grid-template-columns: 1fr auto;
        cursor: pointer;
        text-decoration: none;
        row-gap: 8px;
        column-gap: 16px;
    }

    &__tag {
        grid-row: 1 / 2;
        grid-column: 1 / 2;
    }

    &__title {
        grid-row: 2 / 3;
        grid-column: 1 / 2;

        font-size: 16px;
        font-family: $title-font-stack;
        font-weight: 400;

        @include tablet-landscape {
            font-size: 21px;
        }
    }

    &__arrow {
        grid-row: 2 / 3;
        grid-column: 2 / 3;
        align-self: center;
        justify-self: flex-end;
    }

    &__item:hover &__title {
        @media (hover: hover) {
            text-decoration: underline;
        }
    }

    &__item:hover &__arrow {
        @media (hover: hover) {
            color: $orange;
            animation-name: moveLeftAndBack;
            animation-duration: 0.5s;
        }
    }
}
