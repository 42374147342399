/*
RFH Leafs

Markup:
<div style="background: #DDD">
  <div class="rfh-leafs">
    <div class="leaf leaf--left"></div>
    <div class="leaf leaf--right">
      <img
        class="object-fit"
        src="https://via.placeholder.com/1600x1200"
        alt="image title" />
    </div>
  </div>
</div>

Style guide: 4-components.rfh-leafs
 */

@use 'sass:math';

.rfh-leafs {
  height: 3 * 107px;
  position: relative;
  width: 3 * 122px;
  &--loading {
    margin: 0 auto;
    margin-bottom: 11px;
    @include tablet-landscape {
      margin-bottom: 14px;
    }
    height: 0.5 * 107px;
    width: 0.5 * 122px;
  }

  &--responsive {
    width: 100%;
    height: 0;
    padding-top: 82%;
  }
}
.leaf {
  content: '';
  bottom: 0;
  display: block;
  height: math.div(106, 107) * 100%;
  mask-position: center;
  mask-repeat: no-repeat;
  mask-size: contain;
  position: absolute;
  transition: transform $transition-faster ease-in-out;
  width: math.div(81, 122) * 100%;

  &--left {
    @include when-inside('.rfh-leafs--loading') {
      background: $green-dark;
      animation: leaf-left-clap $transition-slower ease-in-out infinite;
    }
    background: $green;
    left: 0;
    mask-image: image('leaf-left.svg');
    transform-origin: 79% 100%;
  }

  &--right {
    @include when-inside('.rfh-leafs--loading') {
      background: $green;
      animation: leaf-right-clap $transition-slower ease-in-out infinite;
    }
    background: $white;
    mask-image: image('leaf-right.svg');
    right: 0;
    transform-origin: 21% 100%;
  }

  &--green-main {
    background: $green-main;
  }

  &--grey-very-light {
    background: $grey-very-light;
  }

  &--purple-main {
    background-color: $purple-main;
  }
}

.cta-icon-block,
.leaf {
  @include on-event {
    &:not(.has-no-hover) {
      .leaf--left {
        animation: leaf-left-clap $transition-slow ease-in-out;
      }

      .leaf--right {
        animation: leaf-right-clap $transition-slow ease-in-out;
      }
    }
  }
}

/*
RFH Leafs - Icon

Markup:
<div style="background: #DDD">
  <div class="rfh-leafs is-icon">
    <div class="leaf leaf--right"></div>
    <div class="leaf leaf--left"></div>
  </div>
</div>

Style guide: 4-components.rfh-leafs-icon
 */
.rfh-leafs.is-icon {
  height: 21px;
  width: 24px;

  .leaf--left {
    background-color: $green-dark;
  }

  .leaf--right {
    background-color: $green;
  }
}

/*
RFH Leafs - diagonal

Markup:
<div style="background: #DDD">
  <div class="rfh-leafs--diagonal">
    <div class="leaf leaf--left"></div>
    <div class="leaf leaf--right">
      <img
        class="object-fit"
        src="https://via.placeholder.com/1600x1200"
        alt="image title" />
    </div>
  </div>
</div>

Style guide: 4-components.rfh-leafs-diagonal
 */

.rfh-leafs--diagonal {
  position: relative;
  width: 1280px;
  padding-top: 74%;

  @include desktop {
    //padding-top: 65%;
  }

  .leaf {
    transition: transform $transition-slower ease-in-out;

    &.leaf--left {
      background: $white;
      height: math.div(910, 960) * 100%;
      left: 0;
      mask-image: image('leaf-left.svg');
      transform-origin: (math.div(530, 690) * 100%) 100%;
      top: 0;
      width: math.div(690, 1280) * 100%;
    }

    &.leaf--right {
      background: $green;
      bottom: 0;
      height: math.div(765, 960) * 100%;
      mask-image: image('leaf-right--diagonal.svg');
      right: 0;
      transform-origin: (math.div(35, 790) * 100%) (math.div(720, 765) * 100%);
      width: math.div(790, 1280) * 100%;
    }
  }
}

// ===============
// LEAFS ANIMATION
// ===============
@keyframes leaf-left-clap {
  50% {
    transform: rotate(19deg);
  }
  100% {
    transform: rotate(0);
  }
}

@keyframes leaf-right-clap {
  50% {
    transform: rotate(-19deg);
  }
  100% {
    transform: rotate(0);
  }
}

/*
c-leafs

Markup:
<div style="background: #DDD">
    <div class="c-leafs">
        <div class="c-leafs__container">
            <div class="c-leafs__leaf c-leafs__leaf--left"></div>
            <div class="c-leafs__leaf c-leafs__leaf--right"></div>
        </div>
    </div>
</div>

Style guide: 4-components.c-leafs
 */

.c-leafs {
  width: 100%;

  &--max-366 {
    max-width: 366px;
  }

  &__container {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 82%;
  }

  &__leaf {
    content: '';
    bottom: 0;
    display: block;
    height: calc(106 / 107 * 100%);
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: contain;
    position: absolute;
    transition: transform $transition-faster ease-in-out;
    width: calc(81 / 122 * 100%);

    &--left {
      @include when-inside('.rfh-leafs--loading') {
        background: $green-dark;
        animation: leaf-left-clap $transition-slower ease-in-out infinite;
      }
      background: $green;
      left: 0;
      mask-image: image('leaf-left.svg');
      transform-origin: 79% 100%;
      z-index: 3;
    }

    &--right {
      @include when-inside('.rfh-leafs--loading') {
        background: $green;
        animation: leaf-right-clap $transition-slower ease-in-out infinite;
      }
      background: $purple;
      mask-image: image('leaf-right.svg');
      right: 0;
      transform-origin: 21% 100%;
      z-index: 2;
    }

    &--green {
      background: $green;
    }

    &--green-light {
      background: $green-light;
    }

    &--purple-light {
      background: $purple-light;
    }
  }

  &__leaf-image {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  &--animated {
    opacity: 0;
    transition: $transition-slow all ease-out;

    .loaded & {
      opacity: 1;
    }

    .c-leafs__leaf--right {
      transition: 1.5s all cubic-bezier(0.42, 1.635, 0.725, 1.005);
      transform: rotate(-30deg);

      .loaded & {
        transform: rotate(0);
        opacity: 1;
      }
    }
  }

  &--purple-light &__leaf--left {
    background: $purple-main;
  }

  &--purple-light &__leaf--right {
    background: $purple-200;
  }

  &--green-light &__leaf--left {
    background: $green-main;
  }

  &--green-light &__leaf--right {
    background: $green-200;
  }
}
