
            @import "@/styles/abstracts/all.scss";
          

.c-tool {
    @include full-grid;
    padding-bottom: 40px;

    @include tablet-landscape {
        padding-top: 40px;
    }

    &__step {
        display: none;

        &--active {
            display: block;
        }
    }

    &__progression {
        background-color: $green;
        position: absolute;
        left: 0;
        bottom: 0;
        height: 4px;
        width: 0%;
        display: block;
        transition: width 0.3s ease-in-out;
    }

    &__progression-bar {
        position: relative;
        background-color: $grey;
        height: 4px;
        display: block;
        grid-column: side-start/side-end;
        margin-bottom: 28px;

        @include tablet-landscape {
            height: 1px;
            margin-bottom: 40px;
        }
    }

    &__title {
        display: flex;
        margin-bottom: 24px;

        position: relative;
        &::after {
            content: "";
            height: 4px;
            background-color: $green;
            width: 24px;
            position: absolute;
            bottom: -8px;
            left: 0;
            right: 0;

            @include tablet-landscape {
                width: 160px;
                bottom: -12px;
            }
        }
    }

    &__description {
        margin-bottom: 16px;
    }

    &__header {
        margin-bottom: 16px;
        margin-top: 36px;
    }

    &__step-number {
        height: 24px;
        width: 24px;
        color: $white;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        font-family: $title-font-stack;
        background-color: $green;
        margin-right: 8px;

        @include tablet-landscape {
            height: 32px;
            width: 32px;
            font: 21px;
        }
    }
    &__footnote {
        grid-column: 4 / span 8;
        margin-bottom: 48px;
        :deep(p) {
            margin-bottom: 16px;
        }
        :deep(ul) {
            padding-left: 20px;
            list-style: outside;
            margin-bottom: 6px;
        }
        :deep(li) {
            margin-bottom: 10px;
        }
    }
    &__steps {
        grid-column: main-start/main-end;
        margin-bottom: 16px;

        @include tablet-landscape {
            margin-bottom: 24px;
            grid-column: 4 / span 8;
        }

        @include widescreen {
            grid-column: 5 / span 6;
        }
    }

    &__buttons {
        grid-column: main-start/main-end;
        display: flex;
        justify-content: flex-start;
        column-gap: 16px;

        @include tablet-landscape {
            grid-column: 4 / span 8;
        }

        @include widescreen {
            grid-column: 5 / span 6;
        }

        & > button {
            width: 100%;
            flex-grow: 1;
            flex-basis: 0;

            @include tablet {
                width: auto;
                flex-grow: 0;
                flex-basis: auto;
            }
        }
    }

    &__result {
        grid-column: main-start/main-end;
        margin-bottom: 48px;

        @include widescreen {
            grid-column: 3 / -3;
        }
    }
}
