.checkmark-list {
  &__item {
    position: relative;
    padding-left: 2em;

    &:not(:last-child) {
      margin-bottom: $padding-size;
    }

    &::before {
      position: absolute;
      left: 0;
      top: -0.25em;
      content: "\2713";
      font-size: 20px;
    }
  }
}
