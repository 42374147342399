.section-feedback-block {
  padding-top: $padding-size * 4;
  padding-bottom: $padding-size * 8;
}

.feedback-block {
  @include standard-grid;

  &__content {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: $padding-size * 3;
    grid-column: 1 / 7;
    text-align: center;

    @include tablet {
      grid-column: 3 / 11;
    }

    @include tablet-landscape {
      margin-bottom: $padding-size * 2;
      grid-column: 4 / 10;
    }
  }

  &__visual {
    grid-column: 3 / 5;
    margin-bottom: $padding-size * 1.5;

    @include mobile {
      margin: 0 auto;
      width: 50%;
    }

    @include tablet {
      margin: auto;
      width: 100%;
      grid-column: 6 / 8;
    }

    @include tablet-landscape {
      margin: 0 auto;
      width: 50%;
    }
  }

  &__button {
    &--1 {
      grid-column: 1 / 4;

      @include tablet {
        grid-column: 4 / 7;
      }

      @include tablet-landscape {
        grid-column: 5 / 7;
      }

      @include widescreen {
        grid-column: 5 / 7;
      }
    }

    &--2 {
      grid-column: 4 / 7;

      @include tablet {
        grid-column: 7 / 10;
      }

      @include tablet-landscape {
        grid-column: 7 / 9;
      }

      @include widescreen {
        grid-column: 7 / 9;
      }
    }
  }
}
