@use "sass:math";

.section-maxi-header {
  padding-top: 40px;
  padding-bottom: 0px;

  @include tablet-landscape {
    margin-bottom: 0;
  }
}

.maxi-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @include tablet-landscape {
    flex-direction: row;
    justify-content: space-between;
  }

  &__content {
    order: 1;
    align-self: flex-start;

    @include tablet-landscape {
      order: 0;
      width: math.div(100, 12) * 4%;
      margin-top: 50px;
    }
  }

  &__title {
    margin-bottom: 20px;
  }

  &__paragraph {
    font-size: 24px;
    line-height: 32px;
    font-family: $text-font-stack;

    @include tablet-landscape {
      font-size: 20px;
      line-height: 28px;
    }
  }

  &__visual {
    position: relative;
    width: 100%;
    margin-bottom: 32px;
    padding-top: 88%;

    @include tablet-landscape {
      transform: translateX(3%);
      padding-top: 50%;
      margin-bottom: 0;
      width: math.div(100, 12) * 7%;
    }
  }

  &__leaf {
    content: "";
    bottom: 0;
    display: block;
    height: math.div(106, 107) * 100%;
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: contain;
    position: absolute;
    width: math.div(81, 122) * 100%;

    &--left {
      background: $green-dark;
      left: 0;
      mask-image: image("leaf-left.svg");
    }
    &--right {
      background-color: $purple;
      left: 0;
      mask-image: image("leaf-right.svg");
      transform: translateX(50%);
      background-size: cover;
    }
  }
}

.section-usps {
  @include tablet-landscape {
    margin-top: -210px;
  }

  @include widescreen {
    margin-top: -230px;
  }
}
