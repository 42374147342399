.c-highlighted-text {
    @include full-grid;
    position: relative;
    color: $white !important;
    grid-column: 3 / span 5;

    &__image-wrapper {
        position: absolute;
        grid-column: main-start / main-end;
        height: 100%;
        top: 0;
        left: 0;
        z-index: -1;
        right: 0;

        transform: translateY(200px);
        opacity: 0;
        transition: transform $move-in-animation-speed 0.2s,
            opacity $move-in-animation-speed 0.2s;

        &::before {
            @include object-fit;
            content: "";
            background: linear-gradient(
                    0deg,
                    rgba(0, 0, 0, 0) 0%,
                    rgba(0, 0, 0, 0.5) 100%
                ),
                linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
        }
    }

    &__image {
        position: absolute;
        object-fit: cover;
        object-position: center;
        width: 100%;
        height: 100%;
        z-index: -1;
    }

    &__content {
        padding: 120px 28px;
        grid-column: main-start / main-end;
    }

    &__tag {
        font-size: 14px;
        margin-bottom: $u-8;
        transform: translateX(-30px);
        opacity: 0;
        transition: transform $move-in-animation-speed 0.6s,
            opacity $move-in-animation-speed 0.6s;
    }

    &__title {
        margin-bottom: $u-16;
        transform: translateX(-30px);
        opacity: 0;
        transition: transform $move-in-animation-speed 0.8s,
            opacity $move-in-animation-speed 0.8s;
    }
    &__text {
        transform: translateX(-30px);
        opacity: 0;
        transition: transform $move-in-animation-speed 1s,
            opacity $move-in-animation-speed 1s;
    }

    &__button {
        margin-top: $u-16;
        transform: translateX(-30px);
        opacity: 0;
        transition: transform $move-in-animation-speed 1.2s,
            opacity $move-in-animation-speed 1.2s;
    }

    @include tablet {
        &__content {
            padding: 120px 64px;
        }
    }

    @include widescreen {
        &__image-wrapper {
            right: -55px;
            left: -55px;
            grid-column: 3 / span 10;
        }

        &__content {
            padding: 120px 0;
            grid-column: 3 / 8 !important;
        }
    }
    &.in-view {
        .c-highlighted-text {
            &__image-wrapper,
            &__tag,
            &__title,
            &__text,
            &__button {
                transform: translateX(0);
                opacity: 1;
            }
        }
    }
}
