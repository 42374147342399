.navbar {
  position: relative;
  width: 100%;
  max-width: $widescreen;
  margin: 0 auto;
  background-color: $white;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: $navbar-index;

  @include tablet-landscape {
    position: relative;
    height: $navbar-height;
  }

  &__container {
    @include container;

    height: 100%;
    display: flex;
    align-items: center;
    padding-left: $section-outer-padding--mobile;
    padding-right: $section-outer-padding--mobile;

    @include tablet {
      padding-left: 0;
      padding-right: 0;
    }
  }
  &__logo-container {
    height: 53px;

    @include tablet-landscape {
      height: 62px;
    }

    svg {
      height: 100%;
    }
  }

  &__right {
    margin-left: auto;
  }

  &__dropdown {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 100px;

    &--right {
      margin-left: auto;
    }
  }
  &__dropdown-button {
    @include basic-reset;
    cursor: pointer;
  }
  &__dropdown-content {
    display: none;
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translateY(100%);
    background-color: $white;
    padding-right: 60px;
    padding-left: 24px;
    padding-top: 24px;
    padding-bottom: 24px;
  }

  &__dropdown:hover &__dropdown-content,
  &__dropdown-button:active + &__dropdown-content {
    display: flex;
  }

  &__label {
    font-size: 12px;
    font-weight: 300;
    text-transform: uppercase;
  }
}

.navigation-list {
  display: flex;
  flex-direction: column;

  &--sticky {
    position: sticky;
    top: 0;
    padding-top: $padding-size !important;
  }

  &__item {
    padding-top: $padding-size;
    padding-bottom: $padding-size;
    cursor: pointer;
    $navigation-list-default-border: 1px solid
      rgba(
        $color: $black,
        $alpha: 0.3
      );
    border-top: $navigation-list-default-border;

    &:last-child {
      border-bottom: $navigation-list-default-border;
    }

    & > * {
      font-weight: 300;
      opacity: 0.3;

      &:hover {
        color: $green;
        opacity: 1;
      }
    }

    &--active > * {
      font-weight: 700;
      opacity: 1;
    }
  }
}
