.c-multi-column {

  &__block {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 32px;
    flex-wrap: wrap;

    @include tablet-landscape {
      flex-direction: row;
      justify-content: space-between;
      column-gap: 22px;
      flex-wrap: nowrap;
    }
  }

  &__block-content {
    flex: 1 1 0px;
    display: flex;
    flex-direction: column;
  }
}
