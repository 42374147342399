$u-very-tiny: 4px;
$u-tiny: 12px;
$u-small: 24px;
$u-medium: 48px;
$u-big: 96px;

u-object-fit {
    @include object-fit;
}

.u-cover {
    min-width: 100%;
    min-height: 100%;
    height: auto;
    width: auto;
}

@include mobile-only {
    .hidden-mobile {
        display: none;
    }
}

.u-margin-left-medium {
    margin-left: $u-medium;
}

.u-height-auto {
    height: auto !important;
}

.u-half-tablet-landscape {
    @include tablet-landscape {
        @include flex-percentage(2, 1);
    }
}

.u-flex-grow {
    flex-grow: 1;
}

.u-align-center {
    display: flex;
    align-items: center;
}