/*
Most Visited Pages

Mobile: 1 column
Tablet: 2 columns
Tablet landscape and up: 3 columns

Markup:
<div class="most-visited-pages">
  <div class="container">
    <h3>Most visited</h3>
    <ul class="most-visited-pages__list">
      <li class="most-visited-pages__item">
        <a href="#" class="text-button__arrow is-inversed">Famous page 1</a>
      </li>
      <li class="most-visited-pages__item">
        <a href="#" class="text-button__arrow is-inversed">Famous page 2</a>
      </li>
      <li class="most-visited-pages__item">
        <a href="#" class="text-button__arrow is-inversed">Famous page 3</a>
      </li>
      <li class="most-visited-pages__item">
        <a href="#" class="text-button__arrow is-inversed">Famous page 4</a>
      </li>
      <li class="most-visited-pages__item">
        <a href="#" class="text-button__arrow is-inversed">Famous page 5</a>
      </li>
      <li class="most-visited-pages__item">
        <a href="#" class="text-button__arrow is-inversed">Famous page 6</a>
      </li>
    </ul>
  </div>
</div>

Style guide: 4-components.most-visited-pages
 */

.most-visited-pages {
  @extend .section;
  @include when-after('.section-header'){
    margin-top: 48px;
  }
  background: $purple;
  color: $white;
  padding-top: 32px;
  padding-bottom: 16px;
  @include tablet {
    padding-bottom: 32px;
  }

  &__list {
    padding-top: 32px;
    @include tablet {
      @include basic-flex-box($multiline: true);
    }
  }

  &__item {
    display: flex;
    align-items: center;
    height: 48px;
    transform: translateX(1em);

    @include tablet {
      @include basic-flex-item;

      display: flex; // Override display block from basic-flex-item, with a flex to align links vertically on desktop
      flex: none;
      height: 72px;
      width: 50%;
      transform: none;
      align-items: center;
    }

    @include tablet-landscape {
      width: 25%;
    }

    &--featured {
      transform: translateX(0em);

      @include tablet {
        transform: translateX(-1em);
      }
    }
  }

  &__columns {
    padding-top: 32px;
    display: flex;
    flex-direction: column;

    @include tablet-landscape {
      flex-direction: row;
      justify-content: space-between
    }
  }

  &__column {
    @include tablet-landscape {
      @include flex-percentage(2,1);
    }

    &:not(:first-of-type) {
      padding-top: $padding-size;

      @include tablet-landscape {
        padding-top: 0px;
      }
    }
  }

  &__links {
    padding-top: $padding-size;
  }

  &__link {
    height: 48px;

    @include tablet-landscape {
      height: 72px;
    }
  }
}

.homepage .most-visited-pages,
.homepage22 .most-visited-pages {
  @include widescreen {
    margin-top: -140px;
    max-width: 1100px;
    margin-left: calc(50% - ($widescreen / 2));
  }
}
