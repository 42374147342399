.quote-block {
  overflow: visible;

  &--purple {
    color: $purple;
  }

  &--green {
    color: $green-dark;
  }

  &__container {
    grid-template-columns: repeat(10, 1fr);
    display: grid;
  }

  &__flower {
    display: none;

    @include tablet-landscape {
      grid-column: 1 / span 5 !important;
      padding-right: 4rem;
      display: block;
    }
  }

  &__text {
    display: flex;
    justify-content: center;
    grid-column: 1 / main-end;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    opacity: 0;
    transition: $transition-fast all ease-out;

    @include tablet-landscape {
      grid-column: 6 / span 5 !important;
      gap: 2rem;
    }

    .loaded & {
      opacity: 1;
    }
  }

  &__quote {
    font-size: 2rem;
    line-height: 1.875rem;
    font-weight: 600;

    @include tablet-landscape {
      font-size: 2.25rem;
      line-height: 1;
    }

    transition: $transition-slow all ease-out;
    transform: translateY(3rem);

    .loaded & {
      opacity: 1;
      transform: translate(0);
    }
  }

  &__name {
    font-size: 1.125rem;
    color: $black;
  }

  &__name,
  &__button {
    transition: $transition-slow all ease-out;
    transition-delay: $transition-fast;
    transform: translateY(3rem);
    opacity: 0;

    .loaded & {
      opacity: 1;
      transform: translate(0);
    }
  }
}
