.c-cards-slider {
  @include full-grid-with-column-gap;
  row-gap: 24px;

  &--has-cta {
    align-items: end;
  }

  &__content {
    grid-column: main-start / main-end;

    @include tablet-landscape {
      grid-column: main-start / span 5;
      grid-row: 1 / span 1;
    }

    @include widescreen {
      grid-column: 3 / span 4;
    }
  }

  &__swiper {
    grid-column: main-start / side-end;

    @include tablet-landscape {
      grid-column: span 7 / side-end;
      grid-row: 1 / span 1;
    }
  }

  &__navigation {
    grid-column: main-start / side-end;
    display: flex;
    justify-content: center;
    column-gap: 10px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -ms-user-select: none;
    user-select: none;

    @include tablet-landscape {
      grid-column: 7 / span 1;
      flex-direction: column;
      row-gap: 10px;
      grid-row: 1 / span 1;
      justify-self: end;
    }
  }

  &__button {
    height: 32px;
    width: 32px;
    background-color: $black;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $white;
    transition: transform 0.1s ease-in-out;
    cursor: pointer;

    &:not(&--disabled) {
      @include card-shadow;
    }

    &--disabled {
      pointer-events: none;
      background-color: $grey;
    }

    &--locked {
      visibility: hidden;
    }
  }
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  width: 245px;

  @include tablet-landscape {
    width: 210px;
  }
}
