@keyframes shake-right {
    0%,
    50%,
    100% {
        transform: translateX(0);
    }
    25%,
    75% {
        transform: translateX(5px);
    }
}

@keyframes shake-left {
    0%,
    50%,
    100% {
        transform: translateX(0);
    }
    25%,
    75% {
        transform: translateX(-5px);
    }
}

@keyframes spin {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(359deg);
    }
}

@keyframes slide-up {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.out-left-enter-active,
.out-left-leave-active,
.out-right-enter-active,
.out-right-leave-active {
    transition: all $transition-slow ease;
}

.out-left-leave-to,
.out-right-enter {
    transform: translateX(-15px);
    opacity: 0;
}

.out-left-enter,
.out-right-leave-to {
    transform: translateX(15px);
    opacity: 0;
}

.accordion-enter-active,
.accordion-leave-active {
    will-change: height, opacity;
    transition: height $transition-fast ease, opacity $transition-fast ease;
    overflow: hidden;
}

.accordion-enter,
.accordion-leave-to {
    height: 0 !important;
    opacity: 0;
}

@keyframes slideInFromBottom {
    0% {
        transform: translateY(100vh);
    }

    100% {
        transform: translateY(0);
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes slideInFromRight {
    0% {
        opacity: 0;
        transform: translateX(80px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

.list-enter-active,
.list-leave-active {
    transition: all $transition-slow ease-out;
}
.list-enter-from,
.list-leave-to {
    transform: translateY(1rem);
    opacity: 0;
}

// add animation so an element moves a bit to the left and then back to starting position
@keyframes moveLeftAndBack {
    0% {
        transform: translateX(0);
    }
    50% {
        transform: translateX(-5px);
    }
    100% {
        transform: translateX(0);
    }
}
