/*
Colors

Style guide: 1-abstracts.colors
 */

/*
Base Colors

Colors:
$purple: #380F92 - Purple
$green-dark: #3A4D41 - Dark green

Style guide: 1-abstracts.colors.base
 */
$purple: #380f92;
$green-dark: #3a4d41;

/*
Support Colors

Colors:
$grey: #CCCCCC - Grey
$grey-very-light: #ECEBEB - Very light grey

Style guide: 1-abstracts.colors.base
 */
$grey: #cccccc;
$grey-very-light: #ecebeb;
$grey-blueish: #c2c2c2;

/*
Highlight Colors

Colors:
$green: #0CC988 - Green
$orange: #FF7200 - Orange

Style guide: 1-abstracts.colors.highlight
 */
$green: #0cc988;
$orange: #ff7200;

/*
Functional Colors

Colors:
$black: #000000 - Black
$white: #FFFFFF - White
$grey-dark: #606060 - Dark grey
$danger: #E6091C - Danger

Style guide: 1-abstracts.colors.functional
 */
$black: #000000;
$white: #ffffff;
$grey-dark: #606060;
$danger: #e6091c;

/*
Text Colors

Colors:
$text-color: #000000 - $black

Style guide: 1-abstracts.colors.text
 */
$text-color: $black;

/// Main brand color
/// @type Color
$brand-color: rgb(229, 0, 80) !default;

/// Light grey
/// @type Color
$light-grey: rgb(237, 237, 237) !default;

/// Medium grey
/// @type Color
$mid-grey: rgb(153, 153, 153) !default;

/// Dark grey
/// @type Color
$dark-grey: rgb(68, 68, 68) !default;

// POSSIBLE COLORS
$blue: blue;
$red: red;

// COLOR HIERARCHY
$color-first: $blue;
$color-second: darken($blue, 10%);
$color-third: lighten($blue, 10%);
$color-fourth: rgba($blue, 0.7);

// RFHE copy of all colors;
// Base Colors
$purple: #380f92;
$purple-on-hover: lighten($purple, 10%);
$purple-light: #d5cbe7;
$green-dark: #3a4d41;
$green-light: #c0ddc5;

// Support Colors
$grey: #cccccc;
$grey-light: #ecebeb;
$grey-medium: #999999;
$grey-heavy: #444444;

$pink: #e11482;

// Highlight Colors

$green: #0cc988;
$orange: #ff7200;
$orange-on-hover: $green;

// Functional Colors
$black: #000000;
$white: #ffffff;
$grey-dark: #606060;
$danger: #e6091c;

$brand: #e50050;

// Text Colors
$text-color: $black;

// status colors
$color-status-grey: #606060;
$color-status-red: #e6091c;
$color-status-green: #606060;

// navigation component
$color-navigation-link: $black;
$color-navigation-link-inactive: $grey-blueish;
$color-navigation-link-active: $orange;
$color-navigation-link-hover: $black;

$color-input-background: $grey-very-light;

$color-grey-lightish: #f3f3f3;
$color-grey-normalish: #e1e1e1;


// 2023 colours

$grayed-out: #cccccc;

$green-main: #3a4d41;
$green-100: #eff6f0;
$green-200: #c0ddc5;
$green-400: #46a667;
$green-600: #1d691b;
$green-800: #003e1a;

$purple-main: #380f92;
$purple-100: #f4f2f9;
$purple-200: #d5cbe7;
$purple-300: #d3d3ff;
$purple-400: #99f;
$purple-600: #5b2692;
$purple-800: #270047;
$purple: #380f92;

$red-main: #E6091C;
$red-200: #faced2;

$orange-main: #ff7200;
$orange-200: #fcecc9;

