$component: 'c-check-mark-list';

.#{$component} {
  @include full-grid;
  &__content {
    grid-column: main-start / main-end;
  }
  &__heading {
    margin-bottom: 16px;
  }
  &__list-block-container {
    display: block;
    padding: 16px;
    border: solid 1px $light-grey;
    border-radius: 6px;
  }
  &__list-title {
    margin-bottom: 16px;
  }
  &__list-block {
    width: 100%;
    padding: 0px;
    &:first-of-type {
      padding-bottom: 24px;

      @include tablet {
        padding-bottom: 0;
      }
    }
    & + & {
      border-left:0;
      border-top: 1px solid $light-grey;
      padding-top: 24px;
    }
  }
  &__list-item {
    display: flex;
    gap: 16px;
    align-items: baseline;
    & + & {
      margin-top: 16px;
    }
    &::before {
      content:'';
      display: block;
      background-image: url('/web/assets/svg/checkMarkList/check.svg');
      width: 16px;
      height: 16px;
      transform: translateY(2px);
      background-size: 100% 100%;
      flex: 0 0 16px;
    }
  }
  &__list-content {
    a {
      color: $orange;
      text-underline-offset: 2px;
    }
  }
  @include tablet {
    &__list-block-container {
      grid-column: main-start / main-end;
      display: flex;
      padding: 32px 33px;
    }
    &__list-block {
      width: 50%;
      padding: 0 33px;
      &:first-child {
        padding-left:0;
      }
      &:last-child {
        padding-right: 0;
      }
      & + & {
        border-top: 0;
        border-left: 1px solid $light-grey;
        padding-top: 0;
      }
    }
    &__list-title {
      margin-bottom: 32px;
    }
    &__list-item {
      & + & {
        margin-top: 16px;
      }
    }
    &__list-content {
      a {
        transition: color 0.2s;
        &:hover {
          color: $green;
          text-decoration: underline;
        }
      }
    }
  }
  @include tablet-landscape {
    &__content {
      grid-row: 1 / span 1;
      grid-column: main-start/ main-end;
    }
  }
  @include widescreen {
    &__content {
      grid-column: 3 / span 10;
    }
  }
}
