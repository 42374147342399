/*
Hero Header

Header with large image in the background.

Markup:
<header class="hero-header">
  <figure class="hero-header__image-container">
    <img
      class="object-fit"
      src="https://via.placeholder.com/1600x1200"
      alt="image title" />
  </figure>
  <div class="hero-header__container">
    <div class="hero-header__content">
      <h1 class="hero-header__title">Hero header title</h1>
      <p class="hero-header__description">
        Chocolate jelly-o fruitcake gummies pudding gingerbread I love. Biscuit
        cupcake chocolate cake tart sweet. Bonbon jelly beans halvah sweet
        roll I love.
      </p>
      <div class="hero-header__cta">
        <a href="#" class="button">
          hero header cta
        </a>
      </div>
    </div>
  </div>
</header>

Style guide: 3-layout.2-headers.3-hero-header
*/

.hero-header {
  background: $grey-very-light;
  margin: 0 auto;
  max-width: $jumbotron;
  min-height: 366px;
  overflow: hidden;
  padding-left: $section-outer-padding--mobile;
  padding-right: $section-outer-padding--mobile;
  position: relative;

  @include tablet-landscape {
    min-height: 666px;
  }

  .hero-header__container {
    @extend .container;
    position: relative;
  }

  .hero-header__content {
    color: $white;
    padding-bottom: calcEm(24px);
    padding-top: calcEm(24px);
    max-width: 460px;

    @include tablet {
      padding-bottom: calcEm(208px);
    }

    @include tablet-landscape {
      padding-top: calcEm(150px);
    }
  }

  .hero-header__title {
    font-size: 3.75em;
  }

  .hero-header__description,
  .hero-header__cta {
    margin-top: 1em;
    max-width: 375px;

    @include tablet-landscape {
      margin-top: 2em;
    }
  }

  .hero-header__image-container {
    bottom: 0;
    display: block;
    margin: 0 auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;

    &::after {
      background: linear-gradient(to right, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 96%);
      bottom: 0;
      content: '';
      display: block;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;

      @include tablet {
        background: linear-gradient(to right, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 62%);
      }
    }
  }

  &--smaller {
    min-height: auto;
    aspect-ratio: 10/8;

    @include tablet {
      aspect-ratio: 16/9;
    }

    @include tablet-landscape {
      aspect-ratio: 72/23;
    }
  }

  &--smaller &__content {
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: $section-outer-padding--mobile;
    padding-right: $section-outer-padding--mobile;

    @include tablet {
      padding-left: 0px;
      padding-right: 0px;
    }
  }

  &--smaller &__container {
    padding-top: 0px;
    padding-bottom: 0px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
  }
}
