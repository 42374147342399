@use "sass:math";

.contact-block {
  position: relative;

  @include tablet-landscape {
    margin: 0 auto;
    width: math.div(8, 12) * 100%;
  }

  @include widescreen {
    width: 50%;
  }
}

.contact-block__visual {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;

  .rfh-leafs {
    height: 59px;
    width: 66px;

    @include tablet-landscape {
      height: 119px;
      width: 136px;
    }
  }
}

.contact-block__content {
  padding-top: 9px;
  padding-left: 47px;

  @include tablet-landscape {
    padding-bottom: 30px;
    padding-top: 30px;
    padding-left: calc(#{math.div(1, 6) * 100%} + #{$padding-size});
  }

  h4,
  p {
    font-size: 24px;

    @include tablet-landscape {
      font-size: 21px;
    }
  }

  a {
    text-decoration: none;

    @include on-event {
      text-decoration: underline;
    }
  }
}
