/*
Grid

Style guide: 3-layout.1-grid
 */

/*
Grid Section

A container to divide page into vertical sections

Markup:
<div class="section">
  <div style="background: #DDD">Content</div>
</div>

Style guide: 3-layout.1-grid.section
 */

.section {
  @include section;

  &.no-vpadding {
    padding-top: 0;
    padding-bottom: 0;
  }

  &.negative-margin-top {
    margin-top: -32px;
  }

  &.no-padding-bottom {
    padding-bottom: 0;
  }

  &.big-padding-bottom {
    padding-bottom: 80px;
  }

  &--accordion-layout {
    padding-top: 68px;
    padding-bottom: 68px;
  }

  &--smaller {
    padding-top: 32px;
    padding-bottom: 32px;
  }

  &--small {
    padding-top: 36px;
    padding-bottom: 36px;
  }

  &--padding-bottom-none {
    padding-bottom: 0px;
  }

  &--padding-top-none {
    padding-top: 0px;
  }

  &--max-width-none {
    max-width: none;
    padding-left: 0px;
    padding-right: 0px;
  }
}

/*
Grid Container

A centered container

Breakpoints:
- Mobile: full width
- Tablet (768px): 720px wide
- Tablet landscape (1024px): 984px wide
- Widescreen (1440px): 1320px wide

Markup:
<div class="container">
  <div style="background: #DDD">Content</div>
</div>

Style guide: 3-layout.1-grid.container
 */

.container {
  @include container;

  &--show-grid {
    //@debug for flex-alignment
    background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.1) calc(100% - #{$gutter}),
      transparent calc(100% - #{$gutter})
    );
    background-size: calc(100% / 6 + ($gutter / 5));
    @include tablet {
      background-size: calc(100% / 12 + ($gutter / 11));
    }
    //@debug
  }
}

/*
Half Grid

Grid half width from tablet size. Mobile is full-width

Markup:

<div class="section is-half-width most-visited-pages">
  <div class="container">
    <div style="background: #DDD">Content</div>
  </div>
</div>

Style guide: 3-layout.1-grid.half-width
 */
.section.is-half-width {
  @include partial-container($columns: 6, $align: "left");
}

/*
Grid Columns

Markup:
<div class="columns" style="border: 1px solid grey">
  <div class="column" style="border: 1px solid grey">
    <div style="background: #DDD">Column</div>
  </div>
  <div class="column" style="border: 1px solid grey">
    <div style="background: #DDD">Column</div>
  </div>
</div>

Style guide: 3-layout.1-grid.columns
 */
