.c-navigation-jobs-dropdown {
    position: relative;
    &__links {
        top: 100%;
        background-color: $white;
        position: absolute;
        display: flex;
        flex-direction: column;
        padding: 1rem;
        width: max-content;
        border-radius: $border-radius-standard;
        border: 1px solid $grey-light;
    }

    &__link {
        text-decoration: none;
        font-size: 1.125rem;
        padding: 0.5rem;
        border-radius: $border-radius-standard;
        transition: all $transition-fast;
        min-width: 180px;

        &:hover {
            background-color: $green-200;
            font-weight: 600;
        }
    }
}
