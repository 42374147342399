.teaser-triple-block {
  &__title {
    display: flex;
    flex-direction: column;
    @include tablet {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__description {
    @include tablet {
      width: 65%;
    }
  }
} 