.c-images-content {
    @include full-grid;
    position: relative;
    grid-column: 3 / span 5;
    align-items: center;

    &__content {
        grid-column: main-start / main-end;

        @include tablet-landscape {
            grid-column: 3 / 8;
            padding-inline-end: $u-32;
        }
    }

    &__tag {
        color: $purple;
        font-size: 14px;
        margin-bottom: $u-8;
        transform: translateX(-30px);
        opacity: 0;
        transition: transform $move-in-animation-speed 0.4s,
            opacity $move-in-animation-speed 0.4s;
    }

    &__title {
        margin-bottom: $u-16;
    }
    &__text {
        transform: translateY(200px);
        opacity: 0;
        transition: transform $move-in-animation-speed 0.5s,
            opacity $move-in-animation-speed 0.5s;
    }

    &__button {
        margin-top: $u-16;
        transform: translateY(200px);
        opacity: 0;
        transition: transform $move-in-animation-speed 0.7s,
            opacity $move-in-animation-speed 0.7s;
    }

    &__images {
        grid-column: main-start / main-end;
        display: flex;
        gap: $u-16;
        margin-top: $u-32;

        @include tablet-landscape {
            grid-column: 8 / 13;
            margin-top: unset;
        }
    }

    &__image-wrapper {
        flex-grow: 1;
        position: relative;
        aspect-ratio: 1/2;

        &:nth-child(3) {
            display: unset;

            @include until-mobile {
                display: none;
            }
        }
        transform: translateY(200px);
        opacity: 0;
        &:nth-child(1) {
            transition: transform $move-in-animation-speed 0.2s,
                opacity $move-in-animation-speed 0.2s;
        }
        &:nth-child(2) {
            transition: transform $move-in-animation-speed 0.5s,
                opacity $move-in-animation-speed 0.5s;
        }
        &:nth-child(3) {
            transition: transform $move-in-animation-speed 0.8s,
                opacity $move-in-animation-speed 0.8s;
        }

        &--even {
            margin-bottom: $u-32;
        }

        &--odd {
            margin-top: $u-32;
        }
    }

    &__image {
        position: absolute;
        inset: 1;
        object-fit: cover;
        object-position: center;
        width: 100%;
        height: 100%;
    }
    &.in-view {
        .c-images-content {
            &__tag,
            &__text,
            &__button,
            &__image-wrapper {
                transform: translate(0);
                opacity: 1;
            }
        }
    }
}
