.c-list-image {
    @include full-grid-with-column-gap;
    row-gap: 40px;

    @include tablet-landscape {
        row-gap: 24px;
    }

    &__content {
        grid-column: main-start / main-end;

        @include tablet-landscape {
            grid-row: 1 / span 1;
            grid-column: main-start/ span 4;
        }
        
        @include widescreen {
            grid-column: 3 / span 3;
        }
    }

    &__list {
        grid-column: main-start / main-end;

        display: flex;
        flex-direction: column;
        row-gap: 16px;

        @include tablet-landscape {
            grid-row: 1 / span 2;
            grid-column: span 7 / main-end;
        }

        @include widescreen {
            grid-column: span 6 / -3;
        }        
    }

    &__visual {
        grid-column: main-start / main-end;

        @include tablet-landscape {
            grid-column: main-start / span 4;
            grid-row: 2 / span 1;
        }

        @include widescreen {
            grid-column: 3 / span 3;
        }        
    }

    &__arrow  {
        margin-left: auto;
    }

    &__list-link {
        text-decoration: none;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        column-gap: 12px;       

        background-color: $grey-light;
        padding: 16px;

        @include on-event {
            background-color: lighten($grey-light, 1%);
        }
      
        @include card-shadow;

        &--featured {
            background-color: $purple;
            color: $white;

            @include on-event {
                background-color: lighten($purple, 2%);
                color: $white;
            }
        }
    }

    &__item-description {
        display: none;

        @include tablet {
            display: block;
        }
    }
}
