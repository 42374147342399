// -----------------------------------------------------------------------------
// This file contains all application-wide Sass mixins.
// -----------------------------------------------------------------------------

/// Event wrapper
/// @author Harry Roberts
/// @param {Bool} $self [false] - Whether or not to include current selector
/// @link https://twitter.com/csswizardry/status/478938530342006784 Original tweet from Harry Roberts
@use "sass:math";
@use "sass:list";

@mixin on-event($self: false, $active-class: "") {
    @if $self {
        &,
        &:hover,
        &:active,
        &:focus {
            @content;
        }
    } @else if $active-class != "" {
        &.#{$active-class},
        &:hover,
        &:active,
        &:focus {
            @content;
        }
    } @else {
        &:hover,
        &:active,
        &:focus {
            @content;
        }
    }
}

/// Make a context based selector a little more friendly
/// @author Hugo Giraudel
/// @param {String} $context
@mixin when-inside($context) {
    #{$context} & {
        @content;
    }
}

@mixin when-after($context) {
    #{$context} + & {
        @content;
    }
}

@mixin basic-reset {
    list-style: none;
    background: none;
    text-decoration: none;
    border: none;
    margin: 0;
    padding: 0;
}

@mixin limit-lines($amount) {
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-clamp: $amount;
    -webkit-line-clamp: $amount;
    -webkit-box-orient: vertical;
    padding-bottom: 0.14em;
}

@mixin object-fit {
    height: 100%;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%;
}

@mixin basic-flex-box(
    $padding: $padding-size,
    $breakpoint: "tablet",
    $multiline: false
) {
    margin-left: -$padding;
    margin-right: -$padding;

    @if $multiline {
        flex-wrap: wrap;
    }

    @if $breakpoint == "none" {
        display: flex;
    } @else if $breakpoint == "tablet" {
        @include tablet {
            display: flex;
        }
    } @else if $breakpoint == "tablet-landscape" {
        @include tablet-landscape {
            display: flex;
        }
    } @else if $breakpoint == "desktop" {
        @include desktop {
            display: flex;
        }
    } @else {
        @include mq($breakpoint) {
            display: flex;
        }
    }
}

@mixin basic-flex-item($padding: $padding-size, $flex: 1, $columns: 0) {
    display: block;
    padding-left: $padding;
    padding-right: $padding;

    @if $columns > 0 {
        flex: none;
        width: math.div($columns, 12) * 100%;
    } @else {
        flex: $flex;
    }
}

@mixin container {
    margin: 0 auto;
    width: 100%;

    @include tablet {
        max-width: $container-max-width--tablet;
    }

    @include tablet-landscape {
        max-width: $container-max-width--tablet-landscape;
    }

    @include widescreen {
        max-width: $container-max-width--widescreen;
    }
}

@mixin partial-container(
    $columns: 6,
    $align: "left",
    $columns-tablet-landscape: 0,
    $columns-widescreen: 0
) {
    $columns-tablet: $columns;
    @if $columns-tablet-landscape == 0 {
        $columns-tablet-landscape: $columns-tablet;
    }
    @if $columns-widescreen == 0 {
        $columns-widescreen: $columns-tablet-landscape;
    }

    width: 100%;

    @if $align == "right" {
        left: auto;
        right: 0;
        padding-left: $gutter;
        padding-right: $gutter;
    }

    @include tablet {
        width: auto;

        @if $align == "right" {
            left: auto;
            right: 0;
            padding-left: 2 * $gutter;
            padding-right: calc(50% - ($container-max-width--tablet / 2));
        } @else {
            padding-left: calc(50% - calc($container-max-width--tablet / 2));
            padding-right: 2 * $gutter;
        }
    }

    @include tablet-landscape {
        @if $align == "right" {
            padding-right: calc((50% - ($container-max-width--tablet / 2)));
        } @else {
            padding-left: calc((50% - ($container-max-width--tablet / 2)));
        }
    }

    @include widescreen {
        @if $align == "right" {
            margin-right: calc(50% - ($widescreen / 2));
            padding-right: calc($container-max-width--tablet / 2);
        } @else {
            margin-left: calc(50% - ($widescreen / 2));
            padding-left: calc($container-max-width--tablet / 2);
        }
    }

    .container {
        @if $align == "left" {
            margin-left: auto;
            margin-right: 0;
        }

        @include tablet {
            max-width: calc($columns-tablet / 12) * $container-max-width--tablet;
            width: 100%;
        }

        @include tablet-landscape {
            max-width: calc($columns-tablet-landscape / 12) *
                $container-max-width--tablet-landscape;
            width: 100%;
        }

        @include widescreen {
            max-width: calc($columns-widescreen / 12) *
                $container-max-width--widescreen;
            width: 100%;
        }
    }
}

@mixin section-h-sides($property: "padding", $side: "both") {
    margin: 0 auto;
    @if $side == "both" {
        #{$property}-left: $section-outer-padding--mobile;
        #{$property}-right: $section-outer-padding--mobile;

        @include tablet {
            #{$property}-left: $section-outer-padding;
            #{$property}-right: $section-outer-padding;
        }

        @include widescreen {
            #{$property}-left: $section-outer-padding--widescreen;
            #{$property}-right: $section-outer-padding--widescreen;
        }
    }

    @if $side == "left" {
        #{$property}-left: $section-outer-padding--mobile;

        @include tablet {
            #{$property}-left: $section-outer-padding;
        }

        @include widescreen {
            #{$property}-left: $section-outer-padding--widescreen;
        }
    }

    @if $side == "right" {
        #{$property}-right: $section-outer-padding--mobile;

        @include tablet {
            #{$property}-right: $section-outer-padding;
        }

        @include widescreen {
            #{$property}-right: $section-outer-padding--widescreen;
        }
    }
}

@mixin has-arrow-icon-left($hover-style: "underline") {
    display: flex;
    padding-left: 24px;
    position: relative;

    &::before,
    &::after {
        background-color: $orange;
        mask-image: icon("arrow.svg");
        mask-position: left center;
        mask-repeat: no-repeat;
        mask-size: 13px;
        content: "";
        left: 0;
        position: absolute;
        top: 0;
        width: 13px;
        height: 100%;
        mask-position: 0px 8px;
        transform: translateY(2px);
    }

    &::after {
        left: -13px;
        opacity: 0;
    }

    @include on-event {
        @if $hover-style == "underline" {
            text-decoration: underline;
        }

        &::before,
        &::after {
            background-color: $green;
            transition: opacity $transition-fast ease-in,
                left $transition-fast ease-in;
        }

        &::before {
            left: 13px;
            opacity: 0;
        }

        &::after {
            left: 0;
            opacity: 1;
        }
    }

    &.is-active {
        text-decoration: none;

        &::before,
        &::after {
            background-color: $black;
        }
    }

    &.is-inactive {
        color: $grey;

        &::before,
        &::after {
            background-color: $grey;
        }
    }

    &.is-inversed {
        color: white;
        text-decoration: none;

        &::before {
            background-color: $green;
        }

        &::after {
            background-color: $orange;
        }
    }
}

@mixin arrow-glider($direction: right) {
    position: relative;
    overflow: hidden;

    &::before,
    &::after {
        position: absolute;
        content: "";
        top: 0;
        transition: $transition-slow transform, $transition-slow opacity;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center;
        width: 100%;
        background-image: icon("arrow-white.svg");
    }

    @if $direction == left {
        &::before,
        &::after {
            left: auto;
            right: 0;
            transform: scaleX(-1);
        }

        &::before {
            transform: translateX(100%) scaleX(-1);
            opacity: 0;
        }
    } @else {
        &::before,
        &::after {
            left: 0;
            right: auto;
        }

        &::before {
            transform: translateX(-100%);
            opacity: 0;
        }
    }
}

@mixin arrow-glider-hover($direction: right) {
    @if $direction == left {
        &::before {
            opacity: 1;
            transform: translateX(0) scaleX(-1);
        }

        &::after {
            transform: translate(-100%) scaleX(-1);
            opacity: 0;
        }
    } @else {
        &::before {
            opacity: 1;
            transform: translateX(0);
        }

        &::after {
            transform: translate(100%);
            opacity: 0;
        }
    }
}

@mixin full-width-element($until: "all", $hasCenteredContainer: false) {
    margin: 0 (-$section-outer-padding--mobile);

    @if $hasCenteredContainer {
        padding-left: $section-outer-padding--mobile;
        padding-right: $section-outer-padding--mobile;
    }

    @include tablet {
        margin-left: calc(-1 * (50vw - $container-max-width--tablet * 0.5));
        margin-right: calc(-1 * (50vw - $container-max-width--tablet * 0.5));

        @if $hasCenteredContainer {
            padding-left: calc(50vw - $container-max-width--tablet * 0.5);
            padding-right: calc(50vw - $container-max-width--tablet * 0.5);
        }
    }

    @if $until != "tablet" {
        @include tablet-landscape {
            margin-left: calc(
                -1 * (50vw - ($container-max-width--tablet-landscape / 2))
            );
            margin-right: calc(
                -1 * (50vw - ($container-max-width--tablet-landscape / 2))
            );

            @if $hasCenteredContainer {
                padding-left: calc(
                    50vw - ($container-max-width--tablet-landscape / 2)
                );
                padding-right: calc(
                    50vw - ($container-max-width--tablet-landscape / 2)
                );
            }
        }
    } @else {
        @include tablet-landscape {
            margin-left: 0;
            margin-right: 0;

            @if $hasCenteredContainer {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }

    @if $until == "all" {
        @include widescreen {
            margin-left: calc(
                -1 * (50vw - ($container-max-width--widescreen / 2))
            );
            margin-right: calc(
                -1 * (50vw - ($container-max-width--widescreen / 2))
            );

            @if $hasCenteredContainer {
                padding-left: calc(
                    50vw - ($container-max-width--widescreen / 2)
                );
                padding-right: calc(
                    50vw - ($container-max-width--widescreen / 2)
                );
            }
        }
    } @else {
        @include widescreen {
            margin-left: 0;
            margin-right: 0;

            @if $hasCenteredContainer {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
}

@mixin image-caption {
    margin-top: 8px;
    font-family: $micro-font-stack;
    font-size: 9px;
    text-transform: uppercase;
    letter-spacing: 0.1px;
    line-height: 20px;
}

/// @author Tim Huijkman
/// @param {number} $cols Amount of grid columns
/// @param {spacing} $gutter [0px] Spacing between those columns
/// @param {string} $child-class [*] What childs to select, defaults to all childs.
/// @param {spacing} $gutter-bottom [$gutter] Spacing between rows, defaults to column spacing.
@mixin grid($cols, $gutter: 0px, $child-class: "*", $gutter-bottom: $gutter) {
    display: flex;
    justify-content: left;
    width: 100%;
    flex-wrap: wrap;
    > #{$child-class} {
        $w: calc(
            math.div(100%, $cols) - math.div($gutter * ($cols - 1), $cols)
        );
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: $w;
        width: $w;
        @include IE {
            max-width: $w;
        }

        //all rows except bottom row
        &:not(:nth-last-child(-n + #{$cols})) {
            margin-bottom: $gutter-bottom;
        }
        //first in row
        &:not(:nth-child(#{$cols}n + #{$cols})) {
            margin-right: $gutter * 0.5;
        }
        //last in row
        &:not(:nth-child(#{$cols}n + 1)) {
            margin-left: $gutter * 0.5;
        }
    }
}

// let's say you have a 12 column grid and you want your element to be 3 column of that, it's not a perfect 33%; (this removes the gap + fallback on IE)
@mixin flex-percentage($parent-total, $column-amount, $spacing: $gutter) {
    $w: columnWidth($parent-total, $column-amount, $spacing);
    flex: 0 0 $w;
    width: $w;
    @include IE {
        max-width: $w;
    }
}

@mixin IE {
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        @content;
    }
}

@mixin css-grid($columns, $column-width, $gap) {
    display: -ms-grid;
    display: grid;
    $s: #{$column-width};
    @for $i from 2 through $columns {
        $s: #{$s} #{$gap} #{$column-width};
    }
    grid-template-columns: $s;
    -ms-grid-columns: $s;
}

// @author Tim Huijkman
@mixin grid-column($start, $span) {
    $start: ($start * 2)-1;
    $span: ($span * 2)-1;
    grid-column: list.slash($start, span) #{$span};
    -ms-grid-column: $start;
    -ms-grid-column-span: $span;
}

@mixin with-count($n) {
    @if $n == 1 {
        &:first-child:nth-last-child(1) {
            @content;
        }
    } @else {
        &:first-child:nth-last-child(#{$n}),
        &:first-child:nth-last-child(#{$n}) ~ & {
            @content;
        }
    }
}

@mixin standard-grid {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    column-gap: $gutter;

    @include tablet {
        grid-template-columns: repeat(12, 1fr);
    }
}

@mixin section {
    position: relative;
    width: 100%;
    margin: 0 auto;
    max-width: 1920px;
    padding-top: 44px;
    padding-right: $section-outer-padding--mobile;
    padding-bottom: 44px;
    padding-left: $section-outer-padding--mobile;

    @include tablet {
        padding-top: 72px;
        padding-right: $section-outer-padding;
        padding-bottom: 72px;
        padding-left: $section-outer-padding;
    }

    @include widescreen {
        padding-left: $section-outer-padding--widescreen;
        padding-right: $section-outer-padding--widescreen;
    }
}

@mixin show-grid {
    //@debug for flex-alignment
    background-image: linear-gradient(
        to right,
        rgba(0, 0, 0, 0.1) calc(100% - #{$gutter}),
        transparent calc(100% - #{$gutter})
    );
    background-size: calc(100% / 6 + ($gutter / 5));
    @include tablet {
        background-size: calc(100% / 12 + ($gutter / 11));
    }
    //@debug
}

@mixin full-grid($max-width: $widescreen, $gutter: 0px) {
    width: 100%;
    margin: 0 auto;
    max-width: $max-width;
    column-gap: $gutter;

    display: grid;
    grid-template-columns:
        [side-start] calc($section-outer-padding--mobile - $gutter)
        [main-start] repeat(6, 1fr) [main-end] calc(
            $section-outer-padding--mobile - $gutter
        )
        [side-end];

    @include tablet {
        grid-template-columns: get-fullgrid-column-width(
            $colums-total: $container-max-width--tablet,
            $column-gap: $gutter
        );
    }

    @include tablet-landscape {
        grid-template-columns: get-fullgrid-column-width(
            $colums-total: $container-max-width--tablet-landscape,
            $column-gap: $gutter
        );
    }

    @include widescreen {
        grid-template-columns: get-fullgrid-column-width(
            $colums-total: $container-max-width--widescreen,
            $column-gap: $gutter
        );
    }
}

@mixin full-grid-with-column-gap {
    @include full-grid($gutter: $gutter);
}

@mixin full-grid-jumbotron {
    @include full-grid($max-width: $jumbotron);
}

@mixin full-grid-with-column-gap-jumbotron {
    @include full-grid($max-width: $jumbotron, $gutter: $gutter);
}

@mixin heading-modifiers {
    &--danger {
        color: $danger;
    }

    &--font-weight-400 {
        font-weight: 400;
    }

    &--white {
        color: $white;
    }

    &--thin {
        font-weight: 300;
    }

    &--bold {
        font-weight: 700;
    }

    &--bold-until-tablet-landscape {
        @include until-tablet-landscape {
            font-weight: 700;
        }
    }

    &--capitalize {
        text-transform: capitalize;
    }

    &--uppercase {
        text-transform: uppercase;
    }

    &--small-mobile {
        @include until-tablet-landscape {
            font-size: calcEm(29px);
        }
    }

    &--has-shadow {
        text-shadow: 1px 1px rgba($black, 0.3);
    }

    &--purple-main {
        color: $purple;
    }

    &--green-main {
        color: $green-main;
    }
}

@mixin typography-modifiers {
    &--green-main {
        color: $green-main;
    }

    &--purple-main {
        color: $purple-main;
    }

    &--white {
        color: $white;
    }

    &--grey-dark {
        color: $grey-dark;
    }

    &--bold {
        font-weight: 600;
    }

    &--uppercase {
        text-transform: uppercase;
    }

    &--limit-3 {
        @include limit-lines(3);
    }

    &--limit-2 {
        @include limit-lines(2);
    }

    &--line-height-100 {
        line-height: 100%;
    }

    &--has-text-shadow {
        text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
    }
}

@mixin card-shadow {
    transition: all 0.1s ease-in-out;
    @include on-event {
        transform: translateY(-2px);
        box-shadow: 3px 3px 0 0 rgba(0, 0, 0, 0.1);
    }

    &:active {
        transform: translateY(1px);
        box-shadow: none;
    }
}

@mixin scrollbar-styling {
    scrollbar-color: #ccc $grey-very-light;
    &::-webkit-scrollbar {
        height: 3px;
    }
    &::-webkit-scrollbar-track {
        border: 0;
    }
    &::-webkit-scrollbar-thumb {
        background: #ccc;
        border-radius: 16px;
    }
    &::-webkit-scrollbar-track {
        background: $grey-very-light;
        border-radius: 16px;
    }
}

@mixin add-hover-animation {
    @media (hover: hover) {
        transition: all 0.3s ease-in-out;
        @include on-event {
            box-shadow: 0px 2px 10px 0px rgba(black, 0.15);
        }

        &:active {
            transform: translate(0, 0px);
            box-shadow: none;
        }
    }
}

@mixin sequence-children($type: "fade-up", $increment: 0) {
    @for $i from 1 through 10 {
        > :nth-child(#{$i}) {
            @if $type == fade-up {
                @include fade-up($increment);
            } @else if $type == scale-up {
                @include scale-up($increment);
            }

            $increment: $increment + $transition-fast;
        }
    }
}

@mixin fade-up($delay: 0) {
    opacity: 0;
    transform: translateY(1rem);
    transition: all $transition-slow ease-out;
    transition-delay: $delay;

    .in-view & {
        transform: translateY(0);
        opacity: 1;
    }
}

@mixin fade($delay: 0) {
    opacity: 0;
    transition: opacity $transition-slow ease-out;
    transition-delay: $delay;

    .in-view & {
        opacity: 1;
    }
}

@mixin scale-up($delay: 0) {
    opacity: 0;
    transform: translateY(5rem) scale(0.8);
    transition: all $transition-slower ease-out;
    transition-delay: $delay;

    .in-view & {
        transform: translateY(0) scale(1);
        opacity: 1;
    }
}

@mixin text-link-hover($color: $green) {
    color: $color;
    background-size: 100% 2px;
}
@mixin text-link($color: $green) {
    position: relative;
    text-decoration: none;
    transition: all $transition-fast ease;
    background: linear-gradient($color, $color) left bottom no-repeat;
    background-size: 0% 2px;
    display: inline !important;
}

// How this works:
// the text-link must be added to the element without :hover animation.
// Then the text-link-hover is added to the :hover part.
// example:
//   | a {
//   |   @include text-link;
//   |
//   |   @include on-event {
//   |     @include text-link-hover
//   |   }
//   | }

// It's important the the element you use this on is a inline element.
// This is needed for the animation to work inline.

@mixin button-hover {
    @include on-event {
       transition: all 0.1s ease-in-out;
        transform: translateY(-1px);
    }

    &:active {
        transform: translateY(1px);
    }
}
