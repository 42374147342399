.video-text {
  display: flex;
  margin-top: 64px;
  align-items: center;
  flex-direction: column-reverse;

  @include tablet-landscape {
    flex-direction: row;
    align-items: flex-start;
  }

  &__content {
    width: 100%;

    @include tablet-landscape {
      @include flex-percentage(12, 4);

      margin-bottom: 52px;
    }
  }

  &__title {
    margin-bottom: 8px;
  }

  &__subtitle {
    margin-bottom: 16px;
  }

  &__description {
    margin-bottom: 18px;
  }

  &__video-text {
    @extend h5;
    @extend .is-text;

    display: none;
    margin-top: 1em;
    text-transform: uppercase;

    @include tablet-landscape {
      display: block;
    }
  }

  &__video {
    width: calc(100% + #{$section-outer-padding--mobile * 2});
    margin-bottom: 32px;

    @include tablet-landscape {
      @include flex-percentage(12, 8);

      width: unset;
      margin-bottom: 0;
      margin-left: $gutter;
    }
  }
}
