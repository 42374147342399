
            @import "@/styles/abstracts/all.scss";
          

.l-tables {
    display: grid;
    column-gap: 40px;
    row-gap: 40px;

    @include tablet-landscape {
        &--two-columns-landscape {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    & > * {
        display: grid;
        align-content: start;
    }
}
