.table {
  margin-right: -26px;
  overflow-x: scroll;

  & + & {
    margin-top: 46px;
  }

  &--inverted {
    .table__row--body:nth-child(odd) {
      background-color: $grey-very-light;
    }
  }

  @include tablet {
    margin-right: 0px;
  }

  &__table {
    min-width: 643px;
    padding-right: 26px;
    width: 100%;

    @include tablet {
      min-width: auto;
      padding-right: 0px;
    }
  }

  &__header,
  &__data {
    flex: 1 1 15%;
    text-align: center;
    min-width: 160px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    justify-content: flex-start;

    &:first-of-type {
      align-items: flex-start;

    }
  }

  &__row {
    padding: 10px;
    display: flex;
    width: 100%;
    min-height: 46px;

    &--body {
      &:nth-child(odd) {
        background-color: $white;
      }
    }
  }
}
