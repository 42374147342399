// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

html,
body,
p,
ol,
ul,
li,
dl,
dt,
dd,
blockquote,
figure,
fieldset,
legend,
textarea,
pre,
iframe,
hr,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    padding: 0;
}

ul {
    list-style: none;
}

ul,
ol {
    padding-left: 0;
    list-style-position: inside;
}

textarea,
input[type="search"],
input[type="text"],
input[type="button"],
input[type="submit"] {
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0;
}

/**
 * Set up a decent box model on the root element
 */
html {
    box-sizing: border-box;
    font-size: $font-size-base;
    scroll-behavior: smooth;
}

body {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    overflow-x: clip;
    @include IE {
        display: block;
    }
}

main {
    flex: 1;
}

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*,
*::before,
*::after {
    box-sizing: inherit;
}

:target {
    scroll-margin-top: calc($navigation-mobile-height + 20px);

    @include tablet-landscape {
        scroll-margin-top: calc($navbar-height + 20px);
    }
}

hr {
    width: 100%;
    height: 0px;
    border-top: 1px solid $grey-dark;
    margin-top: 16px;
    margin-bottom: 16px;
}

::placeholder {
    color: $placeholder-color;
}

.sr-only {
  position: absolute;
  visibility: hidden;
  overflow: hidden;
  opacity: 0;
  height: 0;
  width: 0;
}
