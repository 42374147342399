.c-icon-buttons {
  display: grid;
  grid-gap: 16px;

  @include tablet {
    grid-template-columns: repeat(2, 1fr);
  }

  @include tablet-landscape {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 24px;
  }

  &__item {
    cursor: pointer;
    display: flex;
    align-items: center;
    border: $border-standard;
    border-radius: 6px;
    padding: 8px 40px 8px 8px;
    text-decoration: none;

    @include card-shadow;
  }

  &__visual {
    flex-shrink: 0;
    margin-right: 16px;
    height: 72px;
    width: 72px;
    background-color: $green-light;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__visual-icon {
    max-height: 64%;
    max-width: 64%;
    height: 100%;
    width: 100%;
  }

  &__arrow {
    margin-left: auto;
    transition: transform 0.2s ease-in-out;
  }

  &__item:hover &__arrow {
    transform: translateX(4px);
  }

  &__item:active &__arrow {
    transform: translateX(8px);
  }
}
